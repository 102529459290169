<ng-container *ngIf="requestData.commentList">
  <app-info-card
    *ngFor="let card of requestData.commentList"
    [backgroundImage]="'person'"
    [title]="card.userName"
    [subtitle]="card.date"
    [additionalContent]="card.comment"
  >
  </app-info-card>
</ng-container>
