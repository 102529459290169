import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {DialogInfoComponent} from "../../../../shared/dialog-info/dialog-info.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-meta-data-onlyread',
    imports: [
        NgIf,
        MatIconButton,
        MatIcon
    ],
    templateUrl: './meta-data-onlyread.component.html',
    styleUrl: './meta-data-onlyread.component.scss',
  standalone: true
})
export class MetaDataOnlyreadComponent {

  @Input() value!: string | undefined;
  @Input() title!: string;

  constructor(private dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(DialogInfoComponent, {
      autoFocus: false,
      data: {title: this.title, value: this.value}
    });
  }

}
