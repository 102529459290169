import { Injectable } from '@angular/core';
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {MvpHttpClient} from "../../../mvp-auth";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DmsFavoriteService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http) }

  createDmsFavorite$(type: string, id: string, isFavorite: boolean){
    const content ={
      type: type,
      id: id,
      isFavorite: isFavorite
    }
    // let params: HttpParams = new HttpParams();
    // params = params.append('type', type);
    // params = params.append('id', id);
    // params = params.append('isFavorite', isFavorite.toString());
    return this.create$<any, any>('favorite', content)
  }

}
