import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss'],
    standalone: false
})
export class SearchbarComponent {

  @Output() searchEvent = new EventEmitter<string>();
  @Input() hint= 'Keresés...';

  onSearchClicked(value: string) {
    this.searchEvent.emit(value);
  }
}
