/**
 * User: harold
 * Date: 2023.08.03.
 */
import { HttpEvent, HttpEventType, HttpHandler, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {filter, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MvpApiHttpHandler extends HttpHandler {
  //private readonly api: string = 'https://localhost:8443/api';
  private readonly api: string = '/api';

  constructor(
    private readonly next: HttpHandler,
  ) {
    super();
  }

  override handle(originalRequest: HttpRequest<any>): Observable<HttpEvent<any>> {
    let url = originalRequest.url;
    if (/^https?:\/\//.test(url)) {
      //console.log('url:', url);
      throw new Error('mvp request can not contains http schema!');
    }
    if (!url) throw new Error('no path!');
    if (url.substring(0, 1) !== '/') throw new Error('invalid mvp request path!');
    url = this.api + url;

    const req = originalRequest.clone({
      withCredentials: true,
      headers: originalRequest.headers
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json'),
      url,
      reportProgress: false,
    });

    //console.log('Mvp-Api-Handler', req);

    // https://angular.io/api/common/http/HttpEvent
    // https://www.javatpoint.com/rxjs-operators#Filtering
    return this.next.handle(req).pipe(
      filter(e=>e.type === HttpEventType.Response),
      //tap(r => console.log('HttpEvent=' + HttpEventType[r.documentType], r)),
    );
  }
}
