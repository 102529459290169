import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions, MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {NgIf} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
@Component({
    selector: 'app-dialog-info',
    imports: [
        TranslateModule,
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        NgIf,
    ],
    templateUrl: './dialog-info.component.html',
    styleUrl: './dialog-info.component.scss',
  standalone: true
})
export class DialogInfoComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string; value: string; htmlValue?: string},
              public dialogRef: MatDialogRef<DialogInfoComponent>,
              public sanitizer: DomSanitizer
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  trustHtml(textToHtml: string | undefined, sanitizer: DomSanitizer) {
    if (textToHtml) {
      let htmlText = this.htmlListFormatting(textToHtml);
      return sanitizer.bypassSecurityTrustHtml(htmlText);
    } else {
      return "";
    }
  }

  private htmlListFormatting(htmlString: string){
    let result = htmlString.replaceAll('<ol', '<ul');
    result = result.replaceAll('</ol', '</ul');
    return result;
  }

}
