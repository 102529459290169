/**
 * User: harold
 * Date: 2023.08.03.
 */
import { HttpClient } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MvpHttpHandler} from "./mvp-http-handler.service";

@Injectable({
  providedIn: 'root'
})
export class MvpHttpClient extends HttpClient {

  constructor(handler: MvpHttpHandler) {
    super(handler);
  }
}
