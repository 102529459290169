import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-meta-data-partner-list',
  templateUrl: './meta-data-partner-list.component.html',
  styleUrls: ['./meta-data-partner-list.component.scss']
})
export class MetaDataPartnerListComponent {

  @Input() partnerList: string[] = [];
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  constructor() {}

  protected readonly console = console;
}
