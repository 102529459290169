import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-meta-data-foszam',
  templateUrl: './meta-data-foszam.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataFoszamComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  foszamFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.foszamFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
      Validators.minLength(1),
      Validators.pattern(/^([^\/]+\/)?[1-9]\d*\/\d{4}(\/[^\/]+)?$/),
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }


    this.foszamFormControl = new FormControl(this.value, validators);
  }

}

