import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable, startWith, map} from "rxjs";
import {IncomingDataPartners} from "../../../../../shared/interfaces/shared/IncomingDataPartners";
import {DmsPartnerItem} from "../../../../../model/dmsPartnerItem";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsMetaService} from "../../../../../services/api/dms-meta.service";

@Component({
    selector: 'app-meta-data-partner',
    templateUrl: './meta-data-partner.component.html',
    styleUrls: ['../metadata-style.scss'],
    standalone: false
})

export class MetaDataPartnerComponent extends BaseMetadataAbstract<number|null, string, number> implements OnInit{

  displayValue: string = '';

  found: DmsPartnerItem[] = [];

  myControl = new FormControl<string | IncomingDataPartners>('');
  filteredOptions!: Observable<DmsPartnerItem[]>;


  constructor(
    public dmsMetaService: DmsMetaService,
  ) {
    super();
  }

  ngOnInit(){
    if(this.humanvalue) {
      this.displayValue = this.humanvalue;
    }
  }

  displayFn(option: any): string {
    return option && option.partnerFullName ? option.partnerFullName : '';
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();
    return this.found.filter(option => option.partnerFullName.toLowerCase().includes(filterValue));
  }

  timeoutId!: any;
 counter = 0;

  showAll(value: string){
    if(value.length>2&&!value.includes('---')) {
      this.found = [];
      this.counter++;
      this.timeoutId = setTimeout(() => {
        this.counter--;

        if (this.counter === 0) {
          this.dmsMetaService.readDmsAcPartners$(value).subscribe( response => {
            this.found = response.result;
            if(this.visibility != 'MT_K') {
              this.found.unshift({partnerId: 0, partnerFullName: '---'});
            }
            this.filtering();

          });
        }
      }, 150);
    } else {
      if(this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      this.counter = 0;
    }
  }

  filtering(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.partnerFullName;
        return name ? this._filter(name as string) : this.found.slice();
      }),
    );
  }
}
