import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PrevPageService {

  private _currentModule: string = '';

  private _currentChildRoute: string = '';

  private _targetRoute: string = ''; //csak akkor kap értéket ha az aktuális route-ra lehet visszanavigálni

  private _prevRouteDestinationList: string[] = []; //az a route lista ahová visszanyíl navigál

  private _intendedRoute!: string; //olyan esetben ha az előző komponens megadta hogy hozzá kell visszanavigálni pl. komment lista

  private _intendedInitialRoute!: string;

  loginRedirectCallNum = 0;

  private readonly _originalPathList: string[] = [
    'dashboard',
    'list',
    ':id',
    'do/:id',
    'station-list',
    'user-list',
    'admin-dashboard',
    'user-item/:id'
  ]

  private readonly _rootList: string[] = [
    'dashboard',
    'workflow',
    'admin',
    'admin-dashboard',
    'task',
    'document'
  ]
  constructor() { }

  setupCurrentRouteSchema(event: any){ //jelenlegi route-t tartalmazza, de valódi paraméterek nélkül
    this._targetRoute = '';
    const currentPath = event.snapshot.routeConfig?.path ?? '';
    if(this._rootList.includes(currentPath)) {
      this._currentModule = currentPath;
      this._currentChildRoute = '';
    } else {
      this._currentChildRoute = currentPath;
    }
    let intendedLastRoute: string;
    if( this._currentChildRoute) {
      intendedLastRoute = '/'+this._currentModule+'/'+this._currentChildRoute;
    } else {
      intendedLastRoute = '/'+this._currentModule;
    }
    if(this._originalPathList.includes(currentPath) && intendedLastRoute != '/task/do/:id'){
      //task esetén nem kell visszamenni elvégzés oldalra
      this._targetRoute = intendedLastRoute;
    }
  }

  fixCurrentRoute(event: any){ //jelenlegi route felülírása valódi paraméterekkel
      let routeCorrectionId = '';

      if (event.snapshot.data.requestData) {
        const requestData = event.snapshot.data.requestData;

        if(requestData.wfTaskDetails){
          routeCorrectionId = requestData.wfTaskDetails.wfTaskId.toString();
        } else if(requestData.taskDetails){
          routeCorrectionId = requestData.taskDetails.mut.mutId.toString();
        } else if(requestData.docDetails){
          routeCorrectionId = requestData.docDetails.documentData.docId.toString();
        } else if(requestData.userDetails){
          routeCorrectionId = requestData.userDetails.user_id.toString();
        }
      }

      if(routeCorrectionId && this._currentChildRoute === ':id'){
        this._targetRoute = '/' + this._currentModule+'/' + routeCorrectionId;
      } else if(routeCorrectionId && this._currentChildRoute === 'user-item/:id'){
        this._targetRoute = '/admin/user-item/' + routeCorrectionId;
      } else if(routeCorrectionId && this._currentChildRoute === 'do/:id' && this._currentModule === 'workflow'){
        this._targetRoute = '/workflow/do/' + routeCorrectionId;
      }
  }

  setNavigationList(){
    if(this._prevRouteDestinationList.length === 0) {
      if(this._currentModule === 'admin') {
        this._prevRouteDestinationList.push('/admin-dashboard');
      } else {
        this._prevRouteDestinationList.push('/dashboard'); //ha először lép az oldalra és visszanyílra kattint
      }
    }
    if(this._targetRoute) {
      if(this._prevRouteDestinationList[0] != this._targetRoute) {
        this._prevRouteDestinationList.unshift(this._targetRoute);
      }
      if(this._targetRoute===this._prevRouteDestinationList[2]&&this._prevRouteDestinationList[1].includes('/do/')){
        this._prevRouteDestinationList.splice(0, 2);
      }
    }
    if(this._prevRouteDestinationList.length === 4){
      this._prevRouteDestinationList.pop();
    }
  }

  getPreviousRoute(): string | null {
    if(this._intendedRoute) {
      const tempIntendedRoute = this._intendedRoute;
      this._intendedRoute = '';
      return tempIntendedRoute;
    }
    if(this._prevRouteDestinationList){
      if(this._targetRoute === this._prevRouteDestinationList[0]) { //ha a jelenlegi route-ra vissza lehet térni
          return this._prevRouteDestinationList[1];
      }
      else {
         return this._prevRouteDestinationList[0];
      }
    }
    return null;
  }

  setPreviousRoute(intendedRoute: string) {
    this._intendedRoute = intendedRoute;
  }

  getRedirectRouteforLogin(): string | null {
    if (this.loginRedirectCallNum > 0) {
      this.loginRedirectCallNum--;
      return this._intendedInitialRoute;
    } else {
      return '/dashboard';
    }
  }

  setInitialRoute(intendedRoute: string) {
    if(intendedRoute.includes('/task/') || intendedRoute.includes('/wfTask/')) {
      const parts = intendedRoute.split('/');
      let module = "/" + parts[parts.length - 2];
      const index = "/" + parts[parts.length - 1];
      if (module === '/wfTask') {
        module = '/workflow';
      }
      this._intendedInitialRoute = module + index;
      this.loginRedirectCallNum = 2;
    }
  }

}
