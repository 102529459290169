/**
 * User: harold
 * Date: 2023.08.03.
 */

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import {MvpApiHttpHandler} from "../../mvp-common";
import {catchError, finalize, first, Observable, Subject, switchMap, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {MvpApiHttpClient} from "../../mvp-common";
import {tap} from "rxjs/operators";
import {LoginResponse} from "../auth-intf";
import {AuthStateEnum, LoginState, LoginStore} from "../index";
import * as action from "../actions/login-state.action";

import {AuthErrorCode} from "../types";
import {Router} from "@angular/router";
import {ErrorPageService} from "../../app/services/shared/error-page.service";
import {UserEmailService} from "../../app/services/component/sidenav/user-email.service";
import {PrevPageService} from "../../app/services/component/navigation/prev-page.service";

@Injectable({
  providedIn: 'root'
})
export class MvpHttpHandler extends HttpHandler {
  loginState$: Observable<LoginState>

  constructor(
    private readonly apiClient: MvpApiHttpClient,
    private readonly next: MvpApiHttpHandler,
    private readonly store: LoginStore,
    private readonly router: Router,
    public errorPageService: ErrorPageService,
    private userEmailService: UserEmailService,
    private prevPageService: PrevPageService
  ) {
    super();
    this.loginState$ = this.store.selectLoginState();
  }

  // private catchErrorFunc<T>(err: any, caught:  Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<T> | HttpUserEvent<T>>): Observable<HttpEvent<T>> {
  //   if (err instanceof HttpErrorResponse) {
  //     if (err.status === 401 || err.status === 403) {
  //       return this.createAccessTokenWithAuthPopup(req.clone(), err);
  //     }
  //   }
  //   console.warn(err)
  //   console.warn(caught);
  //   return throwError(err);
  // }

  // private catchErrorOp<T, O extends Observable<any>>(selector: (err: any, caught: Observable<T>) => O): OperatorFunction<T, T | ObservedValueOf<O>> {
  //   return catchError((err, caught) => {
  //     if (err instanceof HttpErrorResponse) {
  //       if (err.status === 401 || err.status === 403) {
  //         return this.createAccessTokenWithAuthPopup(req.clone(), err);
  //       }
  //     }
  //     console.warn(err)
  //     console.warn(caught);
  //     return throwError(err);
  //   });
  // }

  private catchErrorHandler<T, O extends Observable<any>>(req: HttpRequest<T>, err: any, caught: O): Observable<HttpEvent<T>> {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 || err.status === 403) {
        return this.createAccessTokenWithAuthPopup(req.clone(), err);
      }
    }

    const error = err.error;
    console.warn(err)
    //console.warn(caught);
    // if (error instanceof ProgressEvent) {
    //   return throwError(err.headers?.comment ?? 'progress error!');
    // }

    // todo error trace formázottan egy nyitható divbe
    let message: string;
    if(typeof error.text === 'string') {
      const jsonString = error.text;
      const jsonObjects = jsonString.match(/({.*?})/g);
      const firstJsonObject = JSON.parse(jsonObjects[0]);
      message = firstJsonObject.message;
    } else message = err.error.message;
    this.errorPageService.errorMessage=err.status + ': ' + message;
    this.router.navigate(['/error']).catch(navigateError => {console.error('Navigate Error', navigateError)});

    return throwError(err);
  }

  private handleRequestWithPopup<T>(req: HttpRequest<T>): Observable<HttpEvent<T>> {
    return this.next.handle(req).pipe(
      catchError((err, caught) => {
        return this.catchErrorHandler(req, err, caught);
        // if (err instanceof HttpErrorResponse) {
        //   if (err.status === 401 || err.status === 403) {
        //     return this.createAccessTokenWithAuthPopup(req.clone(), err);
        //   }
        // }
        // console.warn(err)
        // console.warn(caught);
        // return throwError(err);
      }),
    )
  }

  override handle(originalRequest: HttpRequest<any>): Observable<HttpEvent<LoginResponse>> {
    const req = originalRequest.clone({
      //headers: originalRequest.headers.set('x-dms-auth', 'true'),
    });
    return this.handleRequestWithPopup(req);
  }

  private createAccessTokenWithAuthPopup(failedRequest: HttpRequest<any>, err: HttpErrorResponse): Observable<HttpEvent<any>> {

    //console.log('after 40x create token...');
    const authError = <AuthErrorCode>err.error.authError;
    const stationRequired = authError === AuthErrorCode.StationSelectionRequired;
    console.log('authError:', authError, 'stationRequired=', stationRequired, err);

    if(authError === 2) {
      console.log('UserAuthenticationRequired. Navigating back to login page')
      if(err.url) {
        this.prevPageService.setInitialRoute(err.url);
      }
      this.router.navigate(['login']);
    }

    if(authError === 100) {
      this.errorPageService.errorMessage=err.status + ': ' + err.error.message;
      this.router.navigate(['/error']).catch(navigateError => {console.error('Navigate Error', navigateError)});
    }

    const req = failedRequest.clone();

    const result = new Subject<HttpEvent<any>>();

    this.store.dispatch(action.loginNeeded());

    const sub = this.loginState$.pipe(
    ).subscribe(loginState => {
      if (loginState.authState === AuthStateEnum.LoginCompleted) {
        //console.log('... and make request again', req);
        //sub.unsubscribe();
        this.next.handle(req)
          .subscribe({
            next: (v) => result.next(v),
            error: (e) => result.error(e),
            complete: () => result.complete(),
          })
      } else if (loginState.authState === AuthStateEnum.LoginAborted) {
        //console.log('... abort the request', req);
        //sub.unsubscribe();
        // throwError(() => err)
        //   .subscribe({
        //     error: (e) => result.error(e),
        //   })
        result.error(err)
      } else {
        //console.log('..wait next state...', state);
      }
    });


    // const sub = this.events.$authState.subscribe(state => {
    //   //console.log('auth state changed:', state);
    //   if (state === AuthStateEnum.LoginCompleted) {
    //     //console.log('... and make request again', req);
    //     //sub.unsubscribe();
    //     this.next.handle(req)
    //       .subscribe({
    //         next: (v) => result.next(v),
    //         error: (e) => result.error(e),
    //         complete: () => result.complete(),
    //       })
    //   } else if (state === AuthStateEnum.LoginAborted) {
    //     //console.log('... abort the request', req);
    //     //sub.unsubscribe();
    //     // throwError(() => err)
    //     //   .subscribe({
    //     //     error: (e) => result.error(e),
    //     //   })
    //     result.error(err)
    //   } else {
    //     //console.log('..wait next state...', state);
    //   }
    // })

    //console.log('... and wait for login state changes...', req);

    // of(AuthStateEnum.LoginCompleted).pipe(
    //   delay(2000)
    // ).subscribe(v => this.events.$authState.next(v));

    return result.pipe(
      //tap(x => console.log('continue request:', x)),
      first(),
      //finalize(() => console.log('Unsubscribe...', sub.unsubscribe())),
      finalize(() => sub.unsubscribe()),
    );
    //return this.next.handle(req);
  }
}
