<div style="width: 100%; display: flex; justify-content: space-between">
  <h2 mat-dialog-title style="margin-bottom: 0">{{'ATTACHMENTS' | translate}}</h2>
  <button mat-icon-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
</div>

<mat-dialog-content style="min-width: 280px;">

  <div *ngFor="let item of data.attachmentList">
    <div class="item-container">
      <div class="item-header">
        <div class="item-name">{{item.name}}</div>
        <div class="item-actions">
          <div *ngIf="item.attachmentType" class="attachment-type">{{item.attachmentType}}</div>
          <span class="example-spacer"></span>
          <div *ngIf="item.attachmentGuid" class="action-buttons">
            <button mat-mini-fab class="action-button" matTooltip="{{'PREVIEW' | translate}}" matTooltipPosition="below"
                    [disabled]="item.isTorolt"
                    (click)="preview(item.attachmentGuid)">
              <mat-icon>visibility</mat-icon>
            </button>
            <button mat-mini-fab class="action-button" matTooltip="{{'SIGN' | translate}}" matTooltipPosition="below"
                    [disabled]="item.isTorolt"
                    (click)="signDoc(item.attachmentGuid)">
              <mat-icon>gesture</mat-icon>
            </button>
            <button mat-mini-fab class="action-button" matTooltip="{{'DOWNLOAD' | translate}}" matTooltipPosition="below"
                    [disabled]="item.isTorolt"
                    (click)="download(item.attachmentGuid)">
              <mat-icon>save_alt</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <mat-divider class="divider-position"></mat-divider>
    </div>
  </div>

  <div>

    <p class="upload-title">{{'FILE_UPLOAD' | translate}}</p>

    <div class="dropzone"
         [ngClass]="{ 'dragover': isDragOver }"
         (drop)="onFileDrop($event)"
         (dragover)="onDragOver($event)"
         (dragleave)="onDragLeave($event)">
      <input type="file" id="fileDropRef" multiple
             style="opacity: 0; position: relative; z-index: 2; width: 100%; height: 100%; top: 0; left: 0;"
             (change)="uploadingFiles($event)">
<!--      <div style="position: relative; width: 100%; height: 100%">-->
<!--        <input type="file" id="fileDropRef" multiple-->
<!--               style="opacity: 100; position: relative; z-index: 2; width: 100%; height: 100%; top: 0; left: 0;"-->
<!--               (change)="uploadingFiles($event)">-->
<!--      </div>-->
      <p class="dropzone-text">{{'CHOOSE_A_FILE' | translate}}</p>
    </div>

    <div *ngFor="let file of uploadList.files; let i = index" class="uploaded-file-div">
        <div class="uploaded-file-inner-div">
          <div class="uploaded-file-name"
               [ngClass]="{'file-too-large': file.content.size > MAX_FILE_SIZE}">
            {{file.content.name}}
            <b>({{formatFileSize(file.content.size)}})</b>
          </div>
          <div style="flex: 0.5; display: flex; flex-direction: row; align-items: end">
          <mat-form-field subscriptSizing="dynamic" class="uploaded-file-type">
            <mat-select placeholder="{{'ATTACHMENT_TYPE' | translate}}" [(value)]="file.attachmentTypeCode" style="line-height: 2">
              <mat-option [value]="null">
                {{'NOT_SELECTED' | translate}}
              </mat-option>
              <mat-option *ngFor="let fileType of attachmentTypes" [value]="fileType.code">
                {{fileType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-mini-fab color="warn" (click)="removeFile(i)">
            <mat-icon>delete</mat-icon>
          </button>
          </div>
        </div>
        <mat-divider class="divider-position"></mat-divider>
    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="margin-right: 18px;margin-bottom: 12px;"
          [disabled]="uploadList.files.length === 0 || isUploading"
          (click)="onSendClick()">
    {{'UPLOAD' | translate}}
  </button>
</mat-dialog-actions>
