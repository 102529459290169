import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {ActivatedRoute, ActivationEnd, NavigationStart, Router} from "@angular/router";
import {BaseComponentAbstract} from "../../../../class/base.component.abstract";

export interface IRouteInfo {
  header?: string;
  actions: IRouteAction[];
}

export interface IRouteAction {
  icon: string;
  // azert legyen mindenkepp ikon, hogy ha kesobb valamit major ikonna akarunk rakni, akkor ne kelljen vadaszgatni
  targetRoute?: string|any[];
  click?: (action: IRouteAction, actionInfo: IRouteInfo) => any;
  caption: string | null;
  tooltip?: string | null;
  isCollapsed?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  readonly routeUrlChanged: Subject<string | null> = new Subject<string | null>();
  readonly routeInfoChanged: Subject<IRouteInfo | null> = new Subject<IRouteInfo | null>();


  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        const snapshot = event.snapshot;
        const routePath = snapshot.routeConfig?.path ?? null;
        this.routeUrlChanged.next(routePath);
      }
    });
  }

  setupRouteHandling(baseComponent: BaseComponentAbstract) {
    // todo: unsubscribes
    baseComponent.subscriptionOfParam =
    baseComponent.route?.paramMap.subscribe(params => {
      const routeInfo = baseComponent.setupNavigation(params);
      // todo: ne timeout-al legyen!!
      setTimeout(() => {
        this.routeInfoChanged.next(routeInfo);
      })
    });
    baseComponent.subscriptionOfNavigationEvent =
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // todo: esetleg az elozo allapotot visszaallitani
        this.routeInfoChanged.next(null);
      }
    });
  }


}
