import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorPageService} from "../../services/shared/error-page.service";
import {Router} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent implements OnInit, OnDestroy{

  private langChangeSub!: Subscription;
  pageNotFoundText= "Az oldal nem létezik";
  constructor(
    public errorPageService: ErrorPageService,
    private router: Router,
    private translateService: TranslateService
    ) {}

  ngOnInit() {
    this.updateMultilingualValues();
    this.langChangeSub = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateMultilingualValues();
    });
  }

  ngOnDestroy() {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  get errorMessage(){
    return this.errorPageService.errorMessage === 'PAGE_NOT_FOUND' ? this.pageNotFoundText : this.errorPageService.errorMessage;
  }

  backToPage() {
    /*if (this.error.errorMessage.includes('workflow feladat már lezárásra került!')) {
      this.router.navigate(['/workflow/list']);
    } else if (this.error.errorMessage.includes('task feladat már lezárásra került!') ||
                this.error.errorMessage.includes('task feladat még nem került elfogadásra!'))
    {
      this.router.navigate(['/task/list']);
    } else {
      history.back();
    }*/
    this.router.navigate(['/login']);
  }

  updateMultilingualValues() {
    this.translateService.get('PAGE_IS_NOT_FOUND').subscribe((translatedText: string) => {
      this.pageNotFoundText = translatedText;
    });
  }

}
