import {Injectable} from '@angular/core';
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {MvpHttpClient} from "../../../mvp-auth";
import {DmsNewsListResponseSchema} from "../../model/dmsNewsListResponseSchema";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DmsNewsService extends BaseHttpServiceAbstract {

  constructor(http: MvpHttpClient) {
    super(http);
  }

  readDmsNews$(closedTasksIncluded: boolean) {
    let data = 'in-progress';
    if (closedTasksIncluded) {
      data = 'all';
    }
    let params: HttpParams = new HttpParams();
    params = params.append("status", data)
    return this.read$<any>(`news`, params)
  }
}
