import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {
  AnyLoginResponse,
  AuthStateEnum,
  Credentials,
  LoginResponseType,
  LoginState,
  LoginStore,
  XLoginRequest
} from "../../../mvp-auth";
import {MvpApiHttpClient} from "../../../mvp-common";
import {tap} from "rxjs/operators";
import * as action from "../../../mvp-auth/actions/login-state.action";
import {UserEmailService} from "../../services/component/sidenav/user-email.service";
import {Router} from "@angular/router";
import {PrevPageService} from "../../services/component/navigation/prev-page.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit, OnDestroy{

  readonly destroyed$ = new Subject<void>();

  loginState$ = this.store.selectLoginState();

  loginState: LoginState = <LoginState><unknown>undefined;
  authStateName?: string;
  canLogin: boolean = false;
  selectedStation: string = '';
  lastSelected: string = localStorage.getItem('lastStation') ?? '';
  stationSelectionMode: boolean = false;

  constructor(private readonly store: LoginStore,
              private readonly api: MvpApiHttpClient,
              public userEmailService: UserEmailService,
              private router: Router,
              private prevPageService: PrevPageService
  ) {}

  ngOnInit(): void {
    this.loginState$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(loginState => {
      this.loginState = loginState;
      this.authStateName = AuthStateEnum[this.loginState.authState];
      this.canLogin = !!loginState.credentials;
      this.stationSelectionMode = loginState.authState === AuthStateEnum.LoginMultiStation;
      this.selectedStation = this.calculateSelectedStation(loginState.credentials, loginState.stationList);
    })
    if(this.userEmailService.emailAddress){
      this.router.navigate(['/dashboard'])
    }
    this.userEmailService.loggedInEmail$.subscribe((newValue) => {
      if( newValue !== '' ) {
        const prevRoute = this.prevPageService.getRedirectRouteforLogin();
        console.log('prevroute:', prevRoute)
        this.router.navigate([prevRoute]);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private calculateSelectedStation(credentials: Credentials | undefined, stationList: string[]) {
    if (stationList.length === 0) return '';
    if (stationList.length === 1) return (this.lastSelected = stationList[0]);
    console.log(stationList.indexOf(this.lastSelected), stationList, this.lastSelected);
    if (~stationList.indexOf(this.lastSelected)) return this.lastSelected;
    return '';
  }

  stationSelectionChanged(value: string) {
    this.lastSelected = value;
    localStorage.setItem('lastStation', value);
  }

  doLogin() {
    this.api.post<AnyLoginResponse>('/login', <XLoginRequest>{
      provider: this.loginState?.provider,
      providerToken: this.loginState?.providerToken,
      selectedStation: this.selectedStation,
    }).pipe(
      tap((r) => console.log('xlogin/2:', r)),
    ).subscribe({
      next: (r) => {
        if (r.type === LoginResponseType.Success) {
          this.store.dispatch(action.xloginSuccess({response: r}));
        } else {
          throw new Error('unexpected response! ' + r.type);
        }
      },
    });
  }

}
