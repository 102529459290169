/**
 * User: harold
 * Date: 2023.08.03.
 */
import { HttpClient } from "@angular/common/http";
import {MvpApiHttpHandler} from "./mvp-api-http-handler.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MvpApiHttpClient extends HttpClient {

  constructor(handler: MvpApiHttpHandler) {
    super(handler);
  }
}
