import {Injectable} from '@angular/core';

const hostname = new URL(window.location.href).hostname;

const prodSites = ['dms-mvp-prod.web.app'];
const devSites = ['dms-mvp-dev.web.app'];
const isProduction = prodSites.indexOf(hostname) > -1;
const isDevelop = devSites.indexOf(hostname) > -1;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  readonly isProduction = isProduction;
  readonly isDevelop = isProduction;

  constructor() {
  }
}
