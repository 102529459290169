import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavToggleService {

  private _isShowing: boolean = false;

  get isShowing(): boolean {
    return this._isShowing;
  }

  set isShowing(value: boolean) {
    this._isShowing = value;
  }
}
