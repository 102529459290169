<mat-expansion-panel class="expansion-card" #expansionPanel>

  <mat-expansion-panel-header
    [collapsedHeight]="headerHeight"
    [expandedHeight]="headerHeight"
    [ngClass]="{'small-screen-header-layout': isSmallScreen}">

    <mat-panel-title [ngClass]="{'small-screen-title-layout': isSmallScreen}">

      <div *ngIf="!isSmallScreen" style="display: flex; flex-direction: row">
        <button mat-icon-button *ngIf="!isFavorite"
                (click)="$event.stopPropagation(); starClicked()">
          <mat-icon>star_border</mat-icon>
        </button>
        <button mat-icon-button *ngIf="isFavorite"
                (click)="$event.stopPropagation(); starClicked()">
          <mat-icon style="color: rgba(252, 196, 53, 1)">star</mat-icon>
        </button>

        <img
          [src]="icon"
          alt="icon"
          height="40"
          width="40">
      </div>

      <div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; margin-bottom: 20px" *ngIf="isSmallScreen">
        <div style="display: flex; flex-direction: row; align-items: center">
          <button mat-icon-button *ngIf="!isFavorite" style="height: 38px;width: 38px;padding: 0;display: flex;align-items: center;justify-content: center;"
                  (click)="$event.stopPropagation(); starClicked()">
            <mat-icon>star_border</mat-icon>
          </button>
          <button mat-icon-button *ngIf="isFavorite" style="height: 38px;width: 38px;padding: 0;display: flex;align-items: center;justify-content: center;"
                  (click)="$event.stopPropagation(); starClicked()">
            <mat-icon style="color: rgba(252, 196, 53, 1)">star</mat-icon>
          </button>
          <img
            [src]="icon"
            alt="icon"
            height="30"
            width="30">
        </div>
        <div style="display: flex; flex-direction: row; gap: 12px; align-items: center; margin-right: 24px">
          <button mat-mini-fab (click)="$event.stopPropagation(); onForwardTask()" *ngIf="isActiveStatus()">
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button mat-mini-fab
                  (click)="$event.stopPropagation(); callAttachments()">
            <mat-icon matBadge="{{countAttachments()}}"
                      matBadgeSize="small"
                      matBadgeColor="warn"
                      aria-hidden="false">
              attach_file
            </mat-icon>
          </button>
          <button mat-mini-fab
                  (click)="$event.stopPropagation()"
                  [routerLink]="routeToInfo()"
                  [disabled]="countMessages()===0">
            <mat-icon matBadge="{{countMessages()}}"
                      matBadgeSize="small"
                      matBadgeColor="warn"
                      [matBadgeHidden]="countMessages()===0"
                      aria-hidden="false">
              question_answer
            </mat-icon>
          </button>
        </div>

      </div>

      <div [ngClass]="isSmallScreen ? 'small-screen-title-div' : 'big-screen-title-div'">
        <span class="medium-400-data use-break-word">
          {{subject}}
          <span *ngIf="type==='WfTask'" class="medium-300-data">
            #{{referenceNumber}}
          </span>
        </span>
        <span class="accent-color medium-300-data use-break-word">
          {{taskName}}
        </span>
        <span *ngIf="type==='Task'" class="medium-300-data">
          #{{referenceNumber}}
        </span>
      </div>

    </mat-panel-title>

    <mat-panel-description class="horizontal-flex-sb">


      <div (click)="$event.stopPropagation()"
           [matTooltip]="deadlineTooltip"
           matTooltipPosition="above"
           class="deadline-div"
           [ngClass]="{
           'big-screen-deadline-width': !isSmallScreen,
           'warning-color-deadline': ((deadlineCounter === missedDeadline)&&isActiveStatus()),
           'accent-color': ((deadlineCounter !== missedDeadline)&&isActiveStatus())}">
        <mat-icon *ngIf="isActiveStatus()">access_time</mat-icon>
        <mat-icon *ngIf="!isActiveStatus()">check_circle</mat-icon>
        <span>
          {{isActiveStatus() ? deadlineCounter : closed}}
        </span>
      </div>

      <div *ngIf="!isSmallScreen || ( isSmallScreen && previewMetaItems.length > 0 )"
           class="header-metadata-div"
           [ngClass]="{'big-screen-metadata-div-height': !isSmallScreen}">
        <span *ngFor="let item of previewMetaItems"
              class="header-metadata-item">
          <b>{{item.name}}: </b>
          {{item.humanvalue}}
        </span>
      </div>

      <div [ngClass]="getHeaderActionLayout()">

        <div [ngClass]="getHeaderResultLayout()" *ngIf="isActiveStatus()">

          <div class="metadata-div" [ngClass]="{'result-hint-fix' : !sendComment&&isMandatoryComment()}">
            <span class="input-field-label-news">
              {{'RESULT' | translate}}
            </span>
            <mat-form-field (click)="$event.stopPropagation()"
                            class="custom-padding"
                            subscriptSizing="dynamic">
              <mat-select placeholder="{{'NOT_SELECTED' | translate}}"
                          disableOptionCentering="true"
                          [(ngModel)]="selectedResult" (selectionChange)="setCommentState()">
                <mat-option *ngFor="let option of possibleTaskResultsSorting()"
                            [value]="option">
                  {{option.displayName}}
                </mat-option>
              </mat-select>
              <mat-hint class="warn-color" *ngIf="!sendComment&&isMandatoryComment()">{{'MANDATORY_COMMENT' | translate}}</mat-hint>
            </mat-form-field>
          </div>
          <button mat-stroked-button class="send-button"
                  *ngIf="!isResult()"
                  disabled="true">
            {{'SEND' | translate}}
            <mat-icon>send</mat-icon>
          </button>
          <button mat-raised-button class="send-button" color="primary"
                  *ngIf="isResult()"
                  (click)="$event.stopPropagation(); completeTask()">
            {{'SEND' | translate}}
            <mat-icon>send</mat-icon>
          </button>

        </div>

        <div [ngClass]="getHeaderResultLayoutNonActive()" *ngIf="!isActiveStatus()" >
          <label class="font-size-small">
            <span>
              <b>{{'RESULT' | translate}}</b>
            </span>
          </label>
          <div class="bordered-div-result non-active-result-text" [matTooltip]="result">
            {{result ? result : '-' }}
          </div>
        </div>

        <div class="header-action-button-div horizontally-to-right" *ngIf="!isSmallScreen">

          <button mat-mini-fab (click)="$event.stopPropagation(); onForwardTask()" *ngIf="isActiveStatus()">
            <mat-icon>swap_horiz</mat-icon>
          </button>

          <button mat-mini-fab
                  (click)="$event.stopPropagation(); callAttachments()">
            <mat-icon matBadge="{{countAttachments()}}"
                      matBadgeSize="small"
                      matBadgeColor="warn"
                      aria-hidden="false">
              attach_file
            </mat-icon>
          </button>

          <button mat-mini-fab
                  (click)="$event.stopPropagation()"
                  [routerLink]="routeToInfo()"
                  [disabled]="countMessages()===0">
            <mat-icon matBadge="{{countMessages()}}"
                      matBadgeSize="small"
                      matBadgeColor="warn"
                      [matBadgeHidden]="countMessages()===0"
                      aria-hidden="false">
              question_answer
            </mat-icon>
          </button>

        </div>

      </div>

    </mat-panel-description>
  </mat-expansion-panel-header>
  <div [ngClass]="isSmallScreen ? 'small-screen-expanded-div' : 'big-screen-expanded-div'">

    <div class="expanded-info-div">
      <div>
        <label class="font-size-small">
<!--          <mat-icon>description</mat-icon>-->
          <span>
            <b>{{'DESCRIPTION' | translate}}</b>
          </span>
        </label>
        <div class="expanded-info-data bordered-div" [innerHTML]="description"></div>
      </div>
      <div>
        <button mat-stroked-button
                class="details-button"
                [routerLink]="routeToInfo()">
          {{'MORE_DETAILS' | translate}}
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>

    <div class="expanded-metadata-div">
      <ng-container *ngFor="let item of metaItem">

        <ng-container *ngIf="item.visibility==='MT_M'||item.visibility==='MT_K'||item.visibility==='MT_O'">
          <ng-container [ngSwitch]="item.type">

            <app-meta-data-check *ngSwitchCase="'Check'"
                                 [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                 [visibility]="item.visibility"
                                 [(humanvalue)]="item.humanvalue"
                                 [(value)]="item.value"></app-meta-data-check>

            <app-meta-data-date *ngSwitchCase="'Date'"
                                [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                [visibility]="item.visibility"
                                [(humanvalue)]="item.humanvalue"
                                [(value)]="item.value"></app-meta-data-date>

            <app-meta-data-partner *ngSwitchCase="'Partner'"
                                   [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                   [visibility]="item.visibility"
                                   [(humanvalue)]="item.humanvalue"
                                   [(value)]="item.value"></app-meta-data-partner>

            <app-meta-data-alszam *ngSwitchCase="'Alszam'"
                                  [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                  [visibility]="item.visibility"
                                  [(humanvalue)]="item.humanvalue"
                                  [(value)]="item.value"></app-meta-data-alszam>

            <app-meta-data-foszam *ngSwitchCase="'Foszam'"
                                  [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                  [visibility]="item.visibility"
                                  [(humanvalue)]="item.humanvalue"
                                  [(value)]="item.value"></app-meta-data-foszam>

            <app-meta-data-mftorzs *ngSwitchCase="'MFTorzs'"
                                   [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                   [visibility]="item.visibility"
                                   [mfTorzsOptionList]="mfTorzsOptionList"
                                   [(humanvalue)]="item.humanvalue"
                                   [(value)]="item.value" [params]="item.params"></app-meta-data-mftorzs>

            <app-meta-data-mftorzs-ac *ngSwitchCase="'MFTorzsAC'"
                                      [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                      [visibility]="item.visibility"
                                      [mfTorzsOptionList]="mfTorzsOptionList"
                                      [(humanvalue)]="item.humanvalue"
                                      [(value)]="item.value" [params]="item.params"></app-meta-data-mftorzs-ac>

            <app-meta-data-money *ngSwitchCase="'Money'"
                                 [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                 [visibility]="item.visibility"
                                 [(humanvalue)]="item.humanvalue"
                                 [(value)]="item.value"></app-meta-data-money>

            <app-meta-data-number *ngSwitchCase="'Number'"
                                  [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                  [visibility]="item.visibility"
                                  [(humanvalue)]="item.humanvalue"
                                  [(value)]="item.value"></app-meta-data-number>

            <app-meta-data-text *ngSwitchCase="'Text'"
                                [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                [visibility]="item.visibility"
                                [(humanvalue)]="item.humanvalue"
                                [(value)]="item.value"></app-meta-data-text>

            <app-meta-data-option *ngSwitchCase="'Option'"
                                  [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                  [visibility]="item.visibility"
                                  [(humanvalue)]="item.humanvalue"
                                  [(value)]="item.value" [default]="item.params"></app-meta-data-option>

            <app-meta-data-textarea *ngSwitchCase="'Textarea'"
                                    [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                    [visibility]="item.visibility"
                                    [(humanvalue)]="item.humanvalue"
                                    [(value)]="item.value"></app-meta-data-textarea>

            <app-meta-data-workflow *ngSwitchCase="'Workflow'"
                                    [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                    [visibility]="item.visibility"
                                    [(humanvalue)]="item.humanvalue"
                                    [(value)]="item.value"></app-meta-data-workflow>

            <app-meta-data-szotar *ngSwitchCase="'Szotar'"
                                   [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                   [visibility]="item.visibility"
                                   [szotarOptionList]="szotarOptionList"
                                   [(humanvalue)]="item.humanvalue"
                                   [(value)]="item.value" [params]="item.params"></app-meta-data-szotar>

            <app-meta-data-szotar-ac *ngSwitchCase="'SzotarAC'"
                                      [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                      [visibility]="item.visibility"
                                      [szotarOptionList]="szotarOptionList"
                                      [(humanvalue)]="item.humanvalue"
                                      [(value)]="item.value" [params]="item.params"></app-meta-data-szotar-ac>

            <app-meta-data-szervuser *ngSwitchCase="'SzervUser'"
                                   [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                   [visibility]="item.visibility"
                                   [(humanvalue)]="item.humanvalue"
                                   [(value)]="item.value"></app-meta-data-szervuser>

            <app-meta-data-szervuser-ac *ngSwitchCase="'SzervUserAC'"
                                     [label]="item.visibility === 'MT_K' ? item.name+'*' : item.name"
                                     [visibility]="item.visibility"
                                     [(humanvalue)]="item.humanvalue"
                                     [(value)]="item.value"></app-meta-data-szervuser-ac>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <div class="expanded-message-div" [ngClass]="{'fixed-width':(!isSmallScreen)&&(!isMediumScreen)}">
      <label class="font-size-small" *ngIf="isActiveStatus()">
        <span>
            <b>{{'COMMENT_ON_THE_RESULT' | translate}}</b>
          </span>
      </label>
      <mat-form-field *ngIf="isActiveStatus()">
        <textarea [(ngModel)]="sendComment" [required]="isMandatoryComment()"
                  matInput #input
                  maxlength="1000">
        </textarea>
        <mat-hint align="end">{{input.value.length}}/1000</mat-hint>
        <mat-error *ngIf="input.checkValidity">
          {{'MANDATORY_COMMENT' | translate}}
        </mat-error>
      </mat-form-field>

      <div>
        <label class="font-size-small">
          <span>
            <b>{{'LAST_COMMENT' | translate}}</b>
            <i>{{getLastCommentData()}}</i>
          </span>
        </label>
        <div class="expanded-info-data bordered-div">{{getLastComment()}}</div>
      </div>
    </div>

    <div *ngIf="isSmallScreen" class="expanded-added-result">
      <div class="metadata-div" [ngClass]="{'result-hint-fix' : !sendComment&&isMandatoryComment()}">
            <span class="input-field-label-news">
              {{'RESULT' | translate}}
            </span>
        <mat-form-field (click)="$event.stopPropagation()"
                        class="custom-padding"
                        subscriptSizing="dynamic">
          <mat-select placeholder="{{'NOT_SELECTED' | translate}}"
                      disableOptionCentering="true"
                      [(ngModel)]="selectedResult" (selectionChange)="setCommentState()">
            <mat-option *ngFor="let option of possibleTaskResultsSorting()"
                        [value]="option">
              {{option.displayName}}
            </mat-option>
          </mat-select>
          <mat-hint class="warn-color" *ngIf="!sendComment&&isMandatoryComment()">{{'MANDATORY_COMMENT' | translate}}</mat-hint>
        </mat-form-field>
      </div>
      <button mat-stroked-button class="send-button"
              *ngIf="!isResult()"
              disabled="true">
        {{'SEND' | translate}}
        <mat-icon>send</mat-icon>
      </button>
      <button mat-raised-button color="primary" class="send-button"
              *ngIf="isResult()"
              (click)="$event.stopPropagation(); completeTask()">
        {{'SEND' | translate}}
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</mat-expansion-panel>
