<h1>API Core Test Page</h1>


<fieldset>
  <legend>quips API</legend>
  <pre>{{quip ? quip : 'no quip'}}</pre>
  <button (click)="getQuip()">Random Quip</button>
  <button (click)="getQuip(42)">Indexed Quip</button>
</fieldset>

<!--
<fieldset>
  <app-login-actions-widget></app-login-actions-widget>
</fieldset>
-->

<fieldset>
  <legend>LoginState</legend>
  <pre>{{loginState$ | async | json}}</pre>
</fieldset>

<fieldset>
  <legend>API</legend>
  <button (click)="queryNoAuth()">no auth</button>
  <button (click)="queryUserAuth()">user auth</button>
  <button (click)="queryUserStationAuth()">station auth</button>
  <button (click)="queryGetRequest()">GET request</button>
  <button (click)="queryGetRequestParameter()">GET request with param</button>
  <button (click)="queryPutRequest()">PUT request</button>
  <button (click)="queryPostRequest()">POST request</button>
  <pre>{{apiResult$ | async | json}}</pre>
</fieldset>

