<!--<h1 class="general-margin">Üdvözlünk újra itt!</h1>-->

<!--<ng-container *ngIf="canShow">
  <app-info-card
    [title]="'Worflow feladatok'"
    [additionalContent]="wfNumberMessage + ' új feladat'"
    routerLink='/workflow/list'
  >
  </app-info-card>

  <app-info-card
    [title]="'Taskok'"
    [additionalContent]="taskNumberMessage + ' új feladat'"
    [routerLink]="'/task/list'"
  >
  </app-info-card>

  <app-info-card
    [title]="'Dokumentumok'"
    [additionalContent]="'Dokumentumok megtekintése'"
    [routerLink]="'/document/list'"
  >
  </app-info-card>
</ng-container>-->
<!--<div style="position: relative; width: 100%"><span style="font-size: 8pt; font-weight: 300; position: absolute; right: 1rem;top:0">Oldal frissítve: {{refreshTime}}</span></div>-->

<div class="news-list-header-small" *ngIf="isSmallScreen">
  <div style="display: flex; flex-direction: row; align-items: center">
    <h1 style="margin-bottom: 0"><b>{{'MY_TASKS_TO_COMPLETE' | translate}}</b></h1>
    <div style="display: flex; flex-direction: row; align-items: center; margin-left: auto">
<!--      <button mat-icon-button *ngIf="!isStar"-->
<!--              (click)="$event.stopPropagation(); starClicked()">-->
<!--        <mat-icon>star_border</mat-icon>-->
<!--      </button>-->
<!--      <button mat-icon-button *ngIf="isStar"-->
<!--              (click)="$event.stopPropagation(); starClicked()">-->
<!--        <mat-icon style="color: rgba(252, 196, 53, 1)">star</mat-icon>-->
<!--      </button>-->
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <button mat-icon-button
              (click)="showFilters=!showFilters">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </div>
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; column-gap: 12px" *ngIf="showFilters">
    <mat-checkbox color="primary"
                  [checked]="workflowChecked"
                  (change)="clickWorkflowChecked()">
      {{'WORKFLOW' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="taskChecked"
                  (change)="clickTaskChecked()">
      {{'TASK' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="doneChecked"
                  (change)="clickDoneChecked()">
      {{'CLOSED_TASKS' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="isStar"
                  (change)="starClicked()">
      {{'FAVOURITES' | translate}}
    </mat-checkbox>
  </div>
  <app-searchbar (searchEvent)="search($event)" style="width: 95%" *ngIf="showFilters"></app-searchbar>
</div>

<div class="news-list-header" *ngIf="!isSmallScreen">
  <div style="display: flex; flex-direction: row; align-items: center; margin-right: 12px;">
<!--    <button mat-icon-button *ngIf="!isStar"-->
<!--            (click)="$event.stopPropagation(); starClicked()">-->
<!--      <mat-icon>star_border</mat-icon>-->
<!--    </button>-->
<!--    <button mat-icon-button *ngIf="isStar"-->
<!--            (click)="$event.stopPropagation(); starClicked()">-->
<!--      <mat-icon style="color: rgba(252, 196, 53, 1)">star</mat-icon>-->
<!--    </button>-->
    <h1 style="margin-bottom: 0"><b>{{'MY_TASKS_TO_COMPLETE' | translate}}</b></h1>
  </div>
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; column-gap: 12px">
    <mat-checkbox color="primary"
                  [checked]="workflowChecked"
                  (change)="clickWorkflowChecked()">
      {{'WORKFLOW' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="taskChecked"
                  (change)="clickTaskChecked()">
      {{'TASK' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="doneChecked"
                  (change)="clickDoneChecked()">
      {{'DISPLAY_CLOSED_TASKS' | translate}}
    </mat-checkbox>
    <mat-checkbox color="primary"
                  [checked]="isStar"
                  (change)="starClicked()">
      {{'FAVOURITES' | translate}}
    </mat-checkbox>
  </div>
  <div style="margin-left: auto; display: flex; flex-flow: row wrap; gap: 12px; align-items: center; justify-content: center">
    <button mat-stroked-button [matMenuTriggerFor]="menu">
      {{'NEW_TASK' | translate}}
    </button>
    <app-searchbar (searchEvent)="search($event)"></app-searchbar>
  </div>
</div>
<div *ngFor="let item of sortedNewsData" style="background: none; margin: 0; padding: 0; width: 100%; display: flex; flex-direction: column">
  <app-feed-card-new
    [type]="item.type"
    [status]="item.status"
    [subject]="item.subject"
    [attachments]="item.attachments"
    [comments]="item.comments"
    [result]="item.result"
    [id]="item.id"
    [referenceNumber]="item.referenceNumber ? item.referenceNumber : ''"
    [taskName]="item.taskName"
    [possibleResults]="item.possibleResults"
    [taskStart]="item.taskStart"
    [taskDeadline]="item.taskDeadline"
    [wfDescription]="trustHtml(item.wfDescription, sanitizer)"
    [taskDescription]="trustHtml(item.taskDescription, sanitizer)"
    [metaItem]="item.metaItems"
    [mfTorzsOptionList]="requestData.mfMasterOptionList"
    [szotarOptionList]="requestData.szotarOptionList"
    [isFavorite]="item.isFavorite"
    [icon]="setIconPath(item.icon)"
  ></app-feed-card-new>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onNewWfTask()">{{'CREATE_WORKFLOW' | translate}}</button>
  <button mat-menu-item (click)="onNewTask()">{{'CREATE_TASK' | translate}}</button>
</mat-menu>

<!--<h1 class="general-margin">Feladataim</h1>-->
<!--<mat-expansion-panel class="no-style">
  <mat-expansion-panel-header class="no-background">
    <h2 class="no-margin">Workflow feladatok - {{wfNumberMessage}}</h2>
  </mat-expansion-panel-header>-->
  <!--<div class="content">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="33%" fxFlex.xs="100%" fxflex.sm="33%" *ngFor="let item of requestData.allIncomingDataNews">
        <app-feed-card [type]="item.type"
                       [status]="item.status"
                       [subject]="item.subject"
                       [attachments]="item.attachments"
                       [id]="item.id"
                       [referenceNumber]="item.referenceNumber ? item.referenceNumber : ''"
                       [taskName]="item.taskName"
                       [possibleResults]="item.possibleResults"
                       [taskStart]="item.taskStart"
                       [taskDeadline]="item.taskDeadline"
                       [wfDescription]="trustHtml(item.wfDescription, sanitizer)"
                       [taskDescription]="trustHtml(item.taskDescription, sanitizer)"
                       [metaItem]="item.metaItems"
                       [mfTorzsOptionList]="requestData.mfMasterOptionList">
        </app-feed-card>
      </div>
    </div>
  </div>-->
<!--</mat-expansion-panel>-->



<!--<mat-expansion-panel class="no-style">
  <mat-expansion-panel-header class="no-background">
    <h2 class="no-margin">Taskok - {{taskNumberMessage}}</h2>
  </mat-expansion-panel-header>
  <ng-container *ngIf="taskIncomingDataNews">
    <app-feed-card *ngFor="let item of taskIncomingDataNews"
                   [type]="item.type"
                   [status]="item.status"
                   [subject]="item.subject"
                   [attachments]="item.attachments"
                   [id]="item.id"
                   [taskName]="item.taskName"
                   [possibleResults]="item.possibleResults"
                   [taskStart]="item.taskStart"
                   [taskDeadline]="item.taskDeadline"
                   [wfDescription]="item.wfDescription"
                   [taskDescription]="item.taskDescription"
                   [metaItem]="item.metaItems">
    </app-feed-card>
  </ng-container>
</mat-expansion-panel>-->





