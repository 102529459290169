import {Component, EventEmitter, Input, Output} from '@angular/core';
import { PossibleWfTaskResults } from "../../../shared/interfaces/workflow/IncomingDataWorkflowTask";

@Component({
  selector: 'app-chipset',
  templateUrl: './chipset.component.html',
  styleUrls: ['./chipset.component.scss']
})
export class ChipsetComponent {

  @Input() resultList: PossibleWfTaskResults[] = [];
  @Input() value!: number;
  @Output() valueChange = new EventEmitter<number>();

  send(value: number){
    this.valueChange.emit(Number(value));
  }
}
