import {Component, Input} from '@angular/core';
import {AsyncPipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {MatFormField, MatHint} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsDictItemsByType} from "../../../../../model/dmsDictItemsByType";
import {DmsDictItem} from "../../../../../model/dmsDictItem";
import {map, Observable, startWith} from "rxjs";
import {TranslateModule} from "@ngx-translate/core";
import {MetaDataOnlyreadComponent} from "../meta-data-onlyread/meta-data-onlyread.component";

@Component({
    selector: 'app-meta-data-szotar-ac',
    imports: [
        AsyncPipe,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatFormField,
        MatHint,
        MatInput,
        MatOption,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        MatTooltip,
        NgClass,
        TranslateModule,
        MetaDataOnlyreadComponent,
    ],
    templateUrl: './meta-data-szotar-ac.component.html',
    styleUrls: ['../metadata-style.scss'],
  standalone: true
})
export class MetaDataSzotarAcComponent extends BaseMetadataAbstract<number, string, number> {

  @Input() params: string = '';
  @Input() szotarOptionList: DmsDictItemsByType[] = [];

  optionList: DmsDictItem[] = [];

  szotarAcFormControl = new FormControl('');
  displayValue: string = '';

  filteredOptions!: Observable<DmsDictItem[]>;
  selectedOption!: DmsDictItem;

  constructor() {
    super();
  }

  ngOnInit() {
    if(this.visibility != 'MT_O') {
      if(this.szotarOptionList && this.szotarOptionList.length > 0){
        let typeIdList: number[] = this.params.split(',').map(Number);
        typeIdList.forEach(param => {
          const foundItem = this.szotarOptionList.find(item =>
            Number(item.dictTypeId) === param);
          if (foundItem) {
            foundItem.items?.forEach(data => {
              this.optionList.push(data);
            })
          }
        });
        this.setAcComponentData();
      } else {}
    }
  }

  private setAcComponentData(){
    if(this.visibility != 'MT_K') {
      this.optionList.unshift({dictItemId: 0, dictItemName: '---'});
    }
    if(this.value && this.value != null) {
      this.selectedOption = this.optionList.find((item) => (item.dictItemId == this.value))!;
      this.displayValue = this.selectedOption.dictItemName;
    } else this.displayValue = '---'
    this.filteredOptions = this.szotarAcFormControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value === 'string') {
          return value;
        }
        const typedValue = value as DmsDictItem | null;
        return typedValue && 'dictItemName' in typedValue ? typedValue.dictItemName : '';
      }),
      map(name => name ? this._filter(name) : this.optionList.slice())
    );
  }

  private _filter(name: string): DmsDictItem[] {
    const filterValue = name.toLowerCase();
    return this.optionList.filter(option => option.dictItemName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(option: DmsDictItem): string {
    return option && option.dictItemName ? option.dictItemName : '';
  }

  send(option: DmsDictItem){
    this.displayValue = option.dictItemName;
  }

}
