import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
    selector: 'app-meta-data-foszam',
    templateUrl: './meta-data-foszam.component.html',
    styleUrls: ['../metadata-style.scss'],
    standalone: false
})
export class MetaDataFoszamComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  foszamFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.foszamFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const foszamRegex = /^(?:([\p{L}\p{N} \w.!*_\/-]+)\/)?([0-9]+)\/((?:19|20)[0-9]{2})(?:\/([\p{L}\p{N} \w.!*_\/-]+))?$/u;
    const validators: ValidatorFn[] = [
      Validators.minLength(1),
      Validators.pattern(foszamRegex),
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }


    this.foszamFormControl = new FormControl(this.value, validators);
  }

}

