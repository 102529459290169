import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() {
  }

  getCookie(name: string): string | null {
    const ca = document.cookie.split(';');
    for (let str of ca) {
      str = str.trim();
      const n = str.indexOf(name + '=');
      if (n === 0) {
        return str.substring(name.length + 1);
      }
    }
    return null;
  }

}
