import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {SharedModule} from "../../../modules/shared/shared.module";
import {DmsAttachmentsService} from "../../../services/api/dms-attachments.service";
import {DocusignService} from "../../../services/api/docusign.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DmsAttachmentUploadItem} from "../../../model/dmsAttachmentUploadItem";
import {DmsAttachmentTypeItem} from "../../../model/dmsAttachmentTypeItem";
import {DmsMetaService} from "../../../services/api/dms-meta.service";
import {AttachmentData} from "../../../shared/interfaces/shared/AttachmentData";
import {Subscription} from "rxjs";
import {NavigationStart, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-dialog-attachment-box',
    imports: [
        NgIf,
        SharedModule,
        MatIconButton,
        MatMiniFabButton,
        TranslateModule,
        MatButton
    ],
    templateUrl: './dialog-attachment-box.component.html',
    styleUrl: './dialog-attachment-box.component.scss',
  standalone: true
})
export class DialogAttachmentBoxComponent implements OnInit, OnDestroy{

  attachmentTypes: DmsAttachmentTypeItem[] = [];

  uploadList: DmsAttachmentUploadItem = {
    files: [],
    elementType: '',
    elementId: ''
  }

  isDragOver = false;

  isUploading= false;

  private navigationSubscription!: Subscription;

  readonly MAX_FILE_SIZE = 104857600; // 100MB in bytes
  //readonly MAX_FILE_SIZE = 31457280; // 30 MB in bytes

  constructor(
    public dmsAttachmentsService: DmsAttachmentsService,
    private dmsMetaService: DmsMetaService,
    private docusignService: DocusignService,
    public dialogRef: MatDialogRef<DialogAttachmentBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentData,
    private router: Router,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.dialogRef.close(false);
      }
    });
    this.dmsMetaService.readDmsAttachmentType$().subscribe(response => {
      if (response.result) {
        this.attachmentTypes = response.result;
      }
    })
    this.uploadList.elementType = this.data.elementType;
    this.uploadList.elementId = this.data.elementId;
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  preview(attachmentGuid: string) {
    this.dmsAttachmentsService.readDmsAttachmentPreviewByGuid$(attachmentGuid);
  }

  download(attachmentGuid: string) {
    this.dmsAttachmentsService.readDmsAttachmentByGuid$(attachmentGuid);
  }

  signDoc(attachmentGuid: string) {
    this.docusignService.docusignGetUrlByAttachmentGuid$(attachmentGuid).subscribe( response => {
      window.open(response.result.url);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSendClick() {

    const tooLargeFiles = this.uploadList.files.filter(
      file => file.content.size > this.MAX_FILE_SIZE
    );

    if (tooLargeFiles.length > 0) {
      const message = this.translate.instant('REMOVE_LARGE_FILES');
      const action = this.translate.instant('OK');
      this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['snackbar-warning']
      });
      return;
    }

    if (this.uploadList.files.length === 0) {
      return;
    }

    this.isUploading = true;

    this.dmsAttachmentsService.createDmsAttachmentUpload$(this.uploadList).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: () => {
        this.isUploading = false;
      },
      complete: () => {
        this.isUploading = false;
      }
    });

  }

  uploadingFiles(event: Event){
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFilesToUploadList(input.files);
      input.value = '';
    }
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFilesToUploadList(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    // Check if the mouse is leaving the dropzone area
    if (!this.isMouseInsideDropzone(event)) {
      this.isDragOver = false;
    }
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(_event: DragEvent) {
    this.isDragOver = false;
  }

  private isMouseInsideDropzone(event: DragEvent): boolean {
    const dropzone = document.querySelector('.dropzone') as HTMLElement;
    if (!dropzone) return false;
    const { top, left, bottom, right } = dropzone.getBoundingClientRect();
    const { clientX, clientY } = event;
    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  }

  private addFilesToUploadList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        this.uploadList.files.push({
          content: file,
          attachmentTypeCode: null,
          fileName: file.name
        });
      }
    }
  }

  removeFile(index: number) {
    this.uploadList.files.splice(index, 1);
  }

  formatFileSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size >= gb) {
      return (size / gb).toFixed(2) + ' GB';
    } else if (size >= mb) {
      return (size / mb).toFixed(2) + ' MB';
    } else if (size >= kb) {
      return (size / kb).toFixed(2) + ' KB';
    } else {
      return size + ' Bytes';
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
