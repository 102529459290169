<mat-sidenav-container class="container" hasBackdrop="true" (backdropClick)="sidenavToggle.isShowing=false">

  <mat-sidenav fixedInViewport="true"
               class="sidenav-positioning"
               mode="over"
               [opened]="sidenavToggle.isShowing">
  <app-sidenav></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>

    <app-spinner *ngIf="spinnerVisibility" class="spinner"></app-spinner>

    <app-toolbar></app-toolbar>

    <app-body></app-body>
  </mat-sidenav-content>
</mat-sidenav-container>

