import { Component } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo, NavigationService} from "../../../services/component/navigation/navigation.service";
import {BaseComponentAbstract} from "../../../../class/base.component.abstract";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent extends BaseComponentAbstract{

  constructor(
    private navService: NavigationService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(route, router)
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: 'Admin felület', actions: []
    };
  }
}
