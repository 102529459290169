<!--
<ng-container *ngIf="appState === AppStateEnum.Boot">
  boot
</ng-container>
<ng-container *ngIf="appState === AppStateEnum.AutologinCheck">
  logging in...
</ng-container>

<ng-container *ngIf="appState === AppStateEnum.LoginProgress">
  <mvp-auth-page></mvp-auth-page>
</ng-container>

<ng-container *ngIf="appState === AppStateEnum.Ready">
  <app-container></app-container>
</ng-container>-->

<app-container></app-container>
<!--
<mvp-auth-dev-widget></mvp-auth-dev-widget>
-->



