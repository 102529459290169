import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions, MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
@Component({
  selector: 'app-dialog-info',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ],
  templateUrl: './dialog-info.component.html',
  styleUrl: './dialog-info.component.scss'
})
export class DialogInfoComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string; value: string}, public dialogRef: MatDialogRef<DialogInfoComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
