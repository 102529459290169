<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

<!--  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">-->
<!--    {{humanvalue}}-->
<!--  </div>-->

  <app-meta-data-onlyread *ngIf="visibility === 'MT_O'" [value]="humanvalue" [title]="label"></app-meta-data-onlyread>

  <mat-form-field *ngIf="visibility === 'MT_K' || visibility === 'MT_M'">
    <input matInput #input
           [formControl]="textFormControl"
           [value]="value"
           (input)="onChangeValue(input.value)">
    <mat-error *ngIf="textFormControl.hasError('required')">
      {{'MANDATORY_FIELD' | translate}}
    </mat-error>
  </mat-form-field>

</div>
