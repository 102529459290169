import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
    selector: 'app-meta-data-text',
    templateUrl: './meta-data-text.component.html',
    styleUrls: ['../metadata-style.scss'],
    standalone: false
})
export class MetaDataTextComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  textFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.textFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.textFormControl = new FormControl(this.value, validators);
  }

}
