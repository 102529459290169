<app-info-card
  [title]="'Felhasználó keresése'">
  <app-searchbar (searchEvent)="showAll($event)"></app-searchbar>
</app-info-card>

<h2 *ngIf="found.length==0||found.length==null" class="general-padding">Nincs Találat</h2>

<mat-selection-list multiple="false" [(ngModel)]="selectedUser">
  <ng-container *ngIf="found.length!=0">
    <mat-list-option *ngFor="let user of found" [value]="user">
      {{user.userName}} - {{user.orgName}}
    </mat-list-option>
  </ng-container>
</mat-selection-list>
