import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";

@Component({
  selector: 'app-meta-data-check',
  templateUrl: './meta-data-check.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataCheckComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  protected readonly console = console;

  fixValue(value : number|string|boolean ){
    let isOn: boolean;
    if (typeof value === 'boolean') {
      isOn = value;
    } else if (typeof value === 'string') {
      isOn = !(value === 'false' || value === '0');
    } else { // typeof number
      isOn = !!value;
    }
    return isOn ? 'on' : '';
  }

  ngOnInit(): void {
    if(this.value != '') this.value = this.fixValue(this.value);
  }

  getBorderClass() {
    if(this.visibility === 'MT_O'){
      return 'disabled-border'
    } else if (this.visibility === 'MT_K' && !this.value){
      return 'warning-border'
    } else return 'active-border'
  }
}
