import { createReducer, on } from '@ngrx/store';
import {deleteTaskSendContent, setTaskSendContent} from "../actions/formtask.actions";
import {initialFormTaskState} from "../states/formTaskState";

export const formTaskReducer = createReducer(
  initialFormTaskState,
  on(setTaskSendContent, (state, { id, sendTaskContent }) => ({
    ...state,
    sendTaskContents: {
      ...state.sendTaskContents,
      [id]: sendTaskContent,
    },
  })),
  on(deleteTaskSendContent, (state, { id }) => {
    const { [id]: _, ...sendTaskContents } = state.sendTaskContents;
    return {
      ...state,
      sendTaskContents
    };
  })
);

