import {Component, Input, Output, EventEmitter} from '@angular/core';
@Component({
    selector: 'app-message-field',
    templateUrl: './message-field.component.html',
    styleUrls: ['./message-field.component.scss'],
    standalone: false
})
export class MessageFieldComponent{

  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

}
