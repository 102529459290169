import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {DmsUsersService} from "../../../services/api/dms-users.service";
import {DmsSzervUserItemWithNames} from "../../../model/dmsSzervUserItemWithNames";
import {DmsWfTasksService} from "../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../services/api/dms-tasks.service";
import {Router} from "@angular/router";
export interface DialogForwardData {
  type: string,
  id: number;
  name: string;
  subject: string;
}

@Component({
    selector: 'app-dialog-forward-box',
    templateUrl: './dialog-forward-box.component.html',
    styleUrls: ['./dialog-forward-box.component.scss'],
    standalone: false
})
export class DialogForwardBoxComponent{

  myControl = new FormControl<string | DmsSzervUserItemWithNames>('');
  options: DmsSzervUserItemWithNames[] = [];
  filteredOptions!: Observable<DmsSzervUserItemWithNames[]>;

  notFound: boolean = false;

  sendUser: DmsSzervUserItemWithNames = {userName: '', userId: 0, orgName: '', orgId: 0};
  constructor(
    public dialogRef: MatDialogRef<DialogForwardBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogForwardData,
    private dmsUserService: DmsUsersService,
    private dmsWfTasksService: DmsWfTasksService,
    private dmsTasksService: DmsTasksService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
  }

  onSearchClicked(value: string){
    this.dmsUserService.readDmsAcUser$(value).subscribe(response => {
      this.options = response.result;
      this.filtering();
    });
  }

  private filtering(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.userName;
        return name ? this._filter(name as string) : this.options.slice();
      }),
    );
    this.notFound = this.options.length === 0;
  }

  displayFn(user: DmsSzervUserItemWithNames): string {
    return user && user.userName ? user.userName : '';
  }

  private _filter(name: string): DmsSzervUserItemWithNames[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.userName.toLowerCase().includes(filterValue));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSendClick(){
    switch(this.data.type){
      case 'WfTask': case 'workflow': {
        this.sendWorkflowForwardRequest();
        break;
      }
      case 'Task': case 'task': {
        this.sendTaskForwardRequest();
        break;
      }
      default: {
        console.log('Hiba a küldésnél: incorrect type')
        break;
      }
    }
    this.dialogRef.close();
  }

  private sendWorkflowForwardRequest() {
    this.dmsWfTasksService.createDmsWfTaskForward$(this.data.id, this.sendUser).subscribe( response => {
      this.reloadPage();
    })
  }

  private sendTaskForwardRequest() {
    this.dmsTasksService.createDmsTaskForward$(this.data.id, this.sendUser).subscribe( response => {
      this.reloadPage();
    })
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }

}
