import {AuthProvider} from "../types";

export enum LoginRequestType {
  Automatic, // ha nics station vagy 1 van a userhez, akkor automatikusan megtortenik az authentikacio, ha nem, akkor nem
  WithStations, // a megadott station
}

export enum LoginResponseType {
  Success = 0,
  MultipleStations = 1, // a cookie-k nem lettek letrehozva
  NotLoggedIn = 2, // nem sikerult az autologin
}

//   documentType: LoginRequestType;
//   provider: AuthProvider;
//   providerToken: string;
//   station?: string | null;
// }
export interface LoginRequest {
  credential: string;
  selectedStation?: string;
}

export interface XLoginRequest {
  provider: AuthProvider;
  providerToken: string;
  selectedStation?: string;
}

/**
 * az /xlogin visszatérése
 */
export type AnyLoginResponse = LoginSuccessResponse | LoginMultiStationResponse | NotLoggedInResponse;

/**
 * a user létezik, rendben van, sikerült beléptetni, a cookie-k kész vannak
 */
export interface LoginSuccessResponse {
  type: LoginResponseType.Success;
  email: string;
  provider: AuthProvider;
  activeStation: string | null;
  stationList: string[];
  roles?: string[];
}

/**
 * a user létezik, rendben van, de több állomáshoz is be van regisztrálva,
 * így további műveletek szükségesek az authentikáció elvégzéséhez
 */
export interface LoginMultiStationResponse {
  type: LoginResponseType.MultipleStations;
  stationList: string[];
}

/**
 * User: harold
 * Date: 2023.08.16.
 */
/**
 * az xautologin adja vissza, ha nincs belepve a dms refresh token alapjan
 */
export interface NotLoggedInResponse {
  type: LoginResponseType.NotLoggedIn;
}
