import { createAction, props } from '@ngrx/store';

export const setTaskSendContent = createAction(
  '[Form] Set Task Send Content',
  props<{ id: number; sendTaskContent: {comment: string} }>(),
);

export const deleteTaskSendContent = createAction(
  '[Form] Delete Task Send Content',
  props<{ id: number }>(),
);
