import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  private _showWorkflow = true;
  private _showTask = true;
  private _showAllTasks = false;
  constructor() { }

  get showWorkflow(): boolean {
    return this._showWorkflow;
  }

  set showWorkflow(value: boolean) {
    this._showWorkflow = value;
  }

  get showTask(): boolean {
    return this._showTask;
  }

  set showTask(value: boolean) {
    this._showTask = value;
  }


  get showAllTasks(): boolean {
    return this._showAllTasks;
  }

  set showAllTasks(value: boolean) {
    this._showAllTasks = value;
  }

}
