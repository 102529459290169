<div class="metadata-div">

  <span class="input-field-label" [matTooltip]="label" matTooltipPosition="above">
    <b>{{label}}</b>
  </span>

<!--  <div *ngIf="visibility === 'MT_O'" class="only-read-metadata-data-div">-->
<!--    {{humanvalue}}-->
<!--  </div>-->
  <app-meta-data-onlyread *ngIf="visibility === 'MT_O'" [value]="humanvalue" [title]="label"></app-meta-data-onlyread>

  <mat-form-field *ngIf="visibility === 'MT_K' || visibility === 'MT_M'"
                  [ngClass]="{'error-state': visibility==='MT_K' && !value}">

    <input matInput
           type="text"
           #input
           [placeholder]="displayValue"
           [formControl]="myControl"
           [matAutocomplete]="auto" (input)="showAll(input.value)"
           (focusout)="input.value=displayValue">
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      autoActiveFirstOption="true">
      <mat-option *ngFor="let option of filteredOptions |async" [value]="option"
                  (onSelectionChange)="onChangeValue(option.orgId.toString()+'/'+option.userId.toString());
                  displayValue=option.userName+' - '+option.orgName;
                  onChangeHumanValue(option.userName+' - '+option.orgName)">
        {{option.userName}} - {{option.orgName}}
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="visibility==='MT_K' && !value" class="error-message">
      {{'MANDATORY_FIELD' | translate}}
    </mat-hint>
  </mat-form-field>

</div>
