import {Component, HostListener, OnInit} from '@angular/core';
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField, MatHint, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  MatStep,
  MatStepContent,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious,
  StepperOrientation
} from "@angular/material/stepper";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {SharedModule} from "../../../modules/shared/shared.module";
import {forkJoin, map, Observable, startWith} from "rxjs";
import {BreakpointObserver} from "@angular/cdk/layout";
import {DmsSzervUserItemWithNames} from "../../../model/dmsSzervUserItemWithNames";
import {DmsAttachmentSchema} from "../../../model/dmsAttachmentSchema";
import {DmsMetaService} from "../../../services/api/dms-meta.service";
import {DmsAttachmentTypeItem} from "../../../model/dmsAttachmentTypeItem";
import {DmsPartnerItem} from "../../../model/dmsPartnerItem";
import {DmsExecutorType} from "../../../model/dmsExecutorType";
import {DmsPriority} from "../../../model/dmsPriority";
import {DmsSzervItemWithNames} from "../../../model/dmsSzervItemWithNames";
import {DmsLinkedItem} from "../../../model/dmsLinkedItem";
import {DmsUsersService} from "../../../services/api/dms-users.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DmsAttachmentPayloadSchema} from "../../../model/dmsAttachmentPayloadSchema";
import {TaskPerformers, TaskStartRequestSchema} from "../../../model/DmsTaskStartRequestSchema";
import {DmsTasksService} from "../../../services/api/dms-tasks.service";
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {QuillModule} from "ngx-quill";

@Component({
    selector: 'app-dialog-new-task-box',
    standalone: true,
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatIcon,
        MatIconButton,
        TranslateModule,
        MatStepper,
        AsyncPipe,
        MatStep,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatStepperNext,
        MatStepperPrevious,
        MatSelect,
        MatOption,
        NgForOf,
        NgIf,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        MatSuffix,
        MatHint,
        FormsModule,
        MatAutocompleteTrigger,
        MatAutocomplete,
        SharedModule,
        MatStepContent,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        QuillModule
    ],
    templateUrl: './dialog-new-task-box.component.html',
    styleUrl: './dialog-new-task-box.component.scss'
})
export class DialogNewTaskBoxComponent implements OnInit {

  isUploading = false;

  isInitialLoading: boolean = true;

  stepperOrientation: Observable<StepperOrientation>;

  allowedExecutorTypes: DmsExecutorType[] = [];
  selectedExecutorType!: DmsExecutorType;

  partnerControl = new FormControl<string | DmsPartnerItem>('');
  partnerNotFound: boolean = false;
  filteredPartnerOptions!: Observable<DmsPartnerItem[]>;
  partnerOptions: DmsPartnerItem[] = [];
  selectedPartner: DmsPartnerItem | null = null;
  partnerSearchActive = false;

  priorityList: DmsPriority[] = [];
  selectedPriority: DmsPriority | null = null;

  selectedStartDate : Date | null = null;
  minDate = new Date();
  minDeadlineDate = new Date();
  maxStartDate!: Date | null;

  deadlineControl = new FormControl;
  // deadlineHoursControl = new FormControl(
  //   { value: 0, disabled: false },
  //   [Validators.min(0), Validators.max(23)]
  // );
  // deadlineMinutesControl = new FormControl(
  //   { value: 0, disabled: false },
  //   [Validators.min(0), Validators.max(59)]
  // );
  deadlineTimeControl = new FormControl('00:00', [this.timeValidator]);
  deadlineDate: Date | null = null;
  //isDateValid: boolean = true;

  needsApprovalFromCreator: boolean | null = null;

  recipientUserControl = new FormControl<string | DmsSzervUserItemWithNames>('');
  recipientUserNotFound: boolean = false;
  filteredRecipientUserOptions!: Observable<DmsSzervUserItemWithNames[]>;
  recipientUserOptions: DmsSzervUserItemWithNames[] = [];
  recipientUserList: DmsSzervUserItemWithNames[] = [];
  selectedRecipientUser!: DmsSzervUserItemWithNames | null;
  userSearchActive = true;

  recipientOrganizationControl = new FormControl<string | DmsSzervItemWithNames>('');
  recipientOrganizationNotFound: boolean = false;
  filteredRecipientOrganizationOptions!: Observable<DmsSzervItemWithNames[]>;
  recipientOrganizationOptions: DmsSzervItemWithNames[] = [];
  recipientOrganizationList: DmsSzervItemWithNames[] = [];
  selectedRecipientOrganization!: DmsSzervItemWithNames | null;
  organizationSearchActive = true;

  isDragOver = false;
  uploadFileList: DmsAttachmentSchema[] = [];
  attachmentTypes: DmsAttachmentTypeItem[] = [];
  readonly MAX_FILE_SIZE = 104857600;

  documentControl = new FormControl;
  documentNotFound: boolean = false;
  filteredDocumentOptions!: Observable<DmsLinkedItem[]>;
  documentOptions: DmsLinkedItem[] = [];
  documentList: DmsLinkedItem[] = [];
  selectedDocument!: DmsLinkedItem | null;
  documentSearchActive = false;
  documentPattern: RegExp = /^([^\/]+\/)?[1-9]\d*-\d+\/\d{4}(\/[^\/]+)?$/;

  folderControl = new FormControl;
  folderNotFound: boolean = false;
  filteredFolderOptions!: Observable<DmsLinkedItem[]>;
  folderOptions: DmsLinkedItem[] = [];
  folderList: DmsLinkedItem[] = [];
  selectedFolder!: DmsLinkedItem | null;
  folderSearchActive = false;
  folderPattern: RegExp = /^([^\/]+\/)?[1-9]\d*\/\d{4}(\/[^\/]+)?$/;

  subject: string = '';

  firstFormGroup = this._formBuilder.group({
    subjectCtrl: ['', Validators.required],
    startDateCtrl: [null, [this.startDateValidator()]],
    deadlineCtrl: new FormControl,
    deadlineTimeCtrl: [''],
    creatorApprovalCtrl: [false],
    executorTypeCtrl: [this.allowedExecutorTypes[0] || null, Validators.required],
    descriptionCtrl: ['']
  });
  secondFormGroup = this._formBuilder.group({});
  thirdFormGroup = this._formBuilder.group({});
  fourthFormGroup = this._formBuilder.group({});

  isEditor = false;

  constructor(public dialogRef: MatDialogRef<DialogNewTaskBoxComponent>,
              breakpointObserver: BreakpointObserver,
              private _formBuilder: FormBuilder,
              private dmsMetaService: DmsMetaService,
              private dmsUserService: DmsUsersService,
              private _snackBar: MatSnackBar,
              private translate: TranslateService,
              private dmsTasksService: DmsTasksService) {
    dialogRef.disableClose = true;
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit() {

    this.firstFormGroup.controls.deadlineCtrl = new FormControl('', [this.deadlineValidator()]);

    //Initialize with current date + 30 days
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 30);
    this.firstFormGroup.controls.deadlineCtrl.setValue(initialDate);

    this.firstFormGroup.controls.deadlineTimeCtrl.setValue('00:00');

    const attachmentTypes$ = this.dmsMetaService.readDmsAttachmentType$();
    const taskStartDetails$ = this.dmsMetaService.readDmsTasksStartDetails$();

    forkJoin([attachmentTypes$, taskStartDetails$]).subscribe(([attachmentResponse, taskDetailsResponse]) => {
      if (attachmentResponse.result) {
        this.attachmentTypes = attachmentResponse.result;
      }
      if (taskDetailsResponse.result) {
        this.priorityList = taskDetailsResponse.result.priorities;
        this.allowedExecutorTypes = taskDetailsResponse.result.executorTypes;
        this.firstFormGroup.get('executorTypeCtrl')?.setValue(this.allowedExecutorTypes[0]);
        this.selectedExecutorType = this.allowedExecutorTypes[0];
      }

      this.isInitialLoading = false;
    });

    this.documentControl = new FormControl<string | DmsLinkedItem>('', [this.patternValidator(this.documentPattern)]);
    this.folderControl = new FormControl<string | DmsLinkedItem>('', [this.patternValidator(this.folderPattern)]);

    this.onSearchUserClicked('');
    this.onSearchOrganizationClicked('');
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSubjectChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.subject = inputElement.value;
  }

  displayPartnerFn(partner: DmsPartnerItem): string {
    return partner && partner.partnerFullName ? partner.partnerFullName : '';
  }

  setPartner(partner: DmsPartnerItem) {
    this.selectedPartner = partner;
  }
  onSearchPartnerClicked(value: string) {
    this.partnerSearchActive = true;
    this.dmsMetaService.readDmsAcPartners$(value).subscribe(response => {
      this.partnerOptions = response.result;
      this.filteringPartner();
    });
  }

  private filteringPartner(){
    this.filteredPartnerOptions = this.partnerControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.partnerFullName;
        return name ? this._filterPartner(name as string) : this.partnerOptions.slice();
      }),
    );
    this.partnerNotFound = this.partnerOptions.length === 0;
    this.partnerSearchActive = false;
  }

  private _filterPartner(name: string): DmsPartnerItem[] {
    const filterValue = name.toLowerCase();
    return this.partnerOptions.filter(option => option.partnerFullName.toLowerCase().includes(filterValue));
  }

  setPriority(value: DmsPriority){
    this.selectedPriority = value;
  }

  minDateValidator() {
    return (control: FormControl) => {
      const selectedDate = control.value;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); //set to midnight
      if (selectedDate && selectedDate < currentDate) {
        return { 'min': true }; // If the selected date is earlier than maxStartDate, return an error
      }
      return null; // No error
    };
  }

  dateNotBeforeTodayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const today = new Date();
      const inputDate = new Date(control.value);

      today.setHours(0, 0, 0, 0);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < today ? { 'dateNotBeforeToday': true } : null;
    };
  }

  patternValidator(pattern: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (typeof control.value === 'string' && control.value.trim() !== '') {
        const isValid = pattern.test(control.value);
        return isValid ? null : { 'patternInvalid': true };
      }
      return null;
    };
  }

  startDateValidator() {
    return (control: FormControl) => {
      if (!control.value) return null;

      const selectedDate = new Date(control.value);
      if (isNaN(selectedDate.getTime())) {
        control.setValue(null);
        return null;
      }

      const deadlineValue = this.firstFormGroup?.get('deadlineCtrl')?.value;
      const deadline = this.getValidDate(deadlineValue);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (selectedDate < currentDate) {
        return { min: true };
      }

      if (deadline && selectedDate > deadline) {
        return { max: true };
      }

      return null;
    };
  }

  deadlineValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        this.firstFormGroup.controls.deadlineTimeCtrl.disable();
        this.firstFormGroup.controls.deadlineTimeCtrl.updateValueAndValidity();
        this.firstFormGroup.controls.startDateCtrl.updateValueAndValidity();
        return null;
      }

      this.firstFormGroup.controls.deadlineTimeCtrl.enable();
      this.firstFormGroup.controls.deadlineTimeCtrl.updateValueAndValidity();

      const selectedDate = new Date(control.value);
      if (isNaN(selectedDate.getTime())) {
        control.setValue(null);
        this.firstFormGroup.controls.startDateCtrl.updateValueAndValidity();
        return null;
      }

      //const startDateValue = this.firstFormGroup?.get('startDateCtrl')?.value;
      //const startDate = this.getValidDate(startDateValue);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (selectedDate < currentDate) {
        this.firstFormGroup.controls.startDateCtrl.updateValueAndValidity();
        return { min: true };
      }

      // if (startDate && selectedDate < startDate) {
      //   this.firstFormGroup.controls.startDateCtrl.markAsTouched();
      // }

      this.firstFormGroup.controls.startDateCtrl.updateValueAndValidity();
      return null;
    };
  }

  timeValidator() {
    return (control: FormControl) => {
      const timeValue = control.value;
      if (!timeValue) {
        return { required: true };
      }

      const [hours, minutes] = timeValue.split(':').map(Number);
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        return { invalidTime: true };
      }

      return null;
    };
  }

  setStartDate(date: Date){
    // if(date) {
    //   let year = date.getFullYear();
    //   let month = (date.getMonth() + 1).toString().padStart(2, '0');
    //   let day = date.getDate().toString().padStart(2, '0');
    //   this.selectedStartDate = `${year}-${month}-${day}`;
    // } else {
    //   this.selectedStartDate = '';
    // }
    if(date) {
      this.selectedStartDate = date;
    } else {
      this.selectedStartDate = null;
    }
  }

  onDateChange(date: Date): void {
    if (date) {
      //this.isDateValid = true;
      // this.deadlineMinutesControl.enable();
      // this.deadlineHoursControl.enable();
      this.deadlineTimeControl.enable();
      this.deadlineDate = date;
      // const hours = this.deadlineHoursControl.value || 0;  // Get value from form control, default to 0 if null
      // const minutes = this.deadlineMinutesControl.value || 0;  // Get value from form control, default to 0 if null
      const timeValue = this.deadlineTimeControl.value || '00:00';
      const [hours, minutes] = timeValue.split(':').map(Number);
      this.deadlineDate.setHours(hours, minutes);
      this.maxStartDate = this.deadlineDate;
    } else {
      //this.isDateValid = false;
      // this.deadlineMinutesControl.disable();
      // this.deadlineHoursControl.disable();
      // this.deadlineHoursControl.setValue(0);
      // this.deadlineMinutesControl.setValue(0);
      this.deadlineTimeControl.disable();
      this.deadlineTimeControl.setValue('00:00');
      if (this.deadlineDate) {
        this.deadlineDate.setHours(0, 0);
      }
      this.maxStartDate = null;
    }
  }

  onTimeChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const time = inputElement.value;

    if (!time) return;

    const [hours, minutes] = time.split(':').map(Number);
    const deadlineDate = this.firstFormGroup.controls.deadlineCtrl.value;

    if (deadlineDate) {
      const updatedDate = new Date(deadlineDate);
      updatedDate.setHours(hours, minutes, 0, 0);

      this.firstFormGroup.controls.deadlineCtrl.setValue(updatedDate);
      this.firstFormGroup.controls.deadlineCtrl.updateValueAndValidity();
      this.firstFormGroup.controls.startDateCtrl.updateValueAndValidity();
    }
  }




  // validateTimeInput(type: 'hours' | 'minutes'): void {
  //   const hours = this.deadlineHoursControl.value ?? 0;  // Default to 0 if null or undefined
  //   const minutes = this.deadlineMinutesControl.value ?? 0;  // Default to 0 if null or undefined
  //   if (type === 'hours' && (hours < 0 || hours > 23)) {
  //     this.deadlineHoursControl.setValue(0);
  //   } else if (type === 'minutes' && (minutes < 0 || minutes > 59)) {
  //     this.deadlineMinutesControl.setValue(0);
  //   }
  //
  //   if (this.deadlineDate) {
  //     this.deadlineDate.setHours(hours, minutes);
  //   }
  // }

  private formatDate(originalDate: Date | null) {
    if (originalDate) {
      const year = originalDate.getFullYear();
      const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
      const day = originalDate.getDate().toString().padStart(2, '0');
      const hours = originalDate.getHours().toString().padStart(2, '0');
      const minutes = originalDate.getMinutes().toString().padStart(2, '0');
      const seconds = originalDate.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else return '';
  }

  private getValidDate(value: any): Date | null {
    if (!value || !(typeof value === 'string' || typeof value === 'number' || value instanceof Date)) {
      return null;
    }

    const date = new Date(value);
    return isNaN(date.getTime()) ? null : date;
  }


  setExecutorType(value: DmsExecutorType){
    this.selectedExecutorType = value;
  }

  displayRecipientUserFn(user: DmsSzervUserItemWithNames): string {
    return user && user.userName ? user.userName : '';
  }

  selectRecipientUser(user: DmsSzervUserItemWithNames) {
    this.selectedRecipientUser = user;
  }
  addRecipientUser() {
    if(this.selectedRecipientUser) {
      const userExists = this.recipientUserList.some(
        user => user.userId === this.selectedRecipientUser!.userId
      );
      if(!userExists) {
        this.recipientUserList.push(this.selectedRecipientUser);
      } else {
        const message = this.translate.instant('USER_ALREADY_SELECTED');
        const action = this.translate.instant('OK');
        this.openSnackBar(message, action);
      }
      this.selectedRecipientUser = null;
      this.recipientUserControl.setValue('');
      this.onSearchUserClicked('');
    }
  }

  onSearchUserClicked(value: string) {
    this.userSearchActive = true;
    this.dmsUserService.readDmsAcUser$(value).subscribe(response => {
      this.recipientUserOptions = response.result;
      this.filteringUser();
    });
  }

  private filteringUser(){
    this.filteredRecipientUserOptions = this.recipientUserControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.userName;
        return name ? this._filterUser(name as string) : this.recipientUserOptions.slice();
      }),
    );
    this.recipientUserNotFound = this.recipientUserOptions.length === 0;
    this.userSearchActive = false;
  }

  private _filterUser(name: string): DmsSzervUserItemWithNames[] {
    const filterValue = name.toLowerCase();
    return this.recipientUserOptions.filter(option => option.userName.toLowerCase().includes(filterValue));
  }

  displayRecipientOrganizationFn(organization: DmsSzervItemWithNames): string {
    return organization && organization.name ? organization.name : '';
  }

  selectRecipientOrganization(organization: DmsSzervItemWithNames) {
    this.selectedRecipientOrganization = organization;
  }

  addRecipientOrganization() {
    if(this.selectedRecipientOrganization) {
      const organizationExists = this.recipientOrganizationList.some(
        organization => organization.id === this.selectedRecipientOrganization!.id
      );
      if(!organizationExists) {
        this.recipientOrganizationList.push(this.selectedRecipientOrganization);
      } else {
        const message = this.translate.instant('ORGANIZATION_ALREADY_SELECTED');
        const action = this.translate.instant('OK');
        this.openSnackBar(message, action);
      }
      this.selectedRecipientOrganization = null;
      this.recipientOrganizationControl.setValue('');
      this.onSearchOrganizationClicked('');
    }
  }

  onSearchOrganizationClicked(value: string) {
    this.organizationSearchActive = true;
    this.dmsMetaService.readDmsAcOrganizations$(value).subscribe(response => {
      this.recipientOrganizationOptions = response.result;
      this.filteringOrganization();
    });
  }

  private filteringOrganization(){
    this.filteredRecipientOrganizationOptions = this.recipientOrganizationControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterOrganization(name as string) : this.recipientOrganizationOptions.slice();
      }),
    );
    this.recipientOrganizationNotFound = this.recipientOrganizationOptions.length === 0;
    this.organizationSearchActive = false;
  }

  private _filterOrganization(name: string): DmsSzervItemWithNames[] {
    const filterValue = name.toLowerCase();
    return this.recipientOrganizationOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  removeRecipientUser(index: number){
    this.recipientUserList.splice(index, 1);
  }

  removeRecipientOrganization(index: number){
    this.recipientOrganizationList.splice(index, 1);
  }

  uploadingFiles(event: Event){
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFilesToUploadList(input.files);
      input.value = '';
    }
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
    const files = event.dataTransfer?.files;
    if (files) {
      this.addFilesToUploadList(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    if (!this.isMouseInsideDropzone(event)) {
      this.isDragOver = false;
    }
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(_event: DragEvent) {
    this.isDragOver = false;
  }

  private isMouseInsideDropzone(event: DragEvent): boolean {
    const dropzone = document.querySelector('.dropzone') as HTMLElement;
    if (!dropzone) return false;
    const { top, left, bottom, right } = dropzone.getBoundingClientRect();
    const { clientX, clientY } = event;
    return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
  }

  private addFilesToUploadList(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) {
        this.uploadFileList.push({
          content: file,
          attachmentTypeCode: null,
          fileName: file.name
        });
      }
    }
  }

  formatFileSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size >= gb) {
      return (size / gb).toFixed(2) + ' GB';
    } else if (size >= mb) {
      return (size / mb).toFixed(2) + ' MB';
    } else if (size >= kb) {
      return (size / kb).toFixed(2) + ' KB';
    } else {
      return size + ' Bytes';
    }
  }

  removeFile(index: number) {
    this.uploadFileList.splice(index, 1);
  }

  displayDocumentFn(doc: DmsLinkedItem): string {
    return doc && doc.identifier ? doc.identifier : '';
  }

  selectDocument(document: DmsLinkedItem) {
    this.selectedDocument = document;
  }

  addDocument() {
    if (this.selectedDocument) {
      const documentExists = this.documentList.some(
        document => document.id === this.selectedDocument!.id
      );
      if (!documentExists) {
        this.documentList.push(this.selectedDocument);
      } else {
        const message = this.translate.instant('DOCUMENT_ALREADY_SELECTED');
        const action = this.translate.instant('OK');
        this.openSnackBar(message, action);
      }
      this.selectedDocument = null;
    }
  }

  onSearchDocumentClicked(value: string) {
    this.documentSearchActive = true;
    this.documentOptions = [];
    this.dmsMetaService.readDmsLinkedItemById$(value, 'alszam').subscribe(response => {
      const result = response.result as { id: number; identifier: string };
      if(result && 'id' in result && result.id != null) {
        this.documentOptions.push(result);
      }
      this.filteringDocument();
    });
  }

  private filteringDocument(){
    this.filteredDocumentOptions = this.documentControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.identifier;
        return name ? this._filterDocument(name as string) : this.documentOptions.slice();
      }),
    );
    this.documentNotFound = this.documentOptions.length === 0;
    this.documentSearchActive = false;
  }

  private _filterDocument(name: string): DmsLinkedItem[] {
    const filterValue = name.toLowerCase();
    return this.documentOptions.filter(option => option.identifier.toLowerCase().includes(filterValue));
  }

  displayFolderFn(folder: DmsLinkedItem): string {
    return folder && folder.identifier ? folder.identifier : '';
  }

  selectFolder(folder: DmsLinkedItem) {
    this.selectedFolder = folder;
  }

  addFolder() {
    if (this.selectedFolder) {
      const folderExists = this.folderList.some(
        folder => folder.id === this.selectedFolder!.id
      );
      if (!folderExists) {
        this.folderList.push(this.selectedFolder);
      } else {
        const message = this.translate.instant('FOLDER_ALREADY_SELECTED');
        const action = this.translate.instant('OK');
        this.openSnackBar(message, action);
      }
      this.selectedFolder = null;
    }
  }

  onSearchFolderClicked(value: string) {
    this.folderSearchActive = true;
    this.folderOptions = [];
    this.dmsMetaService.readDmsLinkedItemById$(value, 'foszam').subscribe(response => {
      const result = response.result as { id: number; identifier: string };
      if(result && 'id' in result && result.id != null) {
        this.folderOptions.push(result);
      }
      this.filteringFolder();
    });
  }

  private filteringFolder(){
    this.filteredFolderOptions = this.folderControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.identifier;
        return name ? this._filterFolder(name as string) : this.folderOptions.slice();
      }),
    );
    this.folderNotFound = this.folderOptions.length === 0;
    this.folderSearchActive = false;
  }

  private _filterFolder(name: string): DmsLinkedItem[] {
    const filterValue = name.toLowerCase();
    return this.folderOptions.filter(option => option.identifier.toLowerCase().includes(filterValue));
  }

  removeDocument(index: number){
    this.documentList.splice(index, 1);
  }

  removeFolder(index: number){
    this.folderList.splice(index, 1);
  }

  isAllFormValid(): boolean {
    return this.firstFormGroup.valid &&
      this.deadlineControl.valid;
  }

  createTask(){
    this.isUploading = true;
    const isDataValid = this.checkAllData();
    if (isDataValid) {
      const filePromises: Promise<DmsAttachmentPayloadSchema>[] =
        this.uploadFileList.length > 0
          ? this.uploadFileList.map(fileItem =>
            this.convertFileToBase64(fileItem.content).then(base64Content => ({
              content: base64Content,
              attachmentTypeCode: fileItem.attachmentTypeCode || null,
              fileName: fileItem.fileName
            }))
          )
          : [];

      Promise.all(filePromises).then((encodedFiles: DmsAttachmentPayloadSchema[]) => {

        const sendContent: TaskStartRequestSchema = {
          taskTitle: this.subject,
          taskDescription: this.firstFormGroup.controls.descriptionCtrl.value ? this.firstFormGroup.controls.descriptionCtrl.value : '',
          taskPartner: this.selectedPartner? this.selectedPartner.partnerId : null,
          taskPriority: this.selectedPriority? this.selectedPriority.id : null,
          taskScheduledStart: this.formatDate(this.getValidDate(this.firstFormGroup.controls.startDateCtrl.value)),
          taskDeadline: this.formatDate(this.getValidDate(this.firstFormGroup.controls.deadlineCtrl.value)),
          needsApprovalFromCreator: this.firstFormGroup.get('creatorApprovalCtrl')!.value === true,
          executorType: this.selectedExecutorType.code,
          files: encodedFiles,
          taskPerformers: this.createTaskPerformersList(),
          relatedDocIds: this.createDocList(),
          relatedFolderIds: this.createFolderList(),
        };

        const sendContentJson = JSON.stringify(sendContent, null, 2);
        this.dmsTasksService.createDmsTaskStart$(sendContentJson).subscribe({
          next: () => {
            this.dialogRef.close(true);
          },
          error: () => {
            this.isUploading = false;
          },
          complete: () => {
            this.isUploading = false;
          }
        });
      }).catch((error) => {
        // Re-enable button if there’s an error in file processing
        console.error("File processing error:", error);
        this.isUploading = false;
      });
    } else {
      this.isUploading = false;
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  checkAllData(): boolean {
    const filesValid = this.checkUploadFiles();
    const formValid = this.isAllFormValid();
    const recipientsValid = this.isRecipientFilled();
    const datesValid = this.isAllDateValid();
    return filesValid && formValid && recipientsValid && datesValid;
  }

  checkUploadFiles(){
    if(this.uploadFileList && this.uploadFileList.length > 0) {
      const tooLargeFiles = this.uploadFileList.filter(
        file => file.content.size > this.MAX_FILE_SIZE
      );
      if (tooLargeFiles.length > 0) {
        const message = this.translate.instant('REMOVE_LARGE_FILES');
        const action = this.translate.instant('OK');
        this._snackBar.open(message, action, {
          duration: 5000,
          panelClass: ['snackbar-warning']
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
    });
  }

  createTaskPerformersList(){
    let performersList: TaskPerformers = {
      users: this.recipientUserList.map(item => ({
        userId: item.userId,
        orgId: item.orgId
      })),
      organizationCodes: this.recipientOrganizationList.map(item =>  item.code)
    }
    return performersList;
  }
  createDocList(){
    return this.documentList.map(item => item.id);
  }

  createFolderList(){
    return this.folderList.map(item => item.id);
  }

  // creatorApproved() {
  //   return this.firstFormGroup.get('creatorApprovalCtrl')!.value === true;
  // }

  setCreatorApproval(value: boolean|null){
    this.needsApprovalFromCreator = value;
  }

  isRecipientFilled() {
    if(this.recipientUserList.length>0 || this.recipientOrganizationList.length>0) {
      return true;
    }
    else {
      const message = this.translate.instant('NO_RECIPIENTS_GIVEN');
      const action = this.translate.instant('OK');
      this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['snackbar-warning']
      });
      return false
    }
  }

  isAllDateValid() {
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    currentDate.setHours(0,0);
    if(this.deadlineDate && this.deadlineDate < currentDate){
      const message = this.translate.instant('DEADLINE_TOO_EARLY');
      const action = this.translate.instant('OK');
      this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['snackbar-warning']
      });
      return false
    }
    if(this.selectedStartDate && this.selectedStartDate < currentDate){
      const message = this.translate.instant('START_DATE_TOO_EARLY');
      const action = this.translate.instant('OK');
      this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['snackbar-warning']
      });
      return false
    }
    if(this.selectedStartDate && this.deadlineDate && this.selectedStartDate > this.deadlineDate){
      const message = this.translate.instant('START_DATE_IS_LATER_THAN_DEADLINE');
      const action = this.translate.instant('OK');
      this._snackBar.open(message, action, {
        duration: 5000,
        panelClass: ['snackbar-warning']
      });
      return false
    }
    return true;
  }

  setAddDocumentButton() {
    this.documentControl.markAsTouched();
    this.documentControl.updateValueAndValidity();
    if(this.selectedDocument){
      this.selectedDocument = null;
    }
  }
  setAddFolderButton() {
    this.folderControl.markAsTouched();
    this.folderControl.updateValueAndValidity();
    if(this.selectedFolder){
      this.selectedFolder = null;
    }
  }
  setAddUserButton() {
    if(this.selectedRecipientUser){
      this.selectedRecipientUser = null;
    }
  }
  setAddOrganizationButton() {
    if(this.selectedRecipientOrganization){
      this.selectedRecipientOrganization = null;
    }
  }

  showEditor(){
    this.isEditor = true;
    const text = this.firstFormGroup.controls.descriptionCtrl.value;
    const htmlContent = this.convertTextToHtml(text);
    this.firstFormGroup.controls.descriptionCtrl.setValue(htmlContent);
  }

  convertTextToHtml(text: string | null): string {
    if(text) {
      return text.split('\n').map(line => `<p>${line}</p>`).join('');
    } else return '';
  }

}
