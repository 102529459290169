import { Injectable } from '@angular/core';
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {MvpHttpClient} from "../../../mvp-auth";

@Injectable({
  providedIn: 'root'
})
export class DmsWorkflowProcessService extends BaseHttpServiceAbstract {

  constructor( http: MvpHttpClient ) { super(http); }

  createDmsWorkflowStart$(content: any) {
    return this.create$<any, any>(`workflow/start`, content);
  }

}
