<h1 class="general-padding">Üdvözlünk újra itt!</h1>
<mat-card appearance="outlined" style="margin: 1rem"  routerLink="/admin/station-list">
  <mat-card-header>
    <mat-card-title>Station management</mat-card-title>
  </mat-card-header>
  <mat-divider class="divider-spacing"></mat-divider>
  <mat-card-content>
    <mat-card-subtitle>Station keresése (Station code), listázása > update station</mat-card-subtitle>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="general-margin"  routerLink="/admin/station-new">
  <mat-card-header>
    <mat-card-title>Station create</mat-card-title>
  </mat-card-header>
  <mat-divider class="divider-spacing"></mat-divider>
  <mat-card-content>
    <mat-card-subtitle>Új station létrehozása</mat-card-subtitle>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="general-margin"  routerLink="/admin/user-list">
  <mat-card-header>
    <mat-card-title>User management</mat-card-title>
  </mat-card-header>
  <mat-divider class="divider-spacing"></mat-divider>
  <mat-card-content>
    <mat-card-subtitle>User keresése (Role, Email, Station, Id), listázása > update user</mat-card-subtitle>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="general-margin"  routerLink="/admin/user-activation-control">
  <mat-card-header>
    <mat-card-title>User-Station Management</mat-card-title>
  </mat-card-header>
  <mat-divider class="divider-spacing"></mat-divider>
  <mat-card-content>
    <mat-card-subtitle>User aktiválása (ha nem létezik, létrehozása), User deaktiválása</mat-card-subtitle>
  </mat-card-content>
</mat-card>

