import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss'],
    standalone: false
})
export class InfoCardComponent {

  @Input() backgroundImage!: string; // Input to provide the background image URL, or icon temporary
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() description!: string;
  @Input() cardContent!: { label: any; value: any , html?: boolean}[];
  @Input() additionalContent!: string;
  @Input() routerLink!: string;

}
