import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "./config.service";
import {AuthEvents} from "../mvp-auth/services/auth-events.service";
import {AuthStateEnum} from "../mvp-auth";
import {Subject, takeUntil} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

enum AppStateEnum {
  Boot,
  AutologinCheck,
  LoginProgress,
  Ready,
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  authState: AuthStateEnum = AuthStateEnum.Unknown;
  appState: AppStateEnum = AppStateEnum.Boot;

  title = 'dms-mvp-frontend';

  isProduction: boolean;


  constructor(private config: ConfigService,
              private readonly authEvents: AuthEvents,
              private translateService: TranslateService
  ) {
    this.isProduction = config.isProduction;
    this.title = this.isProduction ? 'FLEX' : 'FLEX - Dev';
    this.translateService.setDefaultLang('hu');
    this.translateService.use(localStorage.getItem('lang') || 'hu');
  }

  ngOnInit(): void {
    this.authEvents.authState$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(state => {
      this.authState = state;
      if (state === AuthStateEnum.AutoLogin) {
        this.appState = AppStateEnum.AutologinCheck;
      } else if (state === AuthStateEnum.LoginCompleted) {
        this.appState = AppStateEnum.Ready;
      } else if (this.appState !== AppStateEnum.Boot) {
        this.appState = AppStateEnum.LoginProgress;
      }
      // if (state === AuthStateEnum.LoginNeeded) {
      //   this.router.navigate(['dev', 'login']);
      // } else if (state === AuthStateEnum.LoginCompleted) {
      //   this.router.navigate(['dashboard']);
      // } else if (state === AuthStateEnum.LoginAborted) {
      //   this.router.navigate(['']);
      // }
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  protected readonly AuthStateEnum = AuthStateEnum;

}
