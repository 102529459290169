<div>
  <button (click)="dispatchAutologin()">autologin</button>
  <button (click)="dispatchLoginNeeded()">login-needed</button>
  <button (click)="dispatchLoginAborted()">login-aborted</button>
  <button (click)="dispatchLoginCompleted()">login-completed</button>
  <button (click)="dispatchLoginAdminCompleted()">login-admin-completed</button>
  <!--<button (click)="dispatchLoggedIn()">logged-in</button>-->
  <button (click)="dispatchLogout()">log-out</button>
  <code> AuthState:{{getAuthStateName()}}({{loginState?.authState}}) </code>
</div>
<div>
  <button (click)="dispatchAutoLoginSuccess()">autologin-success</button>
  <button (click)="dispatchAutoLoginMultiStation()">autologin-multistation</button>
  <button (click)="demoPage()">↣Demo</button>
  <code> Stations:{{loginState?.stationList|json}}</code>
  <code> Cred:{{loginState?.credentials??null|json}}</code>
</div>
