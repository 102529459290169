import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {SpinnerService} from "../../services/shared/spinner.service";
import {SidenavToggleService} from "../../services/shared/sidenav-toggle.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ContainerComponent implements OnDestroy{

  spinnerVisibility: boolean = false;
  private spinnerSubscription: Subscription;

  constructor(
    public spinner: SpinnerService,
    public sidenavToggle: SidenavToggleService,
    private cd: ChangeDetectorRef
  ) {
    this.spinnerSubscription = this.spinner.visibility$.subscribe((visibility) => {
      this.spinnerVisibility = visibility;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
  }

}
