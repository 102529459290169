<mat-toolbar style="padding-left: 0;  top: 0; z-index: 10; background: white; box-shadow: 0 0 4px rgba(0, 0, 0, 0.2)"
             *ngIf="!noHeader">
  <button mat-icon-button *ngIf="isSmallScreen || isAdminPage" (click)="openSidenav()"><mat-icon>menu</mat-icon></button>
  <div style="background: white; display: flex;
            justify-content: center;
            align-items: center;
            height: 100%; font-size: x-large; padding-left: 16px; padding-right: 14px"
       [ngClass]="isSmallScreen ? 'not-clickable' : 'clickable'"
       (click)="onLogoClicked()">
    <img src="assets/icons/icon-flex-72x72.png" height="16">
  </div>
  <!--<button mat-icon-button *ngIf="showMenu" (click)="navigationBack()">
    <mat-icon>arrow_back_icon</mat-icon>
  </button>-->

  <mat-tab-group *ngIf="!isSmallScreen && !isAdminPage"
                 (selectedIndexChange)="onTabChange($event)"
                 [selectedIndex]="selectedTabIndex">
    <mat-tab></mat-tab>
    <mat-tab label="{{'MY_TASKS' | translate}}"></mat-tab>
    <mat-tab label="{{'MY_DOCUMENTS' | translate}}"></mat-tab>
  </mat-tab-group>

  <span *ngIf="!isSmallScreen" class="example-spacer"></span>
  <span *ngIf="!isSmallScreen" style="font-size: small; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{userEmailService.emailAddress}}</span>
  <button class="badge-circle-button" [matMenuTriggerFor]="lang" *ngIf="!isSmallScreen">
    <div class="badge-circle">{{selectedLang.toUpperCase()}}</div>
  </button>
  <button mat-flat-button
          *ngIf="!isSmallScreen"
          (click)="logOut()"
          style="font-size: 12px; padding-right: 0; min-width: 122px">
    {{'LOGOUT' | translate}}
    <mat-icon>exit_to_app</mat-icon>
  </button>

</mat-toolbar>

<mat-menu #lang="matMenu" xPosition="before">
  <button mat-menu-item *ngFor="let language of languages"
          [disabled]="selectedLang === language.code"
          (click)="changeLanguage(language.code)">
    {{ language.name }}
  </button>
</mat-menu>
