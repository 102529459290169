import {Directive, EventEmitter, Input, Output} from "@angular/core";

@Directive({
    standalone: false
})
export abstract class BaseMetadataAbstract <T,L,V> {

  @Input() label!: L;
  @Input() value!: V;
  @Output() valueChange = new EventEmitter<T>();
  @Output() humanvalueChange = new EventEmitter<string>();
  @Input() visibility?: string;
  @Input() humanvalue?: string;

  onChangeValue(value: T){
    this.valueChange.emit(value);
  }

  onChangeHumanValue(humanvalue: string){
    if(humanvalue === '---Nincs kiválasztva---') humanvalue = '';
    this.humanvalueChange.emit(humanvalue);
  }

  protected readonly length = length;

}
