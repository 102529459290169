import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";

@Injectable({
  providedIn: 'root'
})
export class DmsDocsService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http); }

  readDmsDocuments$(){
    return this.read$<any>(`documents/my`)
  }

  readDmsDocumentById$(docId: number){
    return this.read$<any>(`${this.getDocIdApiUrl(docId)}`)
  }

  readDmsDocumentCommentsById$(docId: number){
    return this.read$<any>(`${this.getCommentApiUrl(docId)}`)
  }

  createDmsDocumentComment$(docId: number, message: string){
    let data = {comment: message};
    return this.create$<any, any>(`${this.getCommentApiUrl(docId)}`, data)
  }

  readDmsDocumentAttachmentsById$(docId: number){
    return this.read$<any>(`attachments/${this.getDocIdApiUrl(docId)}`)
  }

  private getCommentApiUrl(docId: number){
    return `comments/document/${docId}`;
  }

  private getDocIdApiUrl(docId: number){
    return `document/${docId}`;
  }

}
