import {Injectable} from '@angular/core';
import {tap} from "rxjs/operators";
import * as action from "../actions/login-state.action";
import {MvpApiHttpClient} from "../../mvp-common";
import {LoginStore} from "./login-store";
import {Subject, takeUntil} from "rxjs";
import {AuthProvider, AuthStateEnum, LoginState} from "../types";
import {AnyLoginResponse, LoginResponseType, XLoginRequest} from "./types";
import {UserEmailService} from "../../app/services/component/sidenav/user-email.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly destroyed$ = new Subject<void>();

  private readonly loginState$ = this.store.selectLoginState().pipe(takeUntil(this.destroyed$));

  constructor(
    private readonly api: MvpApiHttpClient,
    private readonly store: LoginStore,
    private user: UserEmailService
  ) {
    this.loginState$.subscribe(loginState => {
      if (loginState.authState === AuthStateEnum.AutoLogin) {
        this.handleAutoLogin(loginState);
      }
    })
  }

  xLogin(requestData: XLoginRequest) {
    //console.log('xlogin...', requestData);
    this.api.post<AnyLoginResponse>('/login', requestData).pipe(
      tap((r: any) => {
        console.log('xlogin:', r)

        this.user.emailAddress = r.email;
        this.user.setLoggedInEmail$(r.email);
        console.log('xlogin user.emailAddress', this.user.emailAddress)

      }),
    ).subscribe({
      next: r => {
        if (r.type === LoginResponseType.Success) {
          this.store.dispatch(action.xloginSuccess({response: r}));
        } else if (r.type === LoginResponseType.MultipleStations) {
          this.store.dispatch(action.xloginMultiStation({
            response: r,
            provider: requestData.provider,
            providerToken: requestData.providerToken,
          }));
        } else {
          throw new Error('unexpected xlogin response!');
        }
      }
    })
  }

  providerSignInCompleted(provider: AuthProvider, providerToken: string) {
    this.api.post<AnyLoginResponse>('/login', <XLoginRequest>{
      provider,
      providerToken,
    }).pipe(
      //tap((r) => console.log('xlogin:', r)),
    ).subscribe({
      next: r => {
        if (r.type === LoginResponseType.Success) {
          this.store.dispatch(action.xloginSuccess({response: r}));
        } else if (r.type === LoginResponseType.MultipleStations) {
          this.store.dispatch(action.xloginMultiStation({
            response: r,
            provider: provider,
            providerToken: providerToken,
          }));
        } else {
          throw new Error('unexpected xlogin response!');
        }
      }
    })
  }

  // autoLogin() {
  //   this.store.dispatch(action.autoLogin());
  // }

  private handleAutoLogin(loginState: LoginState) {
    this.api.get<AnyLoginResponse>('/whoami', {})
      .pipe(
        //tap(r => console.log('xautologin', r))
      ).subscribe({
      next: r => {
        if (r.type === LoginResponseType.Success) {
          this.store.dispatch(action.xloginSuccess({response: r}));
        } else if (r.type === LoginResponseType.NotLoggedIn) {
          this.store.dispatch(action.loginNeeded());
        } else if (r.type === LoginResponseType.MultipleStations) {
          throw new Error('xautologin does not supports multi station response!');
        } else {
          throw new Error('unexpected xautologin response!');
        }
      }
    });
  }
}
