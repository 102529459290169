import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DmsWfTasksService} from "../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../services/api/dms-tasks.service";
import {DmsDocsService} from "../../../services/api/dms-docs.service";
import {Router} from "@angular/router";

export interface DialogMessageData {
  type: string,
  id: number;
  title: string;
  subtitle: string;
  message: string;
}

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-message-box.component.html',
  styleUrls: ['./dialog-message-box.component.scss']
})
export class DialogMessageBoxComponent implements OnInit{

  send_comment: string = '';
  constructor(
    public dialogRef: MatDialogRef<DialogMessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessageData,
    private dmsWfTasksService: DmsWfTasksService,
    private dmsTasksService: DmsTasksService,
    private dmsDocsService: DmsDocsService,
    private router: Router
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.send_comment = this.data.message;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSendClick(){
    switch(this.data.type){
      case 'WfTask': case 'workflow' : {
        this.sendWorkflowCommentRequest();
        break;
      }
      case 'Task': case 'task' : {
        this.sendTaskCommentRequest();
        break;
      }
      case 'Document': case 'document' : {
        this.sendDocumentCommentRequest();
        break;
      }
      default: {
        console.log('Hiba a küldésnél: incorrect type', this.send_comment, this.data.id)
        break;
      }
    }
    this.dialogRef.close();
  }

  private sendWorkflowCommentRequest() {
    this.dmsWfTasksService.createDmsWfTaskComment$(this.data.id, this.send_comment).subscribe( response => {
      this.reloadPage();
    })
  }

  private sendTaskCommentRequest() {
    this.dmsTasksService.createDmsTaskComment$(this.data.id, this.send_comment).subscribe( response => {
      this.reloadPage();
    })
  }

  private sendDocumentCommentRequest() {
    this.dmsDocsService.createDmsDocumentComment$(this.data.id, this.send_comment).subscribe( response => {
      this.reloadPage();
    })
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }
}
