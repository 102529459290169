import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo} from "../../../../services/component/navigation/navigation.service";
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseComponentAbstract} from "../../../../../class/base.component.abstract";
import {DmsAttachmentsService} from "../../../../services/api/dms-attachments.service";
import {DocusignService} from "../../../../services/api/docusign.service";
import {PrevPageService} from "../../../../services/component/navigation/prev-page.service";

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent extends BaseComponentAbstract {

  private readonly messageEventListener: (event: MessageEvent<any>) => void;

  constructor(
    public dmsAttachmentsService: DmsAttachmentsService,
    private _snackBar: MatSnackBar,
    private docusignService: DocusignService,
    private prevPageService: PrevPageService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router);
    this.messageEventListener = this.receiveMessage.bind(this);
    window.addEventListener('message', this.messageEventListener);
    const tempRoute = this.route.snapshot.data['type'] + '/attachment-list/' + this.route.snapshot.paramMap.get('id');
    this.prevPageService.setPreviousRoute(tempRoute);
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: 'Csatolmányok', actions: []
    };
  }

  download(attachmentGuid: string) {
    this.dmsAttachmentsService.readDmsAttachmentByGuid$(attachmentGuid);
    let message: string = 'A fájl letöltése sikeres!';
    this.openSnackBar(message, "Rendben");
  }

  signDoc(attachmentGuid: string) {
    this.docusignService.docusignGetUrlByAttachmentGuid$(attachmentGuid).subscribe( response => {
      const newTab = window.open(response.result.url);
      const handleBeforeUnload = () => {
        this.reloadPage();
      };
      newTab!.addEventListener('beforeunload', handleBeforeUnload);
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: "center", verticalPosition: "bottom"
    });
  }

  receiveMessage(event: MessageEvent<any>) {
    if (event.data && event.data.type === 'REFRESH') {
      this.reloadPage();
    }
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }

}
