import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatMenuPanel} from "@angular/material/menu";
import {Router} from "@angular/router";
import {ListViewItem} from "../../../shared/interfaces/shared/ListViewItem";
import {MatDialog} from "@angular/material/dialog";
import {DialogMessageBoxComponent} from "../dialog-message-box/dialog-message-box.component";
import {DialogForwardBoxComponent} from "../dialog-forward-box/dialog-forward-box.component";

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss'],
    standalone: false
})
export class ListViewComponent {

  @Input() routerType!: string;
  @Input() items: ListViewItem[] = [];
  @Input() menuItem: boolean = true;
  @Input() listTitle: string = '';
  selectedId!: number;

  @Output() denyEvent = new EventEmitter<number>();
  @Output() acceptEvent = new EventEmitter<number>();

  @Output() downloadAttachment = new EventEmitter<string>();
  @Output() signAttachment = new EventEmitter<string>();

  constructor(public router: Router, public dialog: MatDialog) { }

  changeIcon(status: string) {
    if (this.routerType==='task'){
      switch (status){
        case '1_letrehozva':{
          return 'assignment_late';
        }
        case '1_elfogadva':{
          return 'assignment';
        }
        case '1_elutasitva':{
          return 'assignment_return';
        }
        case '1_lezarva':{
          return 'assignment_turned_in';
        }
        case '1_atiranyitva':{
          return 'assignment_ind';
        }
        case '1_megszunt': case '1_sztornozott': {
          return 'delete';
        }
        default: return '';
      }
    } else if (this.routerType==='workflow'){
      switch (status){
        case 'FA_U':{ //Új
          return 'assignment';
        }
        case 'FA_K':{ //Elvégezve
          return 'assignment_turned_in';
        }
        case 'FA_A':{ //Átadva
          return 'assignment_turned_in';
        }
        case 'FA_M':{ //Megszűnt
          return 'assignment_turned_in';
        }
        default: return '';
      }
    } else if (this.routerType==='document'){
      if (status == 'Csatolmány') return 'attach_file';
      else return '';
    } else {
      return '';
    }
  }

/*  checkdone(statusz: string, awaiting: MatMenuPanel, task: MatMenuPanel, finalized: MatMenuPanel, document: MatMenuPanel):  any{
    if (statusz == 'Új' || statusz == 'Elfogadva' ) {
      return task;
    } else if (statusz == 'Létrehozva') {
      return awaiting;
    } else if (statusz == 'Lezárva' || statusz == 'Átirányítva' || statusz == 'Elutasítva') {
      return finalized;
    } else return document;
  }*/

  docHasNoAttach = false;
  checkDone (status: string,
             awaiting: MatMenuPanel,
             task: MatMenuPanel,
             finalized: MatMenuPanel,
             document: MatMenuPanel
  ) {

    this.docHasNoAttach = false;

      switch (status) {
        case '1_elfogadva':
          case 'FA_U':
          return task;
        case '1_letrehozva':
          return awaiting;
        case '1_elutasitva':
          case '1_lezarva':
            case '1_atiranyitva':
              case '1_megszunt':
                case '1_sztornozott':
                  case 'FA_K':
                    case 'FA_A':
                      case 'FA_M':
                        return finalized;
        default:
          if(this.routerType == 'document') {
            return document;
          } else {
            return finalized;
          }
      }
  }

  selectItem(item: number){
    this.selectedId = item;
  }

  onDenyClicked() {
    this.denyEvent.emit(this.selectedId);
  }

  onAcceptClicked() {
    this.acceptEvent.emit(this.selectedId);
  }

  onDownloadAttachmentClicked(attachmentGuid: string) {
    this.downloadAttachment.emit(attachmentGuid);
  }

  onSignAttachmentClicked(attachmentGuid: string) {
    this.signAttachment.emit(attachmentGuid);
  }

  setRouterLink(item: ListViewItem){
    if(item.id){
      if(item.status==='FA_U' || item.status==='1_elfogadva'){
        this.router.navigate([this.routerType, 'do' , item.id]);
      } else {
        this.router.navigate([this.routerType, item.id]);
      }
    } else if(item.stationId){
      this.router.navigate(['/admin/station-item/', item.stationId]);
    } else if(item.userId){
      this.router.navigate(['/admin/user-item/', item.userId]);
    }
  }

  onSendMessage() {
    const viewData = this.getSelectedItemDetails();
    this.dialog.open(DialogMessageBoxComponent, {
      data: {
        type: this.routerType,
        id: this.selectedId,
        subtitle: this.routerType === "document" ? viewData.name : '',
        title: viewData.subject,
        message: '',
      }
    })
  }

  onForwardTask() {
    const viewData = this.getSelectedItemDetails();
    this.dialog.open(DialogForwardBoxComponent, {
      data: {
        type: this.routerType,
        id: this.selectedId,
        name: viewData.name,
        subject: viewData.subject,
      },
      autoFocus: false
    })
  }

  private getSelectedItemDetails() {
    const selectedItem = this.items.find(item => item.id === this.selectedId);
    if(selectedItem){
      return { subject: selectedItem.title || '', name: selectedItem.subject || ''};
    }
    return { subject: '', name: ''}
  }

}
