import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import {MvpAuthModule} from "./modules/mvp-auth.module";
import {MvpCommonModule} from "../mvp-common/mvp-common.module";
import {MsalModule, MsalRedirectComponent} from "@azure/msal-angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatTabsModule} from "@angular/material/tabs";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatDialogModule} from "@angular/material/dialog";
import {MatNativeDateModule} from '@angular/material/core';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { NotFoundComponent } from './components/not-found/not-found.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatBadgeModule} from "@angular/material/badge";
import {ContainerModule} from "./modules/container/container.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";
import { DocusignFinishedComponent } from './components/mvp/shared/docusign-finished/docusign-finished.component';
import { formReducer } from "./store/reducers/form.reducer";
import { formTaskReducer } from "./store/reducers/formtask.reducer";
import { PageloadingComponent } from './components/mvp/dashboard/pageloading/pageloading.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {QuillModule} from "ngx-quill";

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    DocusignFinishedComponent,
    PageloadingComponent,

  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}, {}),
    HttpClientModule,
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps:[HttpClient]
        }
      }
    ),
    AppRoutingModule,
    BrowserAnimationsModule,
    MvpCommonModule,
    MvpAuthModule,
    MsalModule,
    CommonModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatMenuModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatBadgeModule,
    ContainerModule,
    MatTooltipModule,
    NgOptimizedImage,
    StoreModule.forFeature('form', formReducer),
    StoreModule.forFeature('formTask', formTaskReducer),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    QuillModule.forRoot({
      placeholder: '',
      modules: {
        toolbar: getToolbarOptions()
      },
      sanitize: true
    }),
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU'},
    HttpClient
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

function getToolbarOptions() {
  const isMobile = window.innerWidth <= 425;

  // Toolbar options for mobile
  const mobileToolbar = [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean']
  ];

  // Toolbar options for desktop
  const desktopToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large'] }],
    [{ font: ['sans serif', 'serif', 'monospace'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ color: [] }, { background: [] }],
    ['blockquote'],
    ['link'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['clean']
  ];

  return isMobile ? mobileToolbar : desktopToolbar;
}
