import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {MatInputModule} from "@angular/material/input";
// import {MatSelectModule} from "@angular/material/select";
// import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    // MatInputModule,
    // MatSelectModule,
    // FormsModule,
  ]
})
export class MvpCommonModule {
}
