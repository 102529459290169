import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable, startWith, map} from "rxjs";
import {DmsMfTorzsItem} from "../../../../../model/dmsMfTorzsItem";
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {DmsMetaService} from "../../../../../services/api/dms-meta.service";
import {DmsMfTorzsItemsByLeiro} from "../../../../../model/dmsMfTorzsItemsByLeiro";

@Component({
    selector: 'app-meta-data-mftorzs-ac',
    templateUrl: './meta-data-mftorzs-ac.component.html',
    styleUrls: ['../metadata-style.scss'],
    standalone: false
})
export class MetaDataMftorzsAcComponent extends BaseMetadataAbstract<number, string, number> {

  @Input() params: string = '';
  @Input() mfTorzsOptionList: DmsMfTorzsItemsByLeiro[] = [];

  optionList: DmsMfTorzsItem[] = [];

  myControl = new FormControl('');
  displayValue: string = '';

  filteredOptions!: Observable<DmsMfTorzsItem[]>;
  selectedOption!: DmsMfTorzsItem;

  constructor(public mfTorzsService: DmsMetaService) {
    super();
  }

  ngOnInit() {
    if(this.visibility != 'MT_O') {
      if(this.mfTorzsOptionList && this.mfTorzsOptionList.length > 0){
        let leiroIdList: number[] = this.params.split(',').map(Number);
        leiroIdList.forEach(param => {
          const foundItem = this.mfTorzsOptionList.find(item => Number(item.mfTorzsLeiroId) === param);
          if (foundItem) {
            foundItem.items?.forEach(data => {
              this.optionList.push(data);
            })
          }
        });
        this.setAcComponentData();
      } else {
        /*this.mfTorzsService.readDmsMfTorzsListByLeiroId$(this.params).subscribe(response => {
          this.optionList = response.result[0].items;
          this.setAcComponentData();
        });*/
      }
    }
  }

  private setAcComponentData(){
    if(this.visibility != 'MT_K') {
      this.optionList.unshift({mfTorzsId: 0, mfTorzsName: '---'});
    }
    if(this.value && this.value != null) {
      this.selectedOption = this.optionList.find((item) => (item.mfTorzsId == this.value))!;
      this.displayValue = this.selectedOption.mfTorzsName;
    } else this.displayValue = '---'
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (typeof value === 'string') {
          return value;
        }
        const typedValue = value as DmsMfTorzsItem | null;
        return typedValue && 'mfTorzsName' in typedValue ? typedValue.mfTorzsName : '';
      }),
      map(name => name ? this._filter(name) : this.optionList.slice())
    );
  }

  private _filter(name: string): DmsMfTorzsItem[] {
    const filterValue = name.toLowerCase();
    return this.optionList.filter(option => option.mfTorzsName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(option: DmsMfTorzsItem): string {
    return option && option.mfTorzsName ? option.mfTorzsName : '';
  }

  send(option: DmsMfTorzsItem){
    this.displayValue = option.mfTorzsName;
  }

}
