import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo, NavigationService} from "../../../../services/component/navigation/navigation.service";
import {IncomingDataUsers} from "../../../../shared/interfaces/shared/IncomingDataUsers";
import {DmsSzervUserItemWithNames} from "../../../../model/dmsSzervUserItemWithNames";
import {BaseComponentAbstract} from "../../../../../class/base.component.abstract";
import {DmsUsersService} from "../../../../services/api/dms-users.service";
import {DmsWfTasksService} from "../../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../../services/api/dms-tasks.service";


@Component({
    selector: 'app-forward',
    templateUrl: './forward.component.html',
    styleUrls: ['./forward.component.scss'],
    standalone: false
})
export class ForwardComponent extends BaseComponentAbstract {

  id: number;
  type: string;

  value = '';

  incomingData: IncomingDataUsers[] = [];

  found: DmsSzervUserItemWithNames[] = [];


  selectedUser!: DmsSzervUserItemWithNames[];


  constructor(
    private navService: NavigationService,
    public dmsWfTasksService: DmsWfTasksService,
    public dmsTasksService: DmsTasksService,
    private dmsUserService: DmsUsersService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(route, router)
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.type = this.route.snapshot.data['type'];
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: 'Átirányítás', actions: [
        {
          icon: 'send',
          click: () => {
            this.forwardTask();
          },
          caption: "Elküld",
          tooltip: 'Elküld',
        },
      ]
    };
  }

  showAll(value: string) {
    this.found = [];
    this.dmsUserService.readDmsAcUser$(value).subscribe(response => {
      this.found = response.result;
    });
  }

  checkForwardError() {
    return !!this.selectedUser;
  }

  private forwardTask() {
    if (this.checkForwardError()) {
      switch (this.type) {
        case 'workflow': {
          this.sendWorkflowForwardRequest();
          break;
        }
        case 'task': {
          this.sendTaskForwardRequest();
          break;
        }
        default: {
          alert('Hiba a küldésnél: incorrect url');
          break;
        }
      }
    } else alert('Hiba az elküldendő adatokban!');
  }

  private sendWorkflowForwardRequest(){
    this.dmsWfTasksService.createDmsWfTaskForward$(this.id, this.selectedUser[0])
      .subscribe(response => {
        this.router.navigate(['/workflow/list']);
      });
  }

  private sendTaskForwardRequest(){
    this.dmsTasksService.createDmsTaskForward$(this.id, this.selectedUser[0])
      .subscribe(response => {
        this.router.navigate(['/task/list']);
      });
  }
}
