import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {HttpParams} from "@angular/common/http";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";

@Injectable({
  providedIn: 'root'
})
export class DmsUsersService extends BaseHttpServiceAbstract{

  constructor(http: MvpHttpClient) { super(http) }

  readDmsAcUser$(content: string){
    if(content.length == 0){
      content = '%';
    }
    let params: HttpParams = new HttpParams();
    params = params.append("username", content);

    return this.read$<any>(`ac/user`, params)
  }
}
