import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AnyLoginResponse,
  AuthStateEnum,
  Credentials,
  LoginResponseType,
  LoginState,
  LoginStore, XLoginRequest
} from "../../index";
import {Subject, takeUntil} from "rxjs";
import {tap} from "rxjs/operators";
import * as action from "../../actions/login-state.action";
import {MvpApiHttpClient} from "../../../mvp-common";

@Component({
    selector: 'mvp-auth-page',
    templateUrl: './auth-page.component.html',
    styleUrls: ['./auth-page.component.scss'],
    standalone: false
})
export class AuthPageComponent implements OnInit, OnDestroy {
  readonly destroyed$ = new Subject<void>();

  loginState$ = this.store.selectLoginState();
  // canLogin$ = this.loginState$.pipe(
  //   takeUntil(this.destroyed$),
  //   map(loginState => !!loginState.credentials),
  // )

  loginState: LoginState = <LoginState><unknown>undefined;
  authStateName?: string;
  canLogin: boolean = false;
  selectedStation: string = '';
  lastSelected: string = localStorage.getItem('lastStation') ?? '';
  stationSelectionMode: boolean = false;


  constructor(
    private readonly store: LoginStore,
    private readonly api: MvpApiHttpClient,
  ) {
  }

  ngOnInit(): void {
    this.loginState$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(loginState => {
      this.loginState = loginState;
      this.authStateName = AuthStateEnum[this.loginState.authState];
      this.canLogin = !!loginState.credentials;
      this.stationSelectionMode = loginState.authState === AuthStateEnum.LoginMultiStation;
      this.selectedStation = this.calculateSelectedStation(loginState.credentials, loginState.stationList);
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private calculateSelectedStation(credentials: Credentials | undefined, stationList: string[]) {
    //if (!credentials) return '';
    if (stationList.length === 0) return '';
    if (stationList.length === 1) return (this.lastSelected = stationList[0]);
    console.log(stationList.indexOf(this.lastSelected), stationList, this.lastSelected);
    if (~stationList.indexOf(this.lastSelected)) return this.lastSelected;
    return '';
  }

  stationSelectionChanged(value: string) {
    this.lastSelected = value;
    localStorage.setItem('lastStation', value);
  }

  doLogin() {
    this.api.post<AnyLoginResponse>('/login', <XLoginRequest>{
      provider: this.loginState?.provider,
      providerToken: this.loginState?.providerToken,
      selectedStation: this.selectedStation,
    }).pipe(
      tap((r) => console.log('xlogin/2:', r)),
    ).subscribe({
      next: (r) => {
        if (r.type === LoginResponseType.Success) {
          this.store.dispatch(action.xloginSuccess({response: r}));
        } else {
          throw new Error('unexpected response! ' + r.type);
        }
      },
    });
  }

  protected readonly AuthState = AuthStateEnum;
}
