<section>
  <div class="provider-buttons" [attr.disabled]="stationSelectionMode ? true : null">
    <mvp-auth-google-button></mvp-auth-google-button>
    <mvp-auth-microsoft-button></mvp-auth-microsoft-button>
  </div>


  <ng-container *ngIf="stationSelectionMode">
      <mat-form-field *ngIf="loginState.stationList.length">
        <mat-label>Station</mat-label>
        <mat-select [(ngModel)]="selectedStation" name="station" (ngModelChange)="stationSelectionChanged($event)"
                    [disabled]="loginState.stationList.length <=1"
        >
          <mat-option [value]="0">Nincs</mat-option>
          <mat-option *ngFor="let station_id of loginState.stationList" [value]="station_id">
            {{station_id}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <section>
      <button mat-flat-button color="primary"
              (click)="doLogin()"
      >Login
      </button>
    </section>
  </ng-container>

  <div>
  <textarea readonly rows="10" style="min-width: 300px;">{{loginState | json}}
    {{authStateName | json}}
  </textarea>
  </div>
  <code>{{selectedStation | json}}</code>
  <br/>
  <code>{{lastSelected | json}}</code>
</section>
