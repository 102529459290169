import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() {
  }

  public convertBase64UrlToBase64(input: string): string {
    const
      remainder = input.length % 4;
    if (remainder) {
      const padlen = 4 - remainder;
      input += ''.padEnd(padlen, '=');
    }
    return input.replace(/-/g, '+').replace(/_/g, '/');
  }
}
