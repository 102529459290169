/**
 * User: harold
 * Date: 2023.08.16.
 */

import { Injectable } from '@angular/core';
import {Actions} from '@ngrx/effects';

@Injectable()
export class LoginStateEffects {

//   logout$ = createEffect(()=>this.actions$.pipe(
//     ofType(action.logout),
//     tap(() => {
//       console.log('LoginStateEffects...');
//     }),
//     //exhaustMap((x)=>{})
// //     exhaustMap(({provider})=>{
// //   console.log('LoginStateEffects:', provider);
// // }),
//   ))

  constructor(private actions$: Actions) {}
}
