import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import { catchError, finalize} from "rxjs";
import { SpinnerService } from './spinner.service';
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService, private dialog: MatDialog) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isDialogOpen = this.dialog.openDialogs.length > 0;

    if (!isDialogOpen) {
      this.spinnerService.show();
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (!isDialogOpen) {
          this.spinnerService.hide();
        }
        throw err;
      }),
      finalize(() => {
        if (!isDialogOpen) {
          this.spinnerService.hide();
        }
      }),
    );
  }
}
