import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
  IRouteAction,
  IRouteInfo,
  NavigationService
} from "../../../../services/component/navigation/navigation.service";
import {Router} from "@angular/router";


const MAX_ACTION_ICONS = 2;
const smallscreen = 560;

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderActionsComponent implements OnInit {

  routeInfo: IRouteInfo | null = null;
  actions: IRouteAction[] = [];
  collapsedActions: IRouteAction[] = [];

  constructor(
    private navService: NavigationService,
    private router: Router,
    private changeDetection: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.navService.routeInfoChanged.subscribe(routeInfo => {
      this.routeInfo = this.fixRouteInfo(routeInfo);
      this.changeDetection.detectChanges();
    })
  }

  private fixRouteInfo(routeInfo: IRouteInfo | null): IRouteInfo | null {
    const actions: IRouteAction[] = [], collapsedActions: IRouteAction[] = [];
    if (routeInfo) {
      routeInfo.actions.forEach(function (action) {
        let isCollapsed = action.isCollapsed;
        if (typeof isCollapsed !== 'boolean') isCollapsed = actions.length >= MAX_ACTION_ICONS;
        if (!isCollapsed && window.innerWidth > smallscreen) {
          actions.push(action);
        } else {
          collapsedActions.push(action);
        }
      });
    }
    if(collapsedActions.length === 1 && actions.length === 0) {
      actions.push(collapsedActions[0]);
    }
    this.actions = actions;
    this.collapsedActions = collapsedActions;
    return routeInfo;
  }

  handleClick(event: MouseEvent, action: IRouteAction, routeInfo: IRouteInfo) {
    if (action.click) {
      action.click(action, routeInfo);
    } else if (typeof action.targetRoute === 'string') {
      this.router.navigate([action.targetRoute], {});
    } else if (action.targetRoute) {
      this.router.navigate(action.targetRoute, {});
    }
  }

  showSpacer(){
    const pageWidth = window.innerWidth;
    const element = document.getElementById('headerspan');
    const elementWidth = element?.offsetWidth;
    const allowedMaxWidth = pageWidth*0.63;
    return !(elementWidth && (elementWidth >= allowedMaxWidth && this.actions.length < 2));
  }

  protected readonly window = window;
}
