import {IRouteInfo, NavigationService} from "../app/services/component/navigation/navigation.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Directive, inject, OnDestroy, OnInit} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Directive({})
export abstract class BaseComponentAbstract implements OnDestroy, OnInit{

  route: ActivatedRoute;

  router: Router;

  abstract setupNavigation(params: ParamMap): IRouteInfo;

  subscriptionOfParam?: Subscription;

  subscriptionOfNavigationEvent?: Subscription;

  navigationService: NavigationService = inject(NavigationService);

  initSetupRoute: boolean = true;

  requestData!: any;
  requestRoles!: any;

  protected constructor(route: ActivatedRoute, router: Router) {
    this.route = route;
    this.router = router;
  }

  ngOnInit(): void {
    this.route.data.subscribe((data:any)=> {
      if(typeof data.requestData === 'undefined'){
        console.warn('Resolver "requestData" does not exist in routing');
      }
      this.requestData = data.requestData;
      if(data.requestRoles) {
        this.requestRoles = data.requestRoles;
        //console.log(this.requestRoles.rolesList)
      }
    })
    if(this.initSetupRoute) {
      this.callSetupRoute();
    }
  }
  ngOnDestroy() {
    this.subscriptionOfParam?.unsubscribe();
    this.subscriptionOfNavigationEvent?.unsubscribe();
  }

  callSetupRoute(){
    this.navigationService.setupRouteHandling(this);
  }

  dateFormatting(timestamp: string | null, date?: boolean){
    if (timestamp && timestamp.length>1) {
      let dateArray = timestamp.split('T');
      dateArray[1] = dateArray[1].substring(0, 5);
      if (date) {
        return dateArray[0];
      } else {
        return dateArray.join(' ');
      }
    }
    else return '';
  }

  statusFormatting(status: any){
      switch (status) {
        case 'FA_U': {
          status = 'Új';
          break;
        }
        case 'FA_K': {
          status = 'Elvégezve';
          break;
        }
        case 'FA_A': {
          status = 'Átadva';
          break;
        }
        case 'FA_M': {
          status = 'Megszűnt';
          break;
        }
        case '1_letrehozva': {
          status = 'Létrehozva';
          break;
        }
        case '1_elfogadva': {
          status = 'Elfogadva';
          break;
        }
        case '1_elutasitva': {
          status = 'Elutasítva';
          break;
        }
        case '1_lezarva': {
          status = 'Lezárva';
          break;
        }
        case '1_atiranyitva': {
          status = 'Átirányítva';
          break;
        }
        case '1_megszunt': {
          status = 'Megszűnt';
          break;
        }
        case '1_sztornozott': {
          status = 'Sztornózott';
          break;
        }
        case '3_draft': {
          status = 'Draft';
          break;
        }
        case '3_folyamatban': {
          status = 'Folyamatban';
          break;
        }
        case '3_lezarva': {
          status = 'Lezárva';
          break;
        }
        case '3_sztornozott': {
          status = 'Sztornózott';
          break;
        }
        default: {
          status = '';
          break;
        }
      }
      return status;
      /*
      let newStatus = status.slice(2);
      newStatus = newStatus.charAt(0).toUpperCase() + newStatus.slice(1);
      return newStatus;
      */
  }

  public trustHtml(textToHtml: string | undefined, sanitizer: DomSanitizer) {
    if (textToHtml) {
      let htmlText = this.htmlListFormatting(textToHtml);
      return sanitizer.bypassSecurityTrustHtml(htmlText);
    } else {
      return "";
    }
  }

  private htmlListFormatting(htmlString: string){
    let result = htmlString.replaceAll('<ol', '<ul');
    result = result.replaceAll('</ol', '</ul');
    return result;
  }

}
