import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo} from "../../../services/component/navigation/navigation.service";
import {BaseComponentAbstract} from "../../../../class/base.component.abstract";
import {DomSanitizer} from "@angular/platform-browser";
import {FilterDataService} from "../../../services/component/news-page/filter-data.service";
import {DmsNewsListResponseSchema} from "../../../model/dmsNewsListResponseSchema";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {MatDialog} from "@angular/material/dialog";
import {DialogNewWfTaskBoxComponent} from "../../shared/dialog-new-wf-task-box/dialog-new-wf-task-box.component";
import {DialogNewTaskBoxComponent} from "../../shared/dialog-new-task-box/dialog-new-task-box.component";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: false
})
export class DashboardComponent extends BaseComponentAbstract implements OnInit{

  workflowChecked!: boolean;
  taskChecked!: boolean;
  doneChecked!: boolean;

  refreshTime!: string;

  originalNewsData: DmsNewsListResponseSchema = [];
  sortedNewsData: DmsNewsListResponseSchema = [];

  searchValue = '';

  isStar = false;

  isSmallScreen: boolean = false;
  showFilters: boolean = true;

  constructor(
    route: ActivatedRoute,
    router: Router,
    public sanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private filterDataService: FilterDataService,
    public dialog: MatDialog,
  ){
    super(route, router);
    this.setRefreshTime();
    this.workflowChecked = this.filterDataService.showWorkflow;
    this.taskChecked = this.filterDataService.showTask;
    this.doneChecked = this.filterDataService.showAllTasks;
  }

  override ngOnInit() {
    super.ngOnInit();
    this.originalNewsData = this.requestData.allIncomingDataNews
    this.sortedNewsData = this.originalNewsData;
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if(result.matches) {
        this.isSmallScreen = true;
        this.cdr.detectChanges();
      } else {
        this.isSmallScreen = false;
        this.cdr.detectChanges();
      }
    })
  }

  setRefreshTime(): void {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    this.refreshTime = `${hours}:${minutes} ${year}.${month}.${day}`;
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: '', actions: [/*{
        icon: 'supervisor_account',
        click: () => {
          this.router.navigate(['admin-dashboard']);
        },
        caption: 'Admin page',
        tooltip: 'Admin page'
      }*/]
    };
  }

  clickDoneChecked(){
    this.filterDataService.showAllTasks = !this.filterDataService.showAllTasks;
    this.reloadPage();
  }
  clickWorkflowChecked(){
    this.filterDataService.showWorkflow = !this.filterDataService.showWorkflow;
    this.reloadPage();
  }
  clickTaskChecked(){
    this.filterDataService.showTask = !this.filterDataService.showTask;
    this.reloadPage();
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }

  search(value: string){
    this.sortedNewsData = [];
    this.searchValue = value;
    this.getTasksWithTextFilter();
  }

  getTasksWithTextFilter() {
    if (this.searchValue) {
      this.sortedNewsData = this.filterNewsItems(this.originalNewsData, this.searchValue);
    } else {
      this.sortedNewsData = this.originalNewsData;
    }
  }

  private filterNewsItems(items: DmsNewsListResponseSchema, searchTerm: string): DmsNewsListResponseSchema {

    let sortedItems = items;

    if(this.isStar){
      sortedItems = items.filter(item => {
        return(item.isFavorite);
      })
    }

    if (!searchTerm) {
        return sortedItems;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const strippedSearchTerm = lowerCaseSearchTerm.startsWith('#') ?
      lowerCaseSearchTerm.substring(1) : lowerCaseSearchTerm; //if user searches for reference number

    sortedItems = items.filter(item => {
      return (
        (item.subject && item.subject.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.status && item.status.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.taskStart && item.taskStart.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.taskDeadline && item.taskDeadline.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.taskName && item.taskName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.referenceNumber && item.referenceNumber.toLowerCase().includes(strippedSearchTerm)) ||
        (item.wfDescription && item.wfDescription.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.taskDescription && item.taskDescription.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (item.result && item.result.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });

    return sortedItems;
  }

  starClicked(){
    this.isStar = !this.isStar;
    this.sortedNewsData = [];
    this.sortedNewsData = this.filterNewsItems(this.originalNewsData, '');
  }

  setIconPath(icon: string){
    if(icon&&icon.length>0){
      return 'assets/svg/material-symbols-outlined-48px/' + icon.toString() + '.svg';
    } else {
      return 'assets/svg/pending_actions_24dp.svg';
    }
  }

  onNewTask() {
    const dialogRef = this.dialog.open(DialogNewTaskBoxComponent, {
      autoFocus: false,
      closeOnNavigation: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.reloadPage();
      }
    });
  }

  onNewWfTask() {
    const dialogRef = this.dialog.open(DialogNewWfTaskBoxComponent, {
      autoFocus: false,
      closeOnNavigation: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.reloadPage();
      }
    });
  }

}
