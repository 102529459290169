import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private visibilitySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private counterShow = 0;

  get visibility$() {
    return this.visibilitySubject.asObservable();
  }

  show() {
    if(this.counterShow<=0) {
      this.counterShow = 0; //ne legyen negatív counter
      this.visibilitySubject.next(true);
    }
    this.counterShow++;
  }

  hide() {
    this.counterShow--;
    if(this.counterShow<=0) {
      this.counterShow = 0; //ne legyen negatív counter
      this.visibilitySubject.next(false);
    }
  }

}
