import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-meta-data-textarea',
  templateUrl: './meta-data-textarea.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataTextareaComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  textareaFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.textareaFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.textareaFormControl = new FormControl(this.value, validators);
  }

}
