import { Component } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo, NavigationService} from "../../../../services/component/navigation/navigation.service";
import {BaseComponentAbstract} from "../../../../../class/base.component.abstract";
import {DmsWfTasksService} from "../../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../../services/api/dms-tasks.service";
import {DmsDocsService} from "../../../../services/api/dms-docs.service";

@Component({
    selector: 'app-write-comment',
    templateUrl: './write-comment.component.html',
    styleUrls: ['./write-comment.component.scss'],
    standalone: false
})
export class WriteCommentComponent extends BaseComponentAbstract{

  send_comment: string = '';
  id: number;
  type: string;

  constructor(
    private navService: NavigationService,
    private dmsWfTasksService: DmsWfTasksService,
    private dmsTasksService: DmsTasksService,
    private dmsDocsService: DmsDocsService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(route, router)
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.type = this.route.snapshot.data['type'];
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: 'Megjegyzés írása', actions: [
        {
          icon: 'send',
          click: () => {
            this.sendComment();
          },
          caption: 'Elküld',
          tooltip: 'Elküld'
        },
      ]
    };
  }

  private sendComment() {
    switch(this.type){
      case 'workflow': {
        this.sendWorkflowCommentRequest();
        break;
      }
      case 'task': {
        this.sendTaskCommentRequest();
        break;
      }
      case 'document': {
       this.sendDocumentCommentRequest();
        break;
      }
      default: {
        console.log('Hiba a küldésnél: incorrect url')
        break;
      }
    }
  }

  private sendWorkflowCommentRequest() {
    this.dmsWfTasksService.createDmsWfTaskComment$(this.id, this.send_comment).subscribe( response => {
      this.router.navigate([this.type + '/' + this.id]);
    })
  }

  private sendTaskCommentRequest() {
    this.dmsTasksService.createDmsTaskComment$(this.id, this.send_comment).subscribe( response => {
      this.router.navigate([this.type + '/' + this.id]);
    })
  }

  private sendDocumentCommentRequest() {
    this.dmsDocsService.createDmsDocumentComment$(this.id, this.send_comment).subscribe( response => {
      this.router.navigate([this.type + '/' + this.id]);
    })
  }

}
