import {Component, OnDestroy, OnInit} from '@angular/core';
import {catchError, Observable, of, Subject, takeUntil} from "rxjs";
import {LoginStore} from "../../services/login-store";
import {MvpApiHttpClient} from "../../../mvp-common";
import {MvpHttpClient} from "../../http/mvp-http-client";
import {tap} from "rxjs/operators";
import {LoginState} from "../../types";
import {DmsWfTasksService} from "../../../app/services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../app/services/api/dms-tasks.service";

interface QuipResponse {
  quip: string;
  idx: number;
}

@Component({
    selector: 'mvp-auth-dev-test-page',
    templateUrl: './dev-test-page.component.html',
    styleUrls: ['./dev-test-page.component.scss'],
    standalone: false
})
export class DevTestPageComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  loginState$: Observable<LoginState>;

  apiResult$?: Observable<any>;

  quip: string = '';
  quipExclude: number = -1;


  constructor(
    private readonly store: LoginStore,
    private readonly apiHttp: MvpApiHttpClient,
    private readonly http: MvpHttpClient,
    private readonly dmsWfTasksService: DmsWfTasksService,
    private readonly dmsTasksService: DmsTasksService,

  ) {
    this.loginState$ = store.selectLoginState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  ngOnInit(): void {
    this.loginState$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(loginState => {

    });
  }


  // ***

  getQuip(n?: number) {
    this.http.get<QuipResponse>(n === undefined
      ? '/quip?exclude=' + this.quipExclude
      : '/quip/' + n
    )
      .pipe(
        tap(x => console.log('quip:', x)),
      )
      .subscribe(r => {
        this.quip = r.quip ?? '';
        this.quipExclude = r.idx ?? -1;
      });
    // this.http.get<QuipResponse>('/quip').subscribe(r => {
    //     alert(r.quip);
    //   });
  }

  queryNoAuth() {
    this.apiResult$ = this.apiHttp.get('/echo/alma');
  }

  queryUserAuth() {
    this.apiResult$ = this.http.get<QuipResponse>('/quip?exclude=' + this.quipExclude).pipe(
      tap(r => this.quipExclude = r.idx)
    );
  }

  queryUserStationAuth() {
    this.apiResult$ = this.http.get('/dms/counters');
    this.apiResult$.subscribe(res => {console.log('------>res', res)});
  }

  queryGetRequest(){
    this.apiResult$ = this.http.get(`/dms/wfTask/1`);
  }

  __queryUserStationAuth() {
    const params = {
      "status": ["1_letrehozva", "1_elfogadva"].join(',')
    };
    this.apiResult$ = this.http.get('/dms/tasks/my', {params}).pipe(
      catchError((err, c) => {
        console.error(err);
        return of({error:err.error ?? err.headers?.message ?? 'unknown error'})
      })
    );
    this.apiResult$.subscribe(response => {
      console.log('------>response', response)
    })
    //this.apiResult$.pipe()
  }

  queryGetRequestParameter(){
   let stationId ='sfaj7hhqp2sc80gkko4s8gg8 ';
    this.apiResult$ = this.http.get(`/station/${stationId}`).pipe(
      catchError((err, c) => {
        console.error(err);
        return of({error:err.error ?? err.headers?.message ?? 'unknown error'})
      })
    );
    this.apiResult$.subscribe(response => {
      console.log('------>response', response)
    })

 /*   let mutId = 162;
    this.apiResult$ = this.http.get(`/dms/task/${mutId}`);
    this.apiResult$.subscribe(response => { console.log('response----->', response)})*/
  }

  queryPutRequest() {
    console.log('Put same as post');
  }

  __queryPostRequest(){
    let mutID = 154;
    this.apiResult$ = this.http.post(`/dms/comments/task/${encodeURIComponent(String(mutID))}`, {
      "comment": "Ez egy próba az mvp felület felől"
    })
  }

  queryPostRequest(){
    let mutID = 154;
    // this.apiResult$ = this.http.post(`/dms/comments/task/${encodeURIComponent(String(mutID))}`, {
    //   "comment": "Ez egy próba az mvp felület felől"
    // })
    /*this.apiResult$ = this.dmsTasksService.dmsCommentsTaskMutIdPost(mutID, "Ez egy próba az mvp felület felől");

    let subscription = this.apiResult$.subscribe((response: {result: DmsTaskCommentItem[], success: boolean}) => {

      console.log('----->', response);

      response.result.forEach(function (DmsTaskCommentItem) {
          console.log ("---> DmsTaskCommentItem", DmsTaskCommentItem);
      })


      subscription.unsubscribe();
    })*/
    this.apiResult$ = this.http.post(`/dms/task/${mutID}/deny`, {});
  }

  queryPostRequest404(){
    this.apiResult$ = this.http.post(`/dms/comments/notfounexception`, {
    })
  }

}
