import {AfterViewInit, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {CredentialResponse} from "google-one-tap";
import {MvpApiHttpClient} from "../../../mvp-common";
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as action from "../../actions/login-state.action";
import {LoginStore} from "../../services/login-store";
import {AuthService} from "../../services/auth.service";
import authConfig from "../../auth-config";
import {LoginState} from "../../types";

@Component({
    selector: 'mvp-auth-google-button',
    templateUrl: './google-button.component.html',
    styleUrls: ['./google-button.component.scss'],
    standalone: false
})
export class GoogleButtonComponent implements AfterViewInit {

  loginState$: Observable<LoginState>;
  logout$ = createEffect(() => this.actions$.pipe(
    ofType(action.logout),
    tap(() => {
      console.log('disableAutoSelect...');
      alert('disableAutoSelect');
      window.google.accounts.id.disableAutoSelect();
    })
    //exhaustMap((x)=>{})
  ))

  @ViewChild('google_signin') btnGoogleSignin: ElementRef = new ElementRef({});

  credential?: string;

  constructor(
    private readonly actions$: Actions,
    private readonly api: MvpApiHttpClient,
    private readonly ngZone: NgZone,
    private readonly store: LoginStore,
    private readonly authService: AuthService,
  ) {
    this.loginState$ = store.selectLoginState();
    //console.log('this.loginState$', this.loginState$);
  }

  ngAfterViewInit(): void {
    // https://stackoverflow.com/a/72875140
    window.google.accounts.id.initialize({
      client_id: authConfig.google.clientId,
      context: 'signin',
      ux_mode: 'popup',
      itp_support: true,
      auto_select: true, // https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out
      callback: (idConfig: CredentialResponse) => {
        this.ngZone.run(() => {
          //console.log('Google response:', idConfig.select_by, idConfig);
          const credential = idConfig.credential;
          this.credential = credential;
          this.authService.xLogin({
            providerToken: credential,
            provider: "GOOGLE",
            selectedStation: undefined
          })
          //this.mvpLogin(credential);
        })
      },
    });
    //window.google.accounts.station_id.disableAutoSelect();
    window.google.accounts.id.renderButton(
      this.btnGoogleSignin.nativeElement,
      {
        type: "standard",
        theme: "outline",
        size: "large",
        //width: "50",
        shape: "rectangular",
        //ux_mode: "popup",
      }
    )

    // this.loginState$.pipe(
    //   tap(x => console.log('loginState$', x)),
    // )
    //   .subscribe((loginState) => {
    //     console.log('loginState:', loginState);
    //   });
  }

}
