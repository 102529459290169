<!--<mat-action-list>
  <ng-container *ngFor="let item of incomingData">
  <mat-list-item>
    <div matListItemTitle>{{item.name}} (Verzió: {{item.version}})</div>
    <div matListItemLine>Létrehozta: {{item.creator}} ({{item.createDate}})</div>
    <div matListItemMeta style="height: 100%; margin: 0; display: flex; align-items: center; justify-content: center">
      <button mat-icon-button (click)="download(item.attachmentGuid)"><mat-icon>save_alt</mat-icon></button>
    </div>
  </mat-list-item>
  <mat-divider style="width: 80%; margin: 0 auto"></mat-divider>
  </ng-container>
</mat-action-list>-->

<app-list-view [listTitle]="'Csatolmányok'"
               [items]="requestData.attachmentItemList"
               [menuItem]="false"
               (downloadAttachment)="download($event)"
               (signAttachment)="signDoc($event)"
></app-list-view>

<app-list-view *ngIf="requestData.attachmentRelatedItemList"
               [listTitle]="'Kapcsolódó csatolmányok'"
               [items]="requestData.attachmentRelatedItemList"
               [menuItem]="false"
               (downloadAttachment)="download($event)"
               (signAttachment)="signDoc($event)"
></app-list-view>
