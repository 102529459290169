import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatBadge} from "@angular/material/badge";
import {MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatFormField, MatHint} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf, NgSwitchCase} from "@angular/common";
import {SharedModule} from "../../../modules/shared/shared.module";
import {DmsNewsItem} from "../../../model/dmsNewsItem";
import {DmsPossibleWfTaskResultsSchema} from "../../../model/dmsPossibleWfTaskResultsSchema";
import {IncomingDataAttachments} from "../../../shared/interfaces/shared/IncomingDataAttachments";
import {SafeHtml} from "@angular/platform-browser";
import {DmsMetadataSchema} from "../../../model/dmsMetadataSchema";
import {DmsMfTorzsItemsByLeiro} from "../../../model/dmsMfTorzsItemsByLeiro";
import {DmsWfTaskResultItem} from "../../../model/dmsWfTaskResultItem";
import {DialogForwardBoxComponent} from "../dialog-forward-box/dialog-forward-box.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {DmsWfTasksService} from "../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../services/api/dms-tasks.service";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectSendContentById} from "../../../store/selectors/form.selectors";
import {selectTaskSendContentById} from "../../../store/selectors/formtask.selectors";
import {FormWorkflowState} from "../../../store/states/formWorkflowState";
import {FormTaskState} from "../../../store/states/formTaskState";
import {setSendContent} from "../../../store/actions/form.actions";
import {setTaskSendContent} from "../../../store/actions/formtask.actions";
import {DialogAttachmentBoxComponent} from "../dialog-attachment-box/dialog-attachment-box.component";
import {DmsNewsCommentItem} from "../../../model/dmsNewsCommentItem";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {DmsFavoriteService} from "../../../services/api/dms-favorite.service";
import {MetaDataWorkflowComponent} from "../../mvp/shared/metadata/meta-data-workflow/meta-data-workflow.component";
import {MetaDataSzotarComponent} from "../../mvp/shared/metadata/meta-data-szotar/meta-data-szotar.component";
import {DmsDictItemsByType} from "../../../model/dmsDictItemsByType";
import {MetaDataSzotarAcComponent} from "../../mvp/shared/metadata/meta-data-szotar-ac/meta-data-szotar-ac.component";
import {MetaDataSzervuserComponent} from "../../mvp/shared/metadata/meta-data-szervuser/meta-data-szervuser.component";
import {
  MetaDataSzervuserAcComponent
} from "../../mvp/shared/metadata/meta-data-szervuser-ac/meta-data-szervuser-ac.component";
import {LangChangeEvent, TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-feed-card-new',
    imports: [
        FormsModule,
        MatBadge,
        MatButton,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatFormField,
        MatHint,
        MatIcon,
        MatInput,
        MatMiniFabButton,
        MatOption,
        MatSelect,
        NgForOf,
        NgIf,
        NgSwitchCase,
        SharedModule,
        MatExpansionPanelDescription,
        MatIconButton,
        MetaDataWorkflowComponent,
        MetaDataSzotarComponent,
        MetaDataSzotarAcComponent,
        MetaDataSzervuserComponent,
        MetaDataSzervuserAcComponent,
        TranslateModule
    ],
    templateUrl: './feed-card-new.component.html',
    styleUrl: './feed-card-new.component.scss',
  standalone: true
})
export class FeedCardNewComponent implements OnInit, OnDestroy{

  @ViewChild('input') inputElement!: ElementRef<HTMLElement>;
  @ViewChild('expansionPanel') expansionPanel!: MatExpansionPanel;

  @Input() type!: DmsNewsItem.TypeEnum | undefined;
  @Input() status!: string | undefined;
  @Input() subject!: string | undefined;
  @Input() taskStart!: string | undefined;
  @Input() taskDeadline!: string | undefined;
  @Input() taskName!: string | undefined;
  @Input() possibleResults!: DmsPossibleWfTaskResultsSchema | undefined;
  @Input() id!: number;
  @Input() referenceNumber!: string;
  @Input() attachments!: IncomingDataAttachments[] | undefined;
  @Input() comments!: DmsNewsCommentItem[] | undefined;
  @Input() wfDescription!: SafeHtml;
  @Input() taskDescription!: SafeHtml;
  @Input() metaItem!: Array<DmsMetadataSchema> | undefined;
  @Input() mfTorzsOptionList!: DmsMfTorzsItemsByLeiro[];
  @Input() szotarOptionList!: DmsDictItemsByType[];
  @Input() result!: string;
  @Input() isFavorite!: boolean;
  @Input() icon!: string;

  isStar: boolean = false;

  selectedResult!: DmsWfTaskResultItem;

  sendTaskResult!: string;

  sendComment: string =  '';

  previewMetaItems: Array<DmsMetadataSchema> = [];

  tempMetadata: Array<DmsMetadataSchema> = [];

  deadlineCounter = 'Nincs határidő';

  isSmallScreen: boolean = false;
  isMediumScreen: boolean =false;

  headerHeight = '100px';

  private sendContentSubscription: Subscription | undefined;
  private sendTaskContentSubscription: Subscription | undefined;
  sendContents: { comment: string; metadata: Array<DmsMetadataSchema> } = {comment: '', metadata: []};
  sendTaskContents: { comment: string } = {comment: ''};
  hasPreSaveData: boolean = true;

  possibleTaskResults: DmsWfTaskResultItem[] = [
    { id: '1_elfogadva', displayName: 'Elfogadva' },
    { id: '1_lezarva', displayName: 'Lezárva' },
    { id: '1_elutasitva', displayName: 'Elutasítva' },
  ];

  deadlineTooltip = '';
  private langChangeSub!: Subscription;
  missedDeadline = 'Lejárt határidő!';
  closed = 'Lezárva';
  reqDataError = 'Kérjük töltse ki a kötelező mezőket küldés előtt!';
  reqResultError = 'Eredmény kitöltése kötelező!';
  reqTypeError= "Hiba a feladat típusában!";

  description: SafeHtml = '';

  constructor(
    public dialog: MatDialog,
    public workflowItemService: DmsWfTasksService,
    public taskItemService: DmsTasksService,
    private dmsFavoriteService: DmsFavoriteService,
    private storeWorkflow: Store<{ form: FormWorkflowState }>,
    private storeTask: Store<{ formTask: FormTaskState }>,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    public router: Router,
    private translateService: TranslateService
    ) {
  }

  ngOnInit() {
    this.updateMultilingualValues();
    this.langChangeSub = this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
      this.updateMultilingualValues();
    });

    this.breakpointObserver.observe([
      "(max-width: 768px)",
      "(min-width: 769px) and (max-width: 1023px)"
    ]).subscribe((result: BreakpointState) => {
      if(result.breakpoints["(max-width: 768px)"]) {
        this.isSmallScreen = true;
        this.isMediumScreen = false;
        this.headerHeight = 'auto';
        this.cdr.detectChanges();
      } else if (result.breakpoints["(min-width: 769px) and (max-width: 1023px)"]) {
        this.isSmallScreen = false;
        this.isMediumScreen = true;
        this.headerHeight = '200px';
        this.cdr.detectChanges();
      } else {
        this.isSmallScreen = false;
        this.isMediumScreen = false;
        this.headerHeight = '100px';
        this.cdr.detectChanges();
      }
    })

    if(this.metaItem){
      this.metaItem.forEach(item =>{
        if(item.is_preview){
          this.previewMetaItems.push(item);
        }
      })
    }
    if(this.id && this.type === 'WfTask') {

      if(this.possibleResults){
        this.possibleResults.forEach( result => {
          if(result.isSelected){
            this.selectedResult = result;
          }
        })
      }

      if(this.isMandatoryComment()){
        this.triggerCommentValidator();
      }

      if(this.isActiveStatus()) {
        this.sendContentSubscription = this.storeWorkflow.pipe(
          select(selectSendContentById(this.id))
        ).subscribe((filteredContent) => {
          if (filteredContent) {
            this.sendContents = structuredClone(filteredContent as {
              comment: string;
              metadata: Array<DmsMetadataSchema>
            });

            this.sendComment = this.sendContents.comment;
            this.metaItem = this.sendContents.metadata;

            this.hasPreSaveData = true;
          }
        });
      }
    } else if(this.id && this.type === 'Task') {
      if(this.isActiveStatus()) {
        this.sendTaskContentSubscription = this.storeTask.pipe(
          select(selectTaskSendContentById(this.id))
        ).subscribe((filteredContent) => {
          if (filteredContent) {
            this.sendTaskContents = structuredClone(filteredContent as {
              comment: string;
            });

            this.sendComment = this.sendTaskContents.comment;

            this.hasPreSaveData = true;
          }
        });
      }
    }
    this.setDescription();
  }

  ngOnDestroy() {
    if (this.sendContentSubscription) {
      this.sendContentSubscription.unsubscribe();
    }
    if(this.sendTaskContentSubscription) {
      this.sendTaskContentSubscription.unsubscribe();
    }
    if(this.isActiveStatus()) {
      if (this.id && this.metaItem && this.type === 'WfTask') {
        this.storeWorkflow.dispatch(setSendContent({
          id: this.id,
          sendContent: {
            comment: this.sendComment,
            metadata: this.metaItem
          }
        }));
      } else if (this.id && this.type === 'Task') {
        this.storeTask.dispatch(setTaskSendContent({
          id: this.id,
          sendTaskContent: {
            comment: this.sendComment,
          }
        }));
      }
    }
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  completeTask(){
    if (this.selectedResult && this.selectedResult.id.length>0) {
      this.sendTaskResult = this.selectedResult.id;
      this.sendCompleteRequest();
    } else {
      alert(this.reqResultError);
    }
  }

  private sendCompleteRequest(){
    if(this.type=='WfTask') {
      this.sendWorkflowRequest();
    } else if(this.type=='Task') {
      this.sendTaskRequest();
    } else alert(this.reqTypeError);
  }

  private sendTaskRequest(){
    if(this.sendTaskResult==='1_elfogadva'){
      this.taskItemService.createDmsTaskAccept$(Number(this.id), this.sendComment).subscribe( () => {
        this.reloadPage();
      })
    } else if(this.sendTaskResult==='1_lezarva'){
      this.taskItemService.createDmsTaskComplete$(Number(this.id), this.sendComment).subscribe( () => {
        this.reloadPage();
      })
    } else if(this.sendTaskResult==='1_elutasitva'){
      this.taskItemService.createDmsTaskDeny$(Number(this.id), this.sendComment).subscribe( () => {
        this.reloadPage();
      })
    } else alert(this.reqResultError);
  }

  private sendWorkflowRequest(){
    if(this.metaItem && this.checkMandatoryDataFill() && this.checkMandatoryCommentFill()) {
      this.tempMetadata = this.metaItem;
      let send_metadata: { code: string, value: string | number | null }[];
      send_metadata = this.tempMetadata.map(item =>
        ({
          code: item.code,
          value: item.type === 'Check' ? (item.value ? '1' : '0') : item.value
        })
      );
      send_metadata.forEach(item => {
        if (item.value == '') item.value = null;
      })
      const sendContent = {
        comment: this.sendComment,
        wfTaskResult: this.sendTaskResult,
        metadata: send_metadata.reduce<{ [key: string]: any }>(
          (acc, item) => {
            acc[item.code] = item.value;
            return acc;
          }, {})
      };
      const sendContentJson = JSON.stringify(sendContent, null, 2);
      this.workflowItemService.createDmsWfTaskComplete$(Number(this.id), sendContentJson).subscribe(() => {
        this.reloadPage();
      });
    } else alert(this.reqDataError);
  }

  countAttachments(){
    if(this.attachments&&this.attachments.length>0){
      return this.attachments.length
    } else return 0;
  }

  countMessages(){
    if(this.comments){
      return this.comments.length;
    } else return 0;
  }

  routeToInfo(){
    if(this.type === "WfTask"){
      return '/workflow/'+this.id;
    } else return '/task/'+this.id;
  }

  onForwardTask() {
    this.dialog.open(DialogForwardBoxComponent, {
      data: {
        type: this.type,
        id: this.id,
        name: this.type === "WfTask" ? this.taskName : this.subject,
        subject: this.subject,
      },
      autoFocus: false,
      closeOnNavigation: true
    })
  }

  callAttachments(){
    const dialogRef = this.dialog.open(DialogAttachmentBoxComponent,  {
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        attachmentList: this.attachments,
        elementType: this.type,
        elementId: this.id.toString(),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.reloadPage();
      }
    });
  }

  isResult(){
    return this.selectedResult && this.selectedResult.id.length > 0;
  }

  private reloadPage(){
    this.router.navigate(['/pageloading']);
  }

  private checkMandatoryDataFill(): boolean {
    if (this.metaItem) {
      const alszamRegex = /^(?:([\p{L}\p{N} \w.!*_\/-]+)\/)?([0-9]+)-([0-9]+)\/((?:19|20)[0-9]{2})(?:\/([\p{L}\p{N} \w.!*_\/-]+))?$/u;
      const foszamRegex = /^(?:([\p{L}\p{N} \w.!*_\/-]+)\/)?([0-9]+)\/((?:19|20)[0-9]{2})(?:\/([\p{L}\p{N} \w.!*_\/-]+))?$/u;
      return !this.metaItem.some(item =>
        (item.visibility === 'MT_K' && (item.value === null || item.value === undefined || item.value === '')) ||
        (item.visibility === 'MT_K' && item.type === "Check" && !item.value) ||
        (item.visibility === 'MT_K' && item.type === "Alszam" && !alszamRegex.test(item.value)) ||
        (item.visibility === "MT_K" && item.type === "Foszam" && !foszamRegex.test(item.value))
      );
    }
    return true;
  }

  private checkMandatoryCommentFill(): boolean {
    if (this.isMandatoryComment()) {
      return this.sendComment.length > 0;
    }
    return true;
  }

  possibleTaskResultsSorting() {
    if (this.type === 'WfTask') {
      return this.possibleResults;
    } else if (this.type === 'Task') {
      if (this.status && this.status === '1_elfogadva') {
        return this.possibleTaskResults.slice(1);
      } else return this.possibleTaskResults;
    } else return [];
  }

  starClicked(){
    let type: string;
    if(this.type === 'Task'){
      type = 'task';
    } else type = 'wfTask';
    this.dmsFavoriteService.createDmsFavorite$(type, this.id.toString(), !this.isFavorite)
      .subscribe( () => {
      this.reloadPage();
    })
  }

  getTimeLeft(deadline: string | undefined, year_t: string, day_t: string, hour_t: string, minute_t: string, noDeadline: string): string {
    if(deadline) {
      const currentTime = new Date();
      const deadlineTime = new Date(deadline);
      const timeDifference = deadlineTime.getTime() - currentTime.getTime();

      if (timeDifference <= 0) {
        return this.missedDeadline;
      }

      const minutes = Math.floor(timeDifference / (1000 * 60));
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

      if (years >= 1) {
        return years + ' ' + year_t;
      } else if (days >= 1) {
        return days + ' ' + day_t;
      } else if (hours >= 1) {
        return hours + ' ' + hour_t;
      } else {
        return minutes + ' ' + minute_t;
      }
    } else return noDeadline;
  }

  getLastComment(){
    if(this.comments && this.comments[0] && this.comments[0].comment){
      return this.comments[0].comment;
    } else {
      return '-';
    }
  }

  getLastCommentData(){
    if(this.comments && this.comments[0] && this.comments[0].userName && this.comments[0].date) {
      return ' (' + this.comments[0].userName + ', ' + this.dateFormatting(this.comments[0].date, false) + ')';
    } else {
      return '';
    }
  }

  getHeaderActionLayout(){
    if(this.isSmallScreen){
      return 'small-screen-header-action-div add-row-gap-12';
    } else if(this.isMediumScreen){
      return 'small-screen-header-action-div';
    } else {
      return 'big-screen-header-action-div';
    }
  }

  getHeaderResultLayoutNonActive(){
    if(this.isSmallScreen){
      return 'small-screen-non-active-result-div';
    } else if(this.isMediumScreen){
      return 'medium-screen-non-active-result-div';
    } else {
      return 'big-screen-non-active-result-div';
    }
  }

  getHeaderResultLayout() {
    if(this.isMediumScreen){
      return 'header-result-layout-medium';
    } else {
      return 'flex-row-centered';
    }
  }

  isActiveStatus(){
    return this.status === '1_elfogadva' || this.status === '1_letrehozva' || this.status === 'FA_U';
  }

  isMandatoryComment(){
    return !!(this.selectedResult && this.selectedResult.isCommentRequired);
  }

  triggerCommentValidator() {
    let commentElement: HTMLElement = this.inputElement.nativeElement;
    commentElement.click();
  }

  setCommentState(){
    if(this.isMandatoryComment()) {
      this.expansionPanel.open();
      setTimeout(() => {
        if (this.inputElement && this.inputElement.nativeElement) {
          this.inputElement.nativeElement.focus();
          this.inputElement.nativeElement.blur();
          this.inputElement.nativeElement.click();
        }
      }, 300);
    }
    this.triggerCommentValidator();
  }

  dateFormatting(timestamp: string | null, date?: boolean){
    if (timestamp && timestamp.length>1) {
      let dateArray = timestamp.split('T');
      dateArray[1] = dateArray[1].substring(0, 5);
      if (date) {
        return dateArray[0];
      } else {
        return dateArray.join(' ');
      }
    }
    else return '';
  }

  setDeadlineTooltip(deadline_t: string, noDeadline_t: string){
    if (this.taskDeadline) {
        this.deadlineTooltip = deadline_t + ': ' + this.taskDeadline;
    } else {
        this.deadlineTooltip = noDeadline_t;
    }
  }

  updateMultilingualValues(){
    this.translateService.get('MISSED_DEADLINE').subscribe((translatedText: string) => {
      this.missedDeadline = translatedText;
    });
    this.translateService.get('CLOSED').subscribe((translatedText: string) => {
      this.closed = translatedText;
    });
    let noDeadline_t = "Nincs határidő!";
    let deadline_t = "Határidő";
    let year_t = "év";
    let day_t = "nap";
    let hour_t = "óra";
    let minute_t = "perc";
    this.translateService.get('NO_DEADLINE').subscribe((translatedText: string) => {
      noDeadline_t = translatedText;
    });
    this.translateService.get('DEADLINE').subscribe((translatedText: string) => {
      deadline_t = translatedText;
    });
    this.translateService.get('YEAR').subscribe((translatedText: string) => {
      year_t = translatedText;
    });
    this.translateService.get('DAY').subscribe((translatedText: string) => {
      day_t = translatedText;
    });
    this.translateService.get('HOUR').subscribe((translatedText: string) => {
      hour_t = translatedText;
    });
    this.translateService.get('MINUTE').subscribe((translatedText: string) => {
      minute_t = translatedText;
    });
    this.translateService.get('FILL_REQUIRED_FIELDS').subscribe((translatedText: string) => {
      this.reqDataError = translatedText;
    });
    this.translateService.get('RESULT_SELECTION_REQUIRED').subscribe((translatedText: string) => {
      this.reqResultError = translatedText;
    });
    this.translateService.get('TASK_TYPE_ERROR').subscribe((translatedText: string) => {
      this.reqTypeError = translatedText;
    });
    this.setDeadlineTooltip(deadline_t, noDeadline_t);
    this.deadlineCounter = this.getTimeLeft(this.taskDeadline, year_t, day_t, hour_t, minute_t, noDeadline_t);
  }

  private setDescription(){
    if(this.type==='WfTask'){
      this.description = this.wfDescription ? this.wfDescription : '-';
    } else {
      this.description = this.taskDescription ? this.taskDescription : '-';
    }
  }

}
