<mat-card appearance="outlined" class="general-margin" [routerLink]="routerLink">
  <mat-card-header>
    <div *ngIf="backgroundImage"
         mat-card-avatar
         [ngStyle]="{backgroundImage:'url(./assets/person.png)'}"
         style="background-size: cover"></div>
    <mat-card-title class="break-word">{{ title }}</mat-card-title>
    <mat-card-subtitle class="break-word">{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider class="divider-spacing"></mat-divider>
  <mat-card-content class="break-word">

    <ng-container *ngIf="description">
      {{description}}
      <mat-divider *ngIf="description"
        class="description">
      </mat-divider>
    </ng-container>
    <ng-container *ngFor="let item of cardContent">
      <p *ngIf="item.value">{{ item.label }}: <span *ngIf="!item.html">{{ item.value }}</span></p>
      <p *ngIf="item.html" style="margin-bottom: 0" [innerHTML]="item.value"></p>
    </ng-container>
    <ng-content></ng-content>
    {{additionalContent}}
  </mat-card-content>
  <ng-content></ng-content>
</mat-card>
