import {Component} from '@angular/core';
import * as action from "../../actions/login-state.action";
import {Observable} from "rxjs";
import {LoginStore} from "../../services/login-store";
import {MvpHttpClient} from "../../http/mvp-http-client";
import {AuthStateEnum, LoginState} from "../../types";
import {LoginResponseType} from "../../services/types";
import {Router} from "@angular/router";

@Component({
    selector: 'mvp-auth-dev-widget',
    templateUrl: './dev-widget.component.html',
    styleUrls: ['./dev-widget.component.scss'],
    standalone: false
})
export class DevWidgetComponent {
  loginState$: Observable<LoginState>;
  loginState?: LoginState;

  constructor(
    private readonly http: MvpHttpClient,
    private readonly store: LoginStore,
    private readonly router: Router,
  ) {
    this.loginState$ = store.selectLoginState();
    this.loginState$.subscribe(state => this.loginState = state);
  }

  getAuthStateName(): string {
    return this.loginState ? AuthStateEnum[this.loginState.authState] : '??';
  }

  dispatchAutologin() {
    this.store.dispatch(action.autoLogin());
  }

  dispatchLoginNeeded() {
    this.store.dispatch(action.loginNeeded());
  }

  dispatchLoginCompleted() {
    this.store.dispatch(action.loginCompleted({
      credentials: {
        roles: [],
        email: 'horvath.istvan@dmsone.hu',
        provider: 'GOOGLE',
        station: null,
      },
    }));
  }

  dispatchLoginAdminCompleted() {
    this.store.dispatch(action.loginCompleted({
      credentials: {
        roles: [],
        email: 'horvath.istvan@dmsone.hu',
        provider: 'GOOGLE',
        station: null,
      }
    }));
  }

  dispatchLoginAborted() {
    this.store.dispatch(action.loginAborted());
  }

  // dispatchLoggedIn() {
  //   this.store.dispatch(action.loggedIn({
  //     credentials: {
  //       email: 'horvath.istvan@dmsone.hu',
  //       provider: 'GOOGLE',
  //       station: null,
  //     }
  //   }));
  // }

  dispatchLogout() {
    this.store.dispatch(action.logout({
      provider: 'GOOGLE',
    }));
  }

  protected readonly AuthState = AuthStateEnum;

  dispatchAutoLoginSuccess() {
    this.store.dispatch(action.xloginSuccess({
      response: {
        type: LoginResponseType.Success,
        email: 'horvath.istvan@dmsone.hu',
        provider: 'GOOGLE',
        activeStation: null,
        stationList: [],
      },
    }));
  }

  dispatchAutoLoginMultiStation() {
    this.store.dispatch(action.xloginMultiStation({
      response: {
        type: LoginResponseType.MultipleStations,
        stationList: ['barostér', 'kelenföld', 'pécsbánya'],
      },
      providerToken: 'todo',
      provider: 'GOOGLE'
    }));
  }

  demoPage() {
    this.router.navigate(['dev', 'login', 'demo']);
  }
}
