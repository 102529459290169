import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {DmsForwardRequestSchema} from "../../model/dmsForwardRequestSchema";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {HttpParams} from "@angular/common/http";
import {DmsSzervUserItemWithNames} from "../../model/dmsSzervUserItemWithNames";

@Injectable({
  providedIn: 'root'
})
export class DmsWfTasksService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) {
    super(http);
  }

  readDmsWfTasks$(statusList: null|string[]){
    let params: HttpParams = new HttpParams();
    if (statusList?.length) {
      params = params.append("status", statusList.join())
    }
    return this.read$<any>(`wfTasks/my`, params)

  }

  readDmsWfTaskById$(wfTaskId: number) {
    return this.read$<any>(this.getWfTaskIdApiUrl(wfTaskId))
  }

  readDmsWfTaskCommentsById$(wfTaskId : number) {
    return this.read$<any>(this.getCommentApiUrl(wfTaskId))
  }

  createDmsWfTaskComment$(wfTaskId : number, message : string) {
    let data = {comment: message};
    return this.create$<any, any>(this.getCommentApiUrl(wfTaskId), data)
  }

  readDmsWfTaskAttachmentsById$(wfTaskId : number) {
    /*return this.read$<any>(this.getAttachmentApiUrl(wfTaskId))*/
    return this.read$<any>(`attachments/wfTask/${wfTaskId}`)
  }

  readDmsWfTaskRelatedAttachmentsById$(wfTaskId : number) {
    return this.read$<any>(`${this.getAttachmentApiUrl(wfTaskId)}/related`)
    /*return this.http.get(`/dms/attachments/wfTask/${wfTaskId}/related`)*/
  }

  createDmsWfTaskForward$(wfTaskId: number, target: DmsSzervUserItemWithNames) {
    const content: DmsForwardRequestSchema = {
      targetUser: {
        userId: target.userId,
        orgId: target.orgId
      }
    }
    return this.create$<any, any>(`${this.getWfTaskIdApiUrl(wfTaskId)}/forward`, content)
  }
  createDmsWfTaskComplete$(wfTaskId: number, content: any)
  {
    return this.create$<any, any>(`${this.getWfTaskIdApiUrl(wfTaskId)}/complete`, content);
  }

  private getCommentApiUrl(wfTaskId: number){
    return `comments/wfTask/${wfTaskId}`;
  }

  private getAttachmentApiUrl(wfTaskId: number){
    return `attachments/wfTask/${wfTaskId}`;
  }

  private getWfTaskIdApiUrl(wfTaskId: number){
    return `wfTask/${wfTaskId}`;
  }

}
