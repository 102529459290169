import {ChangeDetectorRef, Component} from '@angular/core';

@Component({
  selector: 'app-docusign-finished',
  templateUrl: './docusign-finished.component.html',
  styleUrls: ['./docusign-finished.component.scss']
})
export class DocusignFinishedComponent {

  countdown: number = 3;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    const intervalId = setInterval(() => {
        this.countdown--;
        this.cdr.detectChanges();
        if (this.countdown <= 0) {
          clearInterval(intervalId);
          this.closeTab();
        }
    }, 1000);
  }

  closeTab(): void {
    const message = 'refresh';
    window.opener.postMessage({ type: 'REFRESH', payload: message }, '*');
    window.close();
  }

}
