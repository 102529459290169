<app-info-card [title]="'Bejelentkezés'" class="login-info-card">

  <mat-card-content>

    <div [attr.disabled]="stationSelectionMode ? true : null">

      <mvp-auth-google-button class="signin-button"></mvp-auth-google-button>

      <mvp-auth-microsoft-button class="signin-button"></mvp-auth-microsoft-button>

    </div>

    <div>
      <ng-container *ngIf="stationSelectionMode">
        <mat-form-field *ngIf="loginState.stationList.length">
          <mat-label>Station</mat-label>
          <mat-select [(ngModel)]="selectedStation" name="station" (ngModelChange)="stationSelectionChanged($event)"
                      [disabled]="loginState.stationList.length <=1"
          >
            <mat-option [value]="0">Nincs</mat-option>
            <mat-option *ngFor="let station_id of loginState.stationList" [value]="station_id">
              {{station_id}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button color="primary"
                (click)="doLogin()"
        >Login
        </button>
      </ng-container>
    </div>

  </mat-card-content>

</app-info-card>
