/**
 * User: harold
 * Date: 2023.08.07.
 * https://ngrx.io/guide/store
 * https://medium.com/angular-in-depth/ngrx-action-creators-redesigned-d396960e46da
 */
import {createAction, props} from "@ngrx/store";

import {
  AuthProvider,
  Credentials,
} from "../types";
import {AnyLoginResponse, LoginMultiStationResponse, LoginSuccessResponse} from "../services/types";

// export const loggedIn = createAction('[login-state] loggedIn', props<{ credentials: Credentials }>());
export const logout = createAction('[login-state] logout', props<{ provider: AuthProvider }>());
export const clear = createAction('[login-state] clear');

export const loginNeeded = createAction('[login-state] loginNeeded');
export const loginCompleted = createAction('[login-state] loginCompleted',
  props<{ credentials: Credentials }>());
export const loginAborted = createAction('[login-state] loginAborted');


// export const stationNeeded = createAction('[login-state] stationNeeded');
// export const stationCompleted = createAction('[login-state] stationCompleted',
//   props<{ credentials: Credentials }>());
// export const stationAborted = createAction('[login-state] stationAborted');
export const autoLogin = createAction('[login-state] autoLogin');


export const autologinResponse = createAction('[login-state] autologinResponse',
  props<{ response: AnyLoginResponse }>());
export const xloginSuccess = createAction('[login-state] autologinSuccess',
  props<{ response: LoginSuccessResponse }>());
export const xloginMultiStation = createAction('[login-state] autologinMultiStation',
  props<{ response: LoginMultiStationResponse, provider: AuthProvider, providerToken: string }>());
// export const login2Completed = createAction('[login-state] login2Completed',
//   props<{ credentials: Credentials }>());
