<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content>
  <p>{{data.subtitle}}</p>
  <mat-form-field style="width: 100%">
    <mat-label>Megjegyzés írása</mat-label>
    <textarea matInput
              #message maxlength="1000"
              [(ngModel)]="send_comment"
              cdkTextareaAutosize
              cdkAutosizeMinRows="4"
              cdkAutosizeMaxRows="16"></textarea>
    <mat-hint align="end">{{message.value.length}} / 1000</mat-hint>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Mégsem</button>
  <button mat-button (click)="onSendClick()">Küldés</button>
</mat-dialog-actions>
