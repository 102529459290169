import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
    selector: 'app-meta-data-number',
    templateUrl: './meta-data-number.component.html',
    styleUrls: ['../metadata-style.scss'],
    standalone: false
})
export class MetaDataNumberComponent extends BaseMetadataAbstract<number|null, string, number|null>  implements OnInit{

  numberFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.numberFormControl.markAsTouched();
  }

  sendNumberValue(input: string){

    const parsedInput = parseFloat(input);

    if(!isNaN(parsedInput)) {
      this.onChangeValue(parsedInput)
    } else {
      this.onChangeValue(null);
    }
  }

  receiveNumberValue(){
    if(this.value!=null) return this.value.toString();
    else return '';
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.numberFormControl = new FormControl(this.value, validators);
  }

}
