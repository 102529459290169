import {MvpHttpClient} from "../../../mvp-auth";
import {Observable} from "rxjs";
import { HttpParams } from "@angular/common/http";

export abstract class BaseHttpServiceAbstract {

  private _dmsPrefix = '/dms/';
  protected http: MvpHttpClient;

  protected constructor(http: MvpHttpClient ) {
    this.http = http;
  }

  protected read$<R>(apiUrl: string, params?: HttpParams): Observable<R>{
    return this.http.get<R>(`${this._dmsPrefix}${apiUrl}`,{params})
  }

  protected create$<C,D>(apiUrl: string, data: D): Observable<C> {
    return this.http.post<C>(`${this._dmsPrefix}${apiUrl}`, data)
  }
/*

  protected update$<U>(apiUrl: string, data: U): Observable<any> {
    return this.http.put(`${this._dmsPrefix}${apiUrl}`, data)
  }

  protected delete$(apiUrl: string): Observable<any> {
    return this.http.delete(`${this._dmsPrefix}${apiUrl}`)
  }
*/


}
