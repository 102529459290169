import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DmsWorkflowDigraph extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http); }

  readDmsWorkflowDigraph$(id: number, isWorkflowId?: boolean){
    let params: HttpParams = new HttpParams();
    if(isWorkflowId){
      params = params.append("workflowId", id);
    } else {
      params = params.append("templateId", id);
    }
    return this.read$<any>(`workflow/digraph`, params)
  }

}
