<span id="headerspan" style="overflow: hidden; text-overflow: ellipsis; padding-top: 9pt; padding-bottom: 9pt; max-width: 75%">{{routeInfo?.header}}</span>

<span *ngIf="showSpacer();" class="example-spacer"></span>

<ng-container *ngIf="routeInfo">
<!--
  <button
    mat-icon-button
    *ngFor="let action of actions"
    [routerLink]="action.targetRoute"
    (click)="action.click"
    matTooltip="{{action.tooltip}}"
  >
-->
  <button
    style="padding-top: 12pt"
    mat-icon-button
    *ngFor="let action of actions"
    (click)="handleClick($event, action, routeInfo)"
    matTooltip="{{action.tooltip}}"
  >
    <mat-icon>{{action.icon}}</mat-icon>
  </button>
  <ng-container *ngIf="collapsedActions.length > 1">
    <button mat-icon-button
            style="padding-top: 12pt; padding-right: 0; padding-left: 0"
            [matMenuTriggerFor]="beforeMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let action of collapsedActions"
        (click)="handleClick($event, action, routeInfo)"
        matTooltip="{{action.tooltip}}"
      >{{action.caption}}</button>
    </mat-menu>
  </ng-container>
</ng-container>

<!--
<button mat-icon-button *ngIf="isworkflow" routerLink="/workflow-item-do" matTooltip="Feladat elvégzése"><mat-icon>check_circle_outline</mat-icon></button>
-->

