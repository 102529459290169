import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserEmailService {

  private _emailAddress: string | undefined = '';


  private emailAddressSubject = new BehaviorSubject<string>('');

  // Expose the observable
  loggedInEmail$ = this.emailAddressSubject.asObservable();

  setLoggedInEmail$(newValue: string) {
    this.emailAddressSubject.next(newValue);
  }

  constructor() { }


  get emailAddress(): string | undefined {
    return this._emailAddress;
  }

  set emailAddress(value: string | undefined) {
    this._emailAddress = value;
  }
}
