import { createReducer, on } from '@ngrx/store';
import {deleteSendContent, setSendContent} from "../actions/form.actions";
import {initialFormWorkflowState} from "../states/formWorkflowState";

export const formReducer = createReducer(
  initialFormWorkflowState,
  on(setSendContent, (state, { id, sendContent }) => ({
    ...state,
    sendContents: {
      ...state.sendContents,
      [id]: sendContent,
    },
  })),
  on(deleteSendContent, (state, { id }) => {
    const { [id]: _, ...sendContents } = state.sendContents;
    return {
      ...state,
      sendContents
    };
  })
);

