<div style="padding: 0; margin: 0" #google_signin></div>

<!--
<div station_id="g_id_onload"
data-client_id="958445996833-kj2g88md641lnu0vottralqomb05cndf.apps.googleusercontent.com"
data-context="use"
data-ux_mode="popup"
data-callback="mvpGoogleCredentialResponse"
data-itp_support="true">
</div>

<div class="g_id_signin"
data-documentType="standard"
data-shape="rectangular"
data-theme="outline"
data-text="signin_with"
data-size="large"
data-logo_alignment="left">
</div>
-->

