import {APP_INITIALIZER, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {loginStateReducer} from "../../mvp-auth";
import {GoogleButtonComponent} from "../../mvp-auth/components/google-button/google-button.component";
import {EffectsModule} from "@ngrx/effects";
import {LoginStateEffects} from "../../mvp-auth";
import {DevWidgetComponent} from "../../mvp-auth";
import {MvpCommonModule} from "../../mvp-common/mvp-common.module";
import {DevTestPageComponent} from "../../mvp-auth/components/dev-test-page/dev-test-page.component";
import {FormsModule} from "@angular/forms";
import {AuthPageComponent} from "../../mvp-auth";
import {MvpMaterialModule} from "../../mvp-material/mvp-material.module";
import {MicrosoftButtonComponent} from "../../mvp-auth/components/microsoft-button/microsoft-button.component";
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule
} from "@azure/msal-angular";
import {PublicClientApplication} from "@azure/msal-browser";
import authConfig from "../../mvp-auth/auth-config";
import {AuthEvents} from "../../mvp-auth/services/auth-events.service";


@NgModule({
  declarations: [
    GoogleButtonComponent,
    MicrosoftButtonComponent,
    DevWidgetComponent,
    DevTestPageComponent,
    AuthPageComponent,
  ],
  imports: [
    CommonModule,
    MvpCommonModule,
    MvpMaterialModule,
    FormsModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('loginState', loginStateReducer),
    EffectsModule.forRoot([LoginStateEffects]),
    MsalModule.forRoot(
      // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
      new PublicClientApplication({
        auth: {
          clientId: authConfig.microsoft.clientId,
          authority: 'https://login.microsoftonline.com/' + authConfig.microsoft.tenantId,
          redirectUri: authConfig.microsoft.redirectUrl,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      }),
      <MsalGuardConfiguration><unknown>null,
      <MsalInterceptorConfiguration><unknown>null,
    ),
  ],
  exports: [
    DevWidgetComponent,
    DevTestPageComponent,
    AuthPageComponent,
    GoogleButtonComponent,
    MicrosoftButtonComponent,
  ],
  bootstrap: [],
  providers: [
    AuthEvents,
    {
      provide: APP_INITIALIZER,
      useFactory: (ae: AuthEvents) => () => ae.dispatchAutologin(),
      deps: [AuthEvents],
      multi: true,
    }
  ]
})
export class MvpAuthModule {
}
