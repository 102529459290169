import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {DmsAttachmentUploadItem} from "../../model/dmsAttachmentUploadItem";
import {DmsAttachmentSchema} from "../../model/dmsAttachmentSchema";
import {from, switchMap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DmsAttachmentsService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http); }

  readDmsAttachmentByGuid$(attachmentGuid: string){
    window.open(`/api/dms/attachment/${attachmentGuid}/download`);
    //window.location.href = `/api/dms/attachment/${attachmentGuid}/download`;
    /*return this.read$<any>(`/attachment/${attachmentGuid}/download`)*/

  }

  readDmsAttachmentPreviewByGuid$(attachmentGuid: string){
    this.http.get(`/dms/attachment/${attachmentGuid}/download`, { responseType: 'blob' }).subscribe(blob => {
      const file = new Blob([blob], { type: blob.type });

      // Check if the file is previewable (like PDFs, images)
      const fileURL = URL.createObjectURL(file);
      const fileType = blob.type;

      if (fileType.startsWith('image/') || fileType === 'application/pdf') {
        // Open the file in a new tab if it's an image or PDF
        window.open(fileURL);
      } else {
        // For non-previewable files, trigger a download
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = '';  // Optional: you can specify file name here
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }, error => {
      console.log('Preview function is not available for this file!');
    });
  }

  // createDmsAttachmentUpload$(content: DmsAttachmentUploadItem) {
  //   return this.create$<any, any>(`attachment/upload`, content);
  // }

  //-----------Base64 solution below with file content being a string-----------

  createDmsAttachmentUpload$(originalContent: DmsAttachmentUploadItem) {
    return from(this.convertToBase64(originalContent.files)).pipe(
      switchMap((base64Files) => {
        const content = {
          files: base64Files,
          elementType: originalContent.elementType,
          elementId: originalContent.elementId,
        };

        return this.create$<any, any>(`attachment/upload`, content);
      })
    );
  }

  private convertToBase64(originalContentFiles: DmsAttachmentSchema[]): Promise<{content: string; attachmentTypeCode: string | null;}[]> {
    const filePromises = originalContentFiles.map(async (file) => {
      const base64Content = await this.fileToBase64(file.content);
      return {
        content: base64Content,
        attachmentTypeCode: file.attachmentTypeCode,
        fileName: file.fileName
      };
    });

    return Promise.all(filePromises);
  }

  private fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

}
