import {Injectable} from '@angular/core';
import {LoginStore} from "./login-store";
import {AuthStateEnum, AuthTypeEnum, Credentials} from "../types";
import {distinctUntilChanged, map, Observable} from "rxjs";
import {Router} from "@angular/router";
import * as action from "../actions/login-state.action";
import {AuthService} from "./auth.service";
import {UserEmailService} from "../../app/services/component/sidenav/user-email.service";

@Injectable({
  providedIn: 'root'
})
export class AuthEvents {

  //public readonly state$: Observable<AuthTypeEnum>;
  private loginState$ = this.store.selectLoginState();
  public readonly authTypeChanged$: Observable<AuthTypeEnum> = this.loginState$.pipe(
    map(loginState => loginState.authType),
    distinctUntilChanged(),
  );
  public readonly authState$: Observable<AuthStateEnum> = this.loginState$.pipe(
    map(loginState => loginState.authState),
    //distinctUntilChanged(),
  );
  public readonly authChanged$: Observable<Credentials | null> = this.loginState$.pipe(
    distinctUntilChanged((prev, curr) => prev.authType == curr.authType),
    map(loginState => loginState.credentials ?? null),
  );

  constructor(
    private readonly store: LoginStore,
    private router: Router,
    private authService: AuthService,
    private user: UserEmailService
  ) {
    this.authTypeChanged$.subscribe(t=>console.log('AuthType=>',AuthTypeEnum[t]));
    this.authChanged$.subscribe(c=> {
      console.log('Credentials=>', c)
      user.emailAddress = c?.email;
      user.setLoggedInEmail$(c ? c.email : '');
    });

    // this.authState$.subscribe(state=>{
    //   if (state === AuthStateEnum.LoginNeeded) {
    //     router.navigate(['dev','login']);
    //   } else if (state === AuthStateEnum.LoginCompleted) {
    //     router.navigate(['dashboard']);
    //   } else if (state === AuthStateEnum.LoginAborted) {
    //     router.navigate(['']);
    //   }
    // })

    //console.log('AuthEvents constructor...');
    // this.authTypeChanged$ = this.loginState$.pipe(
    //   tap(ls => console.log('ae:loginState:', ls)),
    //   map(loginState => loginState.authType),
    //   tap(t => console.log('ae:documentType:', AuthTypeEnum[t])),
    //   distinctUntilChanged(),
    //   tap(t => console.log('ae:distinct:', AuthTypeEnum[t])),
    //   //distinctUntilChanged((prev,curr)=>prev.authType == curr.authType),
    // );
    // this.authTypeChanged$.subscribe(s => console.log('AuthChanged:', s));
    //   .subscribe(loginState => {
    //   console.log('AuthEvents: loginState changed:',loginState);
    //   if (loginState.authState === AuthStateEnum.LoginCompleted) {
    //     this.state$.next(loginState.credentials?.station ? AuthTypeEnum.UserAndStation : AuthTypeEnum.UserOnly);
    //   } else {
    //     this.state$.next(AuthTypeEnum.NotLoggedIn);
    //   }
    // });
    //console.log('AuthEvents constructor completed...');
  }

  dispatchAutologin() {
    this.store.dispatch(action.autoLogin());
  }
}
