import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import {of} from "rxjs";
import {DmsWorkflowStartDetailsResponseSchema} from "../../model/dmsWorkflowStartDetailsResponseSchema";

@Injectable({
  providedIn: 'root'
})
export class DmsWorkflowTemplates extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http); }

  readDmsWorkflowAvailableTemplates$(){

    return this.read$<any>(`workflow/availableTemplates`)

    // const mockTemplates = [
    //   {
    //     id: 1,
    //     code: 'TEMPLATE_001',
    //     name: 'Template One',
    //     description: 'Description for Template One'
    //   },
    //   {
    //     id: 2,
    //     code: 'TEMPLATE_002',
    //     name: 'Template Two',
    //     description: 'Description for Template Two'
    //   },
    //   {
    //     id: 3,
    //     code: 'TEMPLATE_003',
    //     name: 'Template Three',
    //     description: '<h1>Címsor 1</h1><h2>Címsor 2</h2><h3>Címsor 3</h3><h4>Címsor 4</h4><p>Címsor 5</p><p>kicsi Nagy Sans Serif Serif Monospace <strong>bold </strong><em>italic </em><u>underlined</u> <s>áthúzott</s> <span style=\"color: rgb(102, 163, 224);\">kékszöveg </span><span style=\"background-color: rgb(178, 107, 0);\">barna háttér</span> <sup>felsőindex</sup> <sub>alsóindex</sub> <a href=\"https://k4.82.dev.dmsone.hu/ext/hivatkoz%C3%A1s2\" target=\"_blank\">hivatkozás</a></p><p><br></p><blockquote>Idézet</blockquote><p>balra igazítás</p><p class=\"ql-align-right\">jobbra igazítás</p><p class=\"ql-align-center\">középre igazítás</p><p class=\"ql-align-justify\">sorkizárt szöveg</p><p class=\"ql-align-justify\"><br></p><ol><li>számozott lista</li><li class=\"ql-indent-1\">számozott lista behúzva</li><li class=\"ql-indent-2\">számozott lista behúzva még egyszer</li></ol><ul><li>sima lista</li><li class=\"ql-indent-1\">sima lista behúzva</li><li class=\"ql-indent-3\">sima lista behúzva még kétszer</li></ul><p class=\"ql-indent-1\">szimpla szöveg behúzva</p><p class=\"ql-indent-1\"><br></p><h1 class=\"ql-indent-2\">címsor 1 behúzva kétszer</h1><p class=\"ql-align-center ql-indent-3\">nagy betűs szöveg középen behúzva háromszor</p><p class=\"ql-align-justify\"><br></p><p><br></p>'
    //
    //   }
    // ];
    // return of({ result: mockTemplates });
  }

  readDmsWorkflowStartDetailsByWorkflowTemplateId$(workflowTemplateId: number){

    return this.read$<any>(`workflow/startDetails/${workflowTemplateId}`)

    // const mockWorkflowStartDetails_1: DmsWorkflowStartDetailsResponseSchema = {
    //   id: workflowTemplateId,
    //   code: 'WF_TEMP_' + workflowTemplateId,
    //   version: 1,
    //   allowedLinkedItemTypes: [
    //     'alszam',
    //     'foszam',
    //     'dmsszamla'
    //   ],
    //   deadline: '',
    //   metadata: [
    //     {
    //       value: '21',
    //       humanvalue: '12-1/2020',
    //       name: 'Kötelező alszám',
    //       type: 'Alszam',
    //       code: 'F_1',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: 'Igen',
    //       name: 'Check_FLEX most kicsivel hosszabb',
    //       type: 'Check',
    //       code: 'F_2',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '2024-07-28',
    //       humanvalue: '2024-07-28',
    //       name: 'Date_FLEX most kicsivel hosszabb',
    //       type: 'Date',
    //       code: 'F_3',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '13',
    //       humanvalue: '10/2020',
    //       name: 'Foszam_FLEX most kicsivel hosszabb',
    //       type: 'Foszam',
    //       code: 'F_4',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '423',
    //       humanvalue: 'Átutalás (NAV E-Számla)',
    //       name: 'Kötelező opció',
    //       type: 'MFTorzs',
    //       code: 'F_5',
    //       default: '',
    //       params: '11513',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '342',
    //       humanvalue: 'Határozat nélküli módosítás',
    //       name: 'MFTorzsAC_FLEX most kicsivel hosszabb',
    //       type: 'MFTorzsAC',
    //       code: 'F_6',
    //       default: '',
    //       params: '11722',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: '1',
    //       name: 'Money_FLEX most kicsivel hosszabb',
    //       type: 'Money',
    //       code: 'F_7',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '10000',
    //       humanvalue: '10000',
    //       name: 'Számla összege (nettó)',
    //       type: 'Number',
    //       code: 'F_8',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: 'igen',
    //       name: 'Option_FLEX most kicsivel hosszabb',
    //       type: 'Option',
    //       code: 'F_9',
    //       default: '',
    //       params: '1|igen;nem',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '196',
    //       humanvalue: 'Balatoni Teréz',
    //       name: 'Partner_FLEX most kicsivel hosszabb',
    //       type: 'Partner',
    //       code: 'F_10',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '20',
    //       humanvalue: '20',
    //       name: 'Számla sorszáma (könyvelés iktató)',
    //       type: 'Text',
    //       code: 'F_11',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: 'a',
    //       humanvalue: 'a',
    //       name: 'Textarea_FLEX most kicsivel hosszabb',
    //       type: 'Textarea',
    //       code: 'F_12',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '',
    //       humanvalue: '',
    //       name: 'esközök',
    //       type: 'MFTorzs',
    //       code: '23234',
    //       default: '',
    //       params: '11530',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     }
    //   ]
    // };
    // const mockWorkflowStartDetails_2: DmsWorkflowStartDetailsResponseSchema = {
    //   id: workflowTemplateId,
    //   code: 'WF_TEMP_' + workflowTemplateId,
    //   version: 1,
    //   allowedLinkedItemTypes: [
    //     'alszam',
    //   ],
    //   deadline: '',
    //   metadata: [
    //     {
    //       value: '21',
    //       humanvalue: '12-1/2020',
    //       name: 'Kötelező alszám',
    //       type: 'Alszam',
    //       code: 'F_1',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: 'Igen',
    //       name: 'Check_FLEX most kicsivel hosszabb',
    //       type: 'Check',
    //       code: 'F_2',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '2024-07-28',
    //       humanvalue: '2024-07-28',
    //       name: 'Date_FLEX most kicsivel hosszabb',
    //       type: 'Date',
    //       code: 'F_3',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '13',
    //       humanvalue: '10/2020',
    //       name: 'Foszam_FLEX most kicsivel hosszabb',
    //       type: 'Foszam',
    //       code: 'F_4',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: '1',
    //       name: 'Money_FLEX most kicsivel hosszabb',
    //       type: 'Money',
    //       code: 'F_7',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '10000',
    //       humanvalue: '10000',
    //       name: 'Számla összege (nettó)',
    //       type: 'Number',
    //       code: 'F_8',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '1',
    //       humanvalue: 'igen',
    //       name: 'Option_FLEX most kicsivel hosszabb',
    //       type: 'Option',
    //       code: 'F_9',
    //       default: '',
    //       params: '1|igen;nem',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '196',
    //       humanvalue: 'Balatoni Teréz',
    //       name: 'Partner_FLEX most kicsivel hosszabb',
    //       type: 'Partner',
    //       code: 'F_10',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: '20',
    //       humanvalue: '20',
    //       name: 'Számla sorszáma (könyvelés iktató)',
    //       type: 'Text',
    //       code: 'F_11',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //     {
    //       value: 'a',
    //       humanvalue: 'a',
    //       name: 'Textarea_FLEX most kicsivel hosszabb',
    //       type: 'Textarea',
    //       code: 'F_12',
    //       default: '',
    //       params: '',
    //       visibility: 'MT_K',
    //       is_preview: false
    //     },
    //   ]
    // };
    // const mockWorkflowStartDetails_3: DmsWorkflowStartDetailsResponseSchema = {
    //   id: workflowTemplateId,
    //   code: 'WF_TEMP_' + workflowTemplateId,
    //   version: 1,
    //   allowedLinkedItemTypes: [
    //     'alszam',
    //   ],
    //   deadline: '',
    //   metadata: []
    // };
    // if(workflowTemplateId === 1){
    //   return of({result: mockWorkflowStartDetails_1});
    // }
    // if(workflowTemplateId === 2){
    //   return of({result: mockWorkflowStartDetails_2});
    // }
    // else {
    //   return of({result: mockWorkflowStartDetails_3});
    // }
  }

}
