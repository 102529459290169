import { createAction, props } from '@ngrx/store';
import {DmsMetadataSchema} from "../../model/dmsMetadataSchema";

export const setSendContent = createAction(
  '[Form] Set Send Content',
  props<{ id: number; sendContent: {comment: string, metadata: Array<DmsMetadataSchema>} }>(),
);

export const deleteSendContent = createAction(
  '[Form] Delete Send Content',
  props<{ id: number }>(),
);
