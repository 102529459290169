/**
 * User: harold
 * Date: 2023.08.09.
 */

import * as loginStateAction from './actions/login-state.action';

export * from './services/login-store';
export * from './types';
export * from './actions/login-state.reducer';
export * from './actions/login-state.effects';
export * from './http/mvp-http-handler.service';
export * from './http/mvp-http-client';

// export {GoogleButtonComponent} from './components/google-button/google-button.component'
// export {MicrosoftButtonComponent} from './components/microsoft-button/microsoft-button.component'

export {DevWidgetComponent} from './components/dev-widget/dev-widget.component'
export {AuthPageComponent} from "./components/auth-page/auth-page.component";

export const action = loginStateAction;
export {AuthStateEnum} from "./types";
export {LoginState} from "./types";
export {Credentials} from "./types";
export {AuthProvider} from "./types";
export {NotLoggedInResponse} from "./services/types";
export {LoginMultiStationResponse} from "./services/types";
export {LoginSuccessResponse} from "./services/types";
export {AnyLoginResponse} from "./services/types";
export {XLoginRequest} from "./services/types";
export {LoginRequest} from "./services/types";
export {LoginResponseType} from "./services/types";
export {LoginRequestType} from "./services/types";
