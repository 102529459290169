import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
@Injectable({
  providedIn: 'root'
})
export class DocusignService {

  constructor(private readonly http: MvpHttpClient) { }

  docusignGetUrlByAttachmentGuid$(attachmentGuid: string){
    return this.http.get<any>(`/docusign/getUrl/${attachmentGuid}`);
  }



}
