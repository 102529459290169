import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IRouteInfo, NavigationService} from "../../../../services/component/navigation/navigation.service";
import {BaseComponentAbstract} from "../../../../../class/base.component.abstract";
import {DmsWfTasksService} from "../../../../services/api/dms-wf-tasks.service";
import {DmsTasksService} from "../../../../services/api/dms-tasks.service";
import {DmsDocsService} from "../../../../services/api/dms-docs.service";
import {MatDialog} from "@angular/material/dialog";
import {DialogMessageBoxComponent} from "../../../shared/dialog-message-box/dialog-message-box.component";
import {PrevPageService} from "../../../../services/component/navigation/prev-page.service";

@Component({
    selector: 'app-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    standalone: false
})
export class CommentListComponent extends BaseComponentAbstract {

  type: string;

  id!: number;

  constructor(
    private navService: NavigationService,
    public dmsWfTasksService: DmsWfTasksService,
    public dmsTasksService: DmsTasksService,
    public dmsDocsService: DmsDocsService,
    route: ActivatedRoute,
    router: Router,
    public dialog: MatDialog,
    private prevPageService: PrevPageService
  ) {
    super(route, router)
    this.type = this.route.snapshot.data['type'];
    this.id = Number(this.route.snapshot.paramMap.get('id'));
  }

  public setupNavigation(params: ParamMap): IRouteInfo {
    return {
      header: 'Megjegyzések', actions: [
        {
          icon: 'add_comment',
          click: () => {
            this.openMessageDialog();
          },
          caption: 'Megjegyzés írása',
          tooltip: 'Megjegyzés írása',
          isCollapsed: false
        },
      ]
    };
  }

  private openMessageDialog(){
    const additionalData = this.requestData.additionalData;
    let title = "";
    let subtitle = "";
    if(this.type === 'workflow'){
      title = additionalData.subject;
    } else if(this.type === 'task'){
      title = additionalData.taskTitle;
    } else if(this.type === 'document'){
      title = additionalData.registryNumber;
      subtitle = additionalData.subject;
    }
    const tempRoute = this.type + '/comment-list/' + this.id;
    this.prevPageService.setPreviousRoute(tempRoute);
    this.dialog.open(DialogMessageBoxComponent, {
      data: {
        type: this.type,
        id: this.id,
        title: title,
        subtitle: subtitle,
        message: '',
      }
    })
  }

}
