import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {PrevPageService} from "../../../../services/component/navigation/prev-page.service";

@Component({
  selector: 'app-pageloading',
  templateUrl: './pageloading.component.html',
  styleUrls: ['./pageloading.component.scss']
})
export class PageloadingComponent implements OnInit{

  constructor(private router: Router, private prevPageService: PrevPageService) {
  }
  ngOnInit() {
    setTimeout(()=> {
      const previousRoute = this.prevPageService.getPreviousRoute();
      if (previousRoute) {
        this.router.navigate([previousRoute]);
      } else {
        console.log('something went wrong!')
        this.router.navigate(['/dashboard'])
      }
    }, 300);
  }

}
