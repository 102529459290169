<app-info-card [title]="'Partner keresése'">
  <button matSuffix mat-icon-button
          class="partner-button">
    <mat-icon>close</mat-icon>
  </button>
  <h2 *ngIf="!partnerList||partnerList.length==0||partnerList.length==null" class="general-padding">Nincs Találat</h2>
  <mat-selection-list multiple="false" [(ngModel)]="value" class="margin-bottom"
                      (ngModelChange)="valueChange.emit(value)">
    <ng-container *ngIf="partnerList.length!=0">
      <mat-list-option *ngFor="let partner of partnerList" [value]="partner">
        <p>{{partner}}</p>
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</app-info-card>
