import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DmsMetaService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient ) { super(http) }

  readDmsPartnerById$(id: number){
      return this.read$<any>(`partner/${id}`)
  }

  readDmsAcPartners$(user: string){
    if (user.length == 0) user = '%';
    let params: HttpParams = new HttpParams();
    params = params.append("name", user)
      return this.read$<any>(`ac/partner`, params)
  }
  readDmsAcOrganizations$(organization: string){
    if (organization.length == 0) organization = '%';
    let params: HttpParams = new HttpParams();
    params = params.append("name", organization)
    return this.read$<any>(`ac/szerv`, params)
  }

  readDmsLinkedItemById$(identifier: string, linkedItemType: string) {
    let params: HttpParams = new HttpParams();
    params = params.append("linkedItemType", linkedItemType);
    params = params.append("identifier", identifier)
    return this.read$<any>(`id/linkedItem`, params)

    // const mockIdentifier_1: DmsLinkedItem = {
    //   id: '1',
    //   identifier: '1-1/2020'
    // };
    // const mockIdentifier_2: DmsLinkedItem = {
    //   id: '1',
    //   identifier: '1/2020'
    // };
    // const mockIdentifier_3 = {};
    //
    // if(identifier === '1-1/2020') {
    //   return of({result: mockIdentifier_1});
    // } else if(identifier === '1/2020'){
    //   return of({result: mockIdentifier_2});
    // } else {
    //   return of({result: mockIdentifier_3});
    // }
  }

  readDmsMfTorzsListByLeiroId$(leiroIdList: string){
      return this.read$<any>(`mfTorzs/${leiroIdList}`)
  }

  readDmsSzotarListByTypeId$(dictTypeIdList: string){
    return this.read$<any>(`dictItems/${dictTypeIdList}`)
  }

  readDmsAttachmentType$(){
    return this.read$<any>(`attachmentType/get`)
  }

  readDmsTasksStartDetails$(){
    return this.read$<any>(`tasks/startDetails`)
  }

}
