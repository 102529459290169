import {Component} from '@angular/core';
import {MsalService} from "@azure/msal-angular";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'mvp-auth-microsoft-button',
    templateUrl: './microsoft-button.component.html',
    styleUrls: ['./microsoft-button.component.scss'],
    standalone: false
})
export class MicrosoftButtonComponent {


  constructor(
    private readonly msalService: MsalService,
    private readonly authService: AuthService,
  ) {
  }

  loginClick() {
    console.log('ms login');
    this.msalService.loginPopup()
      .subscribe({
        next: (result) => {
          //this.loginSuccess(result);
          this.authService.xLogin({
            provider: "MICROSOFT",
            providerToken: result.idToken,
            selectedStation: undefined
          });
        },
        error: err => console.error(err),
      })
  }

  // private loginSuccess(result: AuthenticationResult) {
  //   console.log('loginResult:', result);
  // }
}
