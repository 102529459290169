import {Component} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from "@angular/forms";

@Component({
  selector: 'app-meta-data-date',
  templateUrl: './meta-data-date.component.html',
  styleUrls: ['../metadata-style.scss']
})

export class MetaDataDateComponent extends BaseMetadataAbstract<string, string, string>{

  dateFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.dateFormControl.markAsTouched();
  }

  formatNewValue(date: Date){
    //Original output format: Tue Dec 26 2023 00:00:00 GMT+0100 (közép-európai téli idő)
    // Format the date value to 'yyyy-MM-dd' then emit the correct value
    if(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      this.onChangeValue(`${year}-${month}-${day}`);
    } else this.onChangeValue('');
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
      Validators.minLength(1),
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.dateFormControl = new FormControl(this.value, validators);
  }

}
