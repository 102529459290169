import {Component, OnInit} from '@angular/core';
import {BaseMetadataAbstract} from "../BaseMetadata.abstract";
import {FormControl, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-meta-data-alszam',
  templateUrl: './meta-data-alszam.component.html',
  styleUrls: ['../metadata-style.scss']
})
export class MetaDataAlszamComponent extends BaseMetadataAbstract<string, string, string> implements OnInit{

  alszamFormControl!: FormControl;

  ngOnInit() {
    this.initializeFormControl();
    this.alszamFormControl.markAsTouched();
  }

  private initializeFormControl() {
    const validators: ValidatorFn[] = [
      Validators.minLength(1),
      Validators.pattern(/^([^\/]+\/)?[1-9]\d*-\d+\/\d{4}(\/[^\/]+)?$/),
    ];

    if (this.visibility === 'MT_K') {
      validators.push(Validators.required);
    }

    this.alszamFormControl = new FormControl(this.value, validators);
  }

}
