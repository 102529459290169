import { Injectable } from '@angular/core';
import {MvpHttpClient} from "../../../mvp-auth";
import {DmsForwardRequestSchema} from "../../model/dmsForwardRequestSchema";
import {BaseHttpServiceAbstract} from "./BaseHttpService.abstract";
import { HttpParams } from "@angular/common/http";
import {DmsSzervUserItemWithNames} from "../../model/dmsSzervUserItemWithNames";

@Injectable({
  providedIn: 'root'
})
export class DmsTasksService extends BaseHttpServiceAbstract{

  constructor( http: MvpHttpClient) { super(http); }

  readDmsTasks$(statusList: null|string[])
  {
    let params: HttpParams = new HttpParams();
    if (statusList?.length) {
      params = params.append("status", statusList.join())
    }
    return this.read$<any>('tasks/my', params);
  }

  readDmsTaskById$(mutId: number){
    return this.read$<any>(this.getTaskIdApiUrl(mutId));
  }

  readDmsTaskCommentsById$(mutId: number) {
    return this.read$<any>(this.getCommentApiUrl(mutId));
  }

  createDmsTaskComment$(mutId: number, message: string) {
    let data = {comment: message};
    return this.create$<any, any>(this.getCommentApiUrl(mutId), data);
  }

  readDmsTaskAttachmentsById$(mutId: number){
    return this.read$<any>(`attachments/task/${mutId}`);
  }

  createDmsTaskForward$(mutId: number, target: DmsSzervUserItemWithNames){
    const content: DmsForwardRequestSchema = {
      targetUser: {
        userId: target.userId,
        orgId: target.orgId
      }
    }
    return this.create$<any, any>(`${this.getTaskIdApiUrl(mutId)}/forward`, content)
  }

  createDmsTaskComplete$(mutId: number, comment: string){
    let content;
    if(comment&&comment.length>0) content = {comment: comment};
    else content = {comment: ''};
    return this.create$<any, any>(`${this.getTaskIdApiUrl(mutId)}/complete`, content)
  }

  createDmsTaskAccept$(mutId: number, comment?: string){
    let content;
    if(comment&&comment.length>0) content = {comment: comment};
    else content = {comment: ''};
    return this.create$<any, any>(`${this.getTaskIdApiUrl(mutId)}/accept`, content);
  }

  createDmsTaskDeny$(mutId: number, comment?: string){
    let content;
    if(comment&&comment.length>0) content = {comment: comment};
    else content = {comment: ''};
    return this.create$<any, any>(`${this.getTaskIdApiUrl(mutId)}/deny`, content);
  }

  private getCommentApiUrl(mutId: number){
    return `comments/task/${mutId}`;
  }

  private getTaskIdApiUrl(mutId: number){
    return `task/${mutId}`;
  }

  createDmsTaskStart$(content: any) {
    return this.create$<any, any>(`task/start`, content);
  }

}
